import React, { useState } from "react";
import UserAuthHome from "./index";
import CustomButton from "../../components/core/CustomButton";
import { useHistory, useParams } from "react-router-dom";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { authService } from "../../services/authMX";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/core/Loader";
import { validatePassword, validateConfirmPassword } from "./Validations";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Footer from "../../components/core/Footer";
import { HOME } from "../../utils/routeConstants";
import { deepCopy } from "../../utils";
import { RESET_VALUES_VALIDATIONS } from "../../services/initialValues";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [resetValidations, setResetValidations] = useState(
    deepCopy(RESET_VALUES_VALIDATIONS)
  );

  const [loading, setLoading] = useState(false);

  const { pk, token } = useParams();

  const resetPasswordHandler = (event) => {
    const { name, value } = event.target;
    let copiedresetValidations = { ...resetValidations };
    copiedresetValidations[name].fieldValue = value;

    if (copiedresetValidations[name].id === "password") {
      validatePassword(value, copiedresetValidations);
    }
    if (copiedresetValidations[name].id === "confirmPassword") {
      validateConfirmPassword(value, copiedresetValidations);
    }
    setResetValidations(copiedresetValidations);
  };

  const handleClickShowPassword = () => {
    if (!resetValidations.password.fieldValue) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const handleClickShowConfirmPassword = () => {
    if (!resetValidations.confirmPassword.fieldValue) {
      setShowConfirmPassword(true);
    } else {
      setShowConfirmPassword(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setShowPassword(true);
  };

  let history = useHistory();

  const fetchResetPasswordAPI = () => {
    let copiedresetValidations = { ...resetValidations };

    if (copiedresetValidations.confirmPassword.fieldValue === "") {
      copiedresetValidations.confirmPassword.isError = true;
      copiedresetValidations.confirmPassword.errorMessage =
        "Confirm Password Should Not be Empty";
      setResetValidations(copiedresetValidations);
    }

    if (copiedresetValidations.password.fieldValue === "") {
      copiedresetValidations.password.isError = true;

      setResetValidations(copiedresetValidations);
    }

    if (
      resetValidations.confirmPassword.fieldValue !== "" &&
      resetValidations.password.fieldValue !== ""
    ) {
      if (
        !resetValidations.password.emptyError &&
        resetValidations.password.minMaxError &&
        resetValidations.password.charactersError &&
        !resetValidations.confirmPassword.isError
      ) {
        setLoading(true);
        authService
          .fetchResetPasswordData({
            new_password1: resetValidations.password.fieldValue,
            new_password2: resetValidations.password.fieldValue,
            uid: atob(pk),
            token: token,
          })
          .then((resp) => {
            if (resp) {
              history.push(HOME);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      }
    }
  };

  const handleEnterkeyPress = (event) => {
    if (event.key === "Enter") {
      fetchResetPasswordAPI();
    }
  };

  return (
    <div>
      {loading && <Loader />}

      <UserAuthHome>
        <div className="auth-block mt-[40px] mb-[20px] xl:mt-[28px] lg:mt-[20px]">
          <h1 className="text-[50px] font-lato font-bold text-[#3A3A3A] xl:text-[30px] lg:text-[20px]">
            Reset your Password
          </h1>
          <p className="text-[30px] text-[#3A3A3A] font-lato mt-[11px] xl:text-[20px] xl:mt-[6px] lg:text-[16px] lg:mt-[4px]">
            Type your New Password and get Started!
          </p>

          <div
            className="flex flex-col mt-[64px] mr-[60px] ml-[10px] 
              xl:flex flex-col xl:mt-[36px] xl:mr-[60px] xl:ml-[10px]
              lg:flex flex-col lg:mt-[32px] lg:mr-[60px] lg:ml-[10px]"
          >
            <FormControl
              sx={{ mt: 1 }}
              variant="outlined"
              className="textinput-field"
            >
              <InputLabel
                // sx={}

                htmlFor="outlined-adornment-password"
                className={`${
                  resetValidations.password.emptyError ||
                  resetValidations.password.isError
                    ? " text-[#d32f2f]"
                    : ""
                }`}
              >
                Password
              </InputLabel>
              <OutlinedInput
                name="password"
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={resetValidations.password.fieldValue}
                error={
                  resetValidations.password.emptyError ||
                  resetValidations.password.isError
                }
                onChange={resetPasswordHandler}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className="p-0 mr-1"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <div className="mt-2 xl:text-[14px] lg:text-[12px]">
              {resetValidations.password.emptyError && (
                <div>
                  <p className="pw-error-msg1 ">
                    {resetValidations.password.emptyErrorMessage}
                  </p>
                </div>
              )}

              <p
                className={`${
                  resetValidations.password.charactersError
                    ? "text-[#4BB543]"
                    : " text-[#d32f2f] pw-error-msg1"
                }`}
              >
                {resetValidations.password.charactersError ? (
                  <DoneIcon />
                ) : (
                  <CloseIcon />
                )}
                {resetValidations.password.charactersErrorMessage}
              </p>

              <p
                className={`${
                  resetValidations.password.minMaxError
                    ? "text-[#4BB543]"
                    : " text-[#d32f2f] pw-error-msg1"
                }`}
              >
                {resetValidations.password.minMaxError ? (
                  <DoneIcon />
                ) : (
                  <CloseIcon />
                )}
                {resetValidations.password.minMaxErrorMessage}
              </p>
            </div>

            <FormControl
              // sx={{ mt: 4 }}
              variant="outlined"
              className="textinput-field 2xl:mt-[15px] xl:mt-[15px] lg:mt-[6px]"
            >
              <InputLabel
                // sx={{marginLeft:1}}

                htmlFor="outlined-adornment-password"
                className={`${
                  resetValidations.confirmPassword.isError
                    ? "text-[#d32f2f]"
                    : ""
                }`}
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                name="confirmPassword"
                id="outlined-adornment-password"
                type={showConfirmPassword ? "text" : "password"}
                value={resetValidations.confirmPassword.fieldValue}
                error={resetValidations.confirmPassword.isError}
                onPaste={(e) => {
                  e.preventDefault();
                }}
                onChange={resetPasswordHandler}
                onKeyDown={handleEnterkeyPress}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={() => setShowConfirmPassword(true)}
                      edge="end"
                      className="p-0 mr-1"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            {resetValidations.confirmPassword.isError && (
              <div>
                <p className="pw-error-msg">
                  {resetValidations.confirmPassword.errorMessage}
                </p>
              </div>
            )}
          </div>

          <div className="mt-[40px] mr-[60px] ml-[10px] xl:mt-[28px] lg:mt-[20px]">
            <CustomButton
              iconName="reply-right"
              text="Reset"
              className="filled-custom-btn text-[24px] w-full h-14"
              onClick={fetchResetPasswordAPI}
            />
          </div>
        </div>
        <Footer className={"bottom-[5px]"} />
      </UserAuthHome>
    </div>
  );
};

export default ResetPassword;
