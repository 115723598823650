import React from "react";
import { Redirect } from "react-router-dom";
import { getStoredData, getTokenDetails } from ".";
import { HOME } from "./routeConstants";
import { useLocation } from "react-router-dom";
import { DASHBOARD } from "../utils/routeConstants";

function RequireAuth({ children }) {
  const token = getTokenDetails();
  const orgId = getStoredData.getOrgId();
  const orgItem = getStoredData.getOrgItem();

  const location = useLocation();

  if (!token) {
    return <Redirect to={HOME} replace />;
  } else {
    if (location.pathname === DASHBOARD) {
      return children;
    } else {
      if (orgId && orgItem) return children;
      else return <Redirect to={DASHBOARD} replace />;
    }
  }
}

export default RequireAuth;
