import FilterDropdown from "../../../components/core/FilterDropdown";
import { useEffect } from "react";
import { organizationService } from "../../../services/organizationMX";
import { getOrganizationDetails } from "../../../utils";

const Customers = ({ filterHandler, customers, setCustomers, setLoading }) => {
  const orgId = getOrganizationDetails();
  const RECORD_LIMIT = 100;
  const PAGE = 1;

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = () => {
    setLoading(true);

    organizationService
      .fetchCustomers(orgId, PAGE, RECORD_LIMIT)
      .then((resp) => {
        if (resp) {
          setCustomers(resp?.results);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="logs-dropdown flex flex-col justify-between">
      <span className="text-[20px] font-bold text-[#003344] xl:text-[16px]">
        Customers
      </span>
      <FilterDropdown
        name="customerId"
        className=" bg-[#ffffff] h-[48px]"
        list={customers}
        onChange={filterHandler}
      />
    </div>
  );
};
export default Customers;
