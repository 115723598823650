import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { organizationService } from "../../services/organizationMX";
import Checkbox from "@mui/material/Checkbox";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { getOrganizationDetails } from "../../utils";
import { toastId } from "../../utils";
import { useLocation } from "react-router-dom";
import DropdownList from "../../components/core/DropdownList";
import AppLayout from "../../components/layout/AppLayout";
import { CUSTOMER } from "../../utils/routeConstants";
import { deepCopy } from "../../utils";
import { updateForm } from "./utils";

const ehrList = [
  "athena",
  "openepic",
  "cerner",
  "nextgen",
  "elationemr",
  "openemr",
];

const INITAL_STATE = {
  practice_id: {
    fieldValue: "",
    isError: false,
  },
  enterprise_id: {
    fieldValue: "",
    isError: false,
  },
  ehrName: "",
  appType: {
    isSystem: false,
    isProvider: false,
    isPatient: false,
  },
  connectionId: {
    isSystem: "",
    isProvider: "",
    isPatient: "",
  },
};

function AddConnectionsToCustomer() {
  const SYSTEM = "system";
  const PROVIDER = "provider";
  const PATIENT = "patient";
  const appTypeMap = new Map([
    ["isSystem", SYSTEM],
    ["isProvider", PROVIDER],
    ["isPatient", PATIENT],
  ]);

  const history = useHistory();
  const location = useLocation();

  const [formFields, setFormFields] = useState([]);

  const [selectedConnectionData, setSelectedConnectionData] = useState(
    deepCopy(INITAL_STATE)
  );
  const customers = useRef(null);
  const connections = useRef(null);
  const selectedConnectionInitalState = useRef(null);

  const onChangeHandler = ({ target: { name, value } }) => {
    let copySeletedConnection = deepCopy(selectedConnectionData);
    copySeletedConnection[name].fieldValue = value;

    if (name === "practice_id") {
      if (value === "") {
        copySeletedConnection.practice_id.isError = true;
      } else {
        copySeletedConnection.practice_id.isError = false;
      }
    }

    if (name === "enterprise_id") {
      if (value === "") {
        copySeletedConnection.enterprise_id.isError = true;
      } else {
        copySeletedConnection.enterprise_id.isError = false;
      }
    }
    setSelectedConnectionData(copySeletedConnection);
  };

  const showToastError = (appType) => {
    const toastMessage = `${selectedConnectionData.ehrName} EHR and ${appType} APP connection does not exist.`;
    toast.info(toastMessage);
  };

  const onCheckBoxHandler = (checkboxType) => {
    const copySelectedConnection = deepCopy(selectedConnectionData);

    if (checkboxType === SYSTEM)
      copySelectedConnection.appType.isSystem =
        !copySelectedConnection.appType.isSystem;
    else if (checkboxType === PROVIDER)
      copySelectedConnection.appType.isProvider =
        !copySelectedConnection.appType.isProvider;
    else if (checkboxType === PATIENT)
      copySelectedConnection.appType.isPatient =
        !copySelectedConnection.appType.isPatient;
    setSelectedConnectionData(copySelectedConnection);
  };

  useEffect(() => {
    customers.current = location.state.customer;
    connections.current = location.state.connection;
    const attachedConnections = location.state.customer.connection;
    const copySelectedConnection = deepCopy(selectedConnectionData);

    if (attachedConnections.length) {
      copySelectedConnection.ehrName = attachedConnections[0].ehr_name;
      if (attachedConnections[0].ehr_name === "athena")
        copySelectedConnection.practice_id.fieldValue =
          location.state.customer.practice_id;

      if (attachedConnections[0].ehr_name === "nextgen") {
        copySelectedConnection.practice_id.fieldValue =
          location.state.customer?.practice_id;
        copySelectedConnection.enterprise_id.fieldValue =
          location.state.customer?.enterprise_id;
      }
    }

    attachedConnections.map((connection) => {
      if (connection.app_type === SYSTEM)
        copySelectedConnection.appType.isSystem = true;
      else if (connection.app_type === PROVIDER)
        copySelectedConnection.appType.isProvider = true;
      else if (connection.app_type === PATIENT)
        copySelectedConnection.appType.isPatient = true;
    });
    selectedConnectionInitalState.current = copySelectedConnection;
  }, []);

  useEffect(() => {
    const { copySelectedConnectionData, formFieldsArray } = updateForm(
      connections,
      selectedConnectionData.ehrName
        ? deepCopy(selectedConnectionData)
        : selectedConnectionInitalState.current,
      showToastError,
      isConnectionValid,
      deepCopy(formFields),
      appTypeMap,
      selectedConnectionData.ehrName
        ? selectedConnectionData.ehrName
        : selectedConnectionInitalState.current.ehrName,
      selectedConnectionInitalState.ehrName
    );

    setSelectedConnectionData(copySelectedConnectionData);
    setFormFields(formFieldsArray);
  }, [
    selectedConnectionData.appType.isSystem,
    selectedConnectionData.appType.isProvider,
    selectedConnectionData.appType.isPatient,
    selectedConnectionData.ehrName,
  ]);

  const isConnectionValid = (connection, appType, ehrName) => {
    if (connection.ehr_name === ehrName && connection.app_type === appType)
      return true;
    else false;
  };

  const onSavehandler = () => {
    let selectedConnectionsArray = [],
      isFormvalid = true;

    Object.keys(selectedConnectionData.connectionId).map((ele) => {
      if (
        selectedConnectionData.connectionId[ele] &&
        selectedConnectionData.appType[ele]
      )
        selectedConnectionsArray.push(selectedConnectionData.connectionId[ele]);
    });

    let reqBody = {
      practice_id: selectedConnectionData.practice_id?.fieldValue,
      enterprise_id: selectedConnectionData.enterprise_id?.fieldValue,
      connection: selectedConnectionsArray,
      email: customers.current.email,
      name: customers.current.name,
    };

    let copySelectedConnectionData = deepCopy(selectedConnectionData);

    formFields.map((ele) => {
      if (!copySelectedConnectionData[ele.fieldName].fieldValue) {
        isFormvalid = false;
        copySelectedConnectionData[ele.fieldName].isError = true;
      }
    });

    setSelectedConnectionData(copySelectedConnectionData);

    if (isFormvalid) {
      organizationService
        .patchConnectionstoCustomer(
          customers.current.uuid,
          getOrganizationDetails(),
          reqBody
        )
        .then((resp) => {
          if (resp?.connection.length > 0) {
            toast.success("Connection Added Successfully!", {
              toastId,
            });
          }
          history.push(CUSTOMER.DASHBOARD);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <AppLayout>
      <section className="connection-container  bg-[#EDF6F9] p-[40px]">
        <div className="text-[20pselectedConnectionData.appType.x]">
          <span
            className="text-[#939393] cursor-pointer"
            onClick={() => history.push(CUSTOMER.DASHBOARD)}
          >
            Customers
          </span>
          <span>
            <ArrowForwardIosIcon fontSize="small" /> Add connections to customer
          </span>
        </div>
        <div className="bg-[#ffffff] pl-[50px] xl:pt-[25px] xl:pl-[25px]  overflow-auto mt-[2%]">
          <div className="flex items-center mt-[4%] mb-[2%] w-[full]">
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              Customer Name
            </span>
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              {customers.current?.name}
            </span>
          </div>
          <div className="flex items-center mt-[2%] mb-[2%] w-[full]">
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              Customer Email
            </span>
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              {customers.current?.email}
            </span>
          </div>
          <div className="flex items-center my-[2%]  w-[full]">
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              Customer ID
            </span>
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[40%]">
              {customers.current?.uuid}
            </span>
          </div>
          <div className="flex items-center my-[2%]  w-[full]">
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              Select EHR
            </span>
            <DropdownList
              dropdownName="ehr_name"
              className="w-[698px] h-[56px] rounded-[10px] mr-[50px]"
              list={ehrList}
              onChange={(e) => {
                setSelectedConnectionData({
                  ...selectedConnectionData,
                  ehrName: e.target.value,
                });
              }}
              selectedvalue={selectedConnectionData.ehrName}
              label="Select EHR Name"
              labelId="Select EHR Name"
            />
          </div>

          {selectedConnectionData.ehrName && (
            <div className="flex items-center my-[2%]  w-[full] text-[20px] font-lato text-[#3A3A3A]">
              <span className=" w-[20%]">Select EHR App Type</span>
              <div
                className="w-[12%] "
                onClick={() => onCheckBoxHandler(SYSTEM)}
              >
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  checked={selectedConnectionData.appType.isSystem}
                />{" "}
                System
              </div>
              <br />

              <div
                className=" w-[12%]"
                onClick={() => onCheckBoxHandler(PROVIDER)}
              >
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  checked={selectedConnectionData.appType.isProvider}
                />{" "}
                Provider
              </div>

              <br />
              <div
                className=" w-[12%]"
                onClick={() => onCheckBoxHandler(PATIENT)}
              >
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  checked={selectedConnectionData.appType.isPatient}
                />{" "}
                Patient
              </div>
            </div>
          )}

          {formFields &&
            formFields.map((item, index) => (
              <div className="flex items-center mt-[40px] w-full" key={index}>
                <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
                  {item.label}
                </span>
                <TextField
                  className="textinput-field w-[53.5%]"
                  name={item.fieldName}
                  helperText={
                    selectedConnectionData[item.fieldName].isError &&
                    "Required*"
                  }
                  error={selectedConnectionData[item.fieldName].isError}
                  label={item.label}
                  onChange={onChangeHandler}
                  value={selectedConnectionData[item.fieldName].fieldValue}
                  variant="outlined"
                  id={item.name}
                />
              </div>
            ))}
        </div>

        <div className="mt-[20px]">
          <button
            className="w-[160px] h-[56px] text-[20px] font-lato rounded-[5px] bg-[#ffffff] border-[1px]"
            onClick={() => history.push(CUSTOMER.DASHBOARD)}
          >
            CANCEL
          </button>
          <button
            className="w-[160px] h-[56px] text-[20px] text-[#ffffff] font-lato rounded-[5px] bg-[#076993] ml-[20px]"
            onClick={onSavehandler}
          >
            SAVE
          </button>
        </div>
      </section>
    </AppLayout>
  );
}

export default AddConnectionsToCustomer;
