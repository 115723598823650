export const DATA_MODELS = [
  { key: "Clinical Summary", value: "Clinical Summary" },
  { key: "Clinical Decisions", value: "Clinical Decisions" },
  { key: "Claim", value: "Claim" },
  { key: "Device", value: "Device" },
  { key: "Department", value: "Department" },
  { key: "Enrichment", value: "Enrichment" },
  { key: "Financial", value: "Financial" },
  { key: "Flowsheet", value: "Flowsheet" },
  { key: "Inventory", value: "Inventory" },
  { key: "Master", value: "Master" },
  { key: "Medications", value: "Medications" },
  { key: "Media", value: "Media" },
  { key: "Notes", value: "Notes" },
  { key: "Order", value: "Order" },
  { key: "Organization", value: "Organization" },
  { key: "PatientAdmin", value: "Patient Admin" },
  { key: "PatientEducation", value: "Patient Education" },
  { key: "PatientSearch", value: "Patient Search" },
  { key: "Provider", value: "Provider" },
  { key: "Referral", value: "Referral" },
  { key: "Research", value: "Research" },
  { key: "Results", value: "Results" },
  { key: "Scheduling", value: "Scheduling" },
  { key: "SSO", value: "SSO" },
  { key: "SurgicalScheduling", value: "Surgical Scheduling" },
  { key: "Vaccination", value: "Vaccination" },
];

export const SELECT_EVENT_TYPES = {
  "Clinical Summary": [
    "PatientQuery",
    "PatientQueryResponse",
    "PatientPush",
    "VisitQuery",
    "VisitQueryResponse",
    "VisitPush",
    "DocumentQuery",
    "DocumentQueryResponse",
    "DocumentGet",
    "DocumentGetResponse",
  ],
  "Clinical Decisions": ["Request", "Response"],
  Claim: ["SubmissionBatch", "PaymentBatch", "Submission", "Payment"],
  Department: ["Query", "Response"],
  Device: ["New"],
  Enrichment: [
    "NaturalLanguageProcessingQuery",
    "NaturalLanguageProcessingQueryResponse",
    "NormalizationQuery",
    "NormalizationQueryResponse",
  ],
  Financial: ["Transaction", "AccountUpdate"],
  Flowsheet: ["New"],
  Inventory: ["Update", "Deplete"],
  Master: ["Query", "Response"],
  Medications: ["New", "Update", "Cancel", "Administration"],
  Media: ["New", "Replace", "Delete", "Query", "QueryResponse"],
  Notes: ["New", "Replace", "Delete", "Query", "QueryResponse"],
  Order: ["New", "Update", "Cancel", "GroupedOrders", "Query", "QueryResponse"],
  Organization: ["New", "Update", "Query", "QueryResponse"],
  PatientAdmin: [
    "Arrival",
    "Cancel",
    "Discharge",
    "NewPatient",
    "PatientUpdate",
    "PatientMerge",
    "PreAdmit",
    "Registration",
    "Transfer",
    "VisitMerge",
    "VisitUpdate",
    "CensusQuery",
    "CensusQueryResponse",
    "VisitQuery",
    "VisitQueryResponse",
    "CheckOut",
  ],
  PatientEducation: ["New", "Update", "Delete"],
  PatientSearch: [
    "Query",
    "Response",
    "LocationQuery",
    "LocationQueryResponse",
  ],
  Provider: [
    "New",
    "Update",
    "Activate",
    "Deactivate",
    "ProviderQuery",
    "ProviderQueryResponse",
  ],
  Referral: [
    "New",
    "Modify",
    "Cancel",
    "AuthReview",
    "AuthResponse",
    "Query",
    "QueryResponse",
  ],
  Research: ["Study", "SubjectUpdate"],
  Results: ["New", "NewUnsolicited", "Query", "QueryResponse"],
  Scheduling: [
    "New",
    "Reschedule",
    "Modification",
    "Cancel",
    "NoShow",
    "PushSlots",
    "AvailableSlots",
    "AvailableSlotsResponse",
    "Booked",
    "BookedResponse",
    "PushTypes",
    "AvailableTypes",
    "AvailableTypesResponse",
  ],
  SSO: ["Sign-on"],
  SurgicalScheduling: ["New", "Reschedule", "Modification", "Cancel", "NoShow"],
  Vaccination: [
    "New",
    "Administration",
    "PatientQuery",
    "PatientQueryResponse",
  ],
};

export const LOG_STATUS = ["Any", "Failed", "Filtered", "Pending", "Succeeded"];

export const EVENT_TYPES = [
  "New",
  "QueryResponse",
  "Query",
  "Update",
  "Study",
  "SubjectUpdate",
  "Reschedule",
  "Modification",
  "Cancel",
  "GroupedOrders",
  "AuthResponse",
  "Modify",
  "AuthReview",
  "Administration",
  "Transaction",
  "AccountUpdate",
  "AvailableSlots",
  "AvailableSlotsResponse",
  "PushSlots",
  "BookedResponse",
  "Booked",
  "ProviderQuery",
  "Activate",
  "Deactivate",
  "ProviderQueryResponse",
  "NewUnsolicited",
  "LocationQueryResponse",
  "LocationQuery",
  "Response",
  "DocumentQueryResponse",
  "VisitPush",
  "DocumentGet",
  "PatientPush",
  "PatientQuery",
  "DocumentQuery",
  "VisitQueryResponse",
  "PatientQueryResponse",
  "VisitQuery",
  "DocumentGetResponse",
  "Sign-on",
  "Replace",
  "CensusQuery",
  "VisitUpdate",
  "PatientMerge",
  "Transfer",
  "Arrival",
  "Registration",
  "VisitMerge",
  "Discharge",
  "NewPatient",
  "PreAdmit",
  "PatientUpdate",
  "CensusQueryResponse",
  "Deplete",
  "Request",
  "SubmissionBatch",
  "Payment",
  "Submission",
  "PaymentBatch",
  "Delete",
  "NoShow",
  "AvailableTypes",
  "PushTypes",
  "AvailableTypesResponse",
  "CheckOut",
];

export const TYPE = ["Any", "Receive", "Request", "Respond", "Send", "Unknown"];
