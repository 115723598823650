const generateAbsPath = (relativePath) =>
  // console.log("import.meta  ", import.meta);
  `${import.meta.env.VITE_MICROSERVICE_API_BASE_URL?.replace(
    /\/$/,
    ""
  )}/${relativePath.replace(/^\//, "")}`;
const AUTH_SERVICE_BASE_URL = generateAbsPath("/api/auth");
const ORG_SERVICE_BASE_URL = generateAbsPath("/api/organizations");
const CONNECTION_SERVICE_BASE_URL = generateAbsPath("/api/connections");
const APIKEY_SERVICE_BASE_URL = generateAbsPath("/api/apikeys");
const WEBHOOK_SERVICE_BASE_URL = generateAbsPath("/api/webhook");
const CONFIGURATION_SERVICE_BASE_URL = generateAbsPath("/api/configuration");
const USER_SERVICE_BASE_URL = generateAbsPath("/api/user");
const LOGS_SERVICE_BASE_URL = generateAbsPath("/api/logs");
const DATAMODEL_SERVICE_BASE_URL = generateAbsPath("/api/datamodel");
const EVENT_TYPE_SERVICE_BASE_URL = generateAbsPath("/api/eventtype");
const CUSTOMERS_SERVICE_BASE_URL = generateAbsPath("/api/connection");

/** ***************************************** Auth ********************************************* */
export const POST_LOGIN = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/login/`,
};

export const POST_LOGOUT = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/logout/`,
};

export const POST_FORGOT = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/password/reset/`,
};

export const POST_RESET = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/password/reset/confirm/`,
};

export const POST_REGISTER = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/registration/`,
};

export const POST_VERIFYEMAIL = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/registration/verify-email/`,
};

export const POST_REFRESH_TOKEN = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/token/refresh/`,
};

export const PUT_CHANGE_USERS_PASSWORD = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/password/change/`,
};

export const GET_CURRENT_USER = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/user/`,
};

export const PUT_UPDATE_USERS_DATA = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/user/`,
};

/** ***************************************** Organizations ********************************************* */
export const GET_ORGANIZATIONS = {
  endpoint: (page, limit) =>
    `${ORG_SERVICE_BASE_URL}?page=${page ?? 1}&page_size=${limit ?? 10}`,
};

export const POST_CREATE_ORGANIZATIONS = {
  endpoint: `${ORG_SERVICE_BASE_URL}`,
};

export const GET_ORGANIZATIONS_BY_ID = {
  endpoint: (orgId) => `${ORG_SERVICE_BASE_URL}/${orgId}`,
};

export const GET_ORGANIZATIONS_PROFILE_BY_ID = {
  endpoint: (orgId) => `${ORG_SERVICE_BASE_URL}/${orgId}/profile`,
};

export const PATCH_ORGANIZATIONS_BY_ID = {
  endpoint: (orgId) => `${ORG_SERVICE_BASE_URL}/${orgId}`,
};

export const GET_USER_INFO = {
  endpoint: (page) => `${ORG_SERVICE_BASE_URL}/user?page=${page}`,
};

export const PUT_ORGANIZATION_PROFILE_BY_ID = {
  endpoint: (orgId) => `${ORG_SERVICE_BASE_URL}/${orgId}/uploadImage`,
  successStatusCode: 200,
};

export const DELETE_ORGANIZATION_BY_ID = {
  endpoint: (orgId) => `${ORG_SERVICE_BASE_URL}/${orgId}`,
};

export const POST_ORGANIZATION = {
  endpoint: (orgId) => `${ORG_SERVICE_BASE_URL}/${orgId}/connections`,
};

export const GET_CUSTOMER_BY_ORGANIZATION_ID = {
  endpoint: (orgId, page, limit) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/customers?page=${page}&page_size=${limit}`,
};

export const POST_CUSTOMER_BY_ORGANIZATION_ID = {
  endpoint: (orgId) => `${ORG_SERVICE_BASE_URL}/${orgId}/customers`,
};

export const GET_PROVIDERS_BY_CUSTOMERS_ID = {
  endpoint: (orgId, customerId, limit, page) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/customers/${customerId}/providers?page=${page}&page_size=${limit}`,
};

export const GET_PATIENTS_BY_CUSTOMERS_ID = {
  endpoint: (orgId, customerId, limit, page) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/customers/${customerId}/patients?page=${page}&page_size=${limit}`,
};

export const DELETE_CUSTOMER_BY_ID = {
  endpoint: (orgId, customerId) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/customers/${customerId}`,
};

export const PATCH_CUSTOMER_BY_ID = {
  endpoint: (orgId, customerId) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/customers/${customerId}`,
};
/** ***************************************** Connection ********************************************* */

export const GET_CONNECTIONS_BY_ORGANIZATION_ID = {
  endpoint: (page, orgId, limit) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/connections?page=${page}&page_size=${limit}`,
};

export const POST_CONNECTIONS_BY_CUSTOMER_ID = {
  endpoint: (customerId, orgId) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/customers/${customerId}`,
};

export const DELETE_CONNECTION_BY_ID = {
  endpoint: (orgId, connectionId) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/connections/${connectionId}`,
};

export const PATCH_CONNECTION_BY_ID = {
  endpoint: (orgId, connectionId) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/connections/${connectionId}`,
};

export const GET_CONNECTIONS = {
  endpoint: (page) => `${CONNECTION_SERVICE_BASE_URL}?page=${page}`,
};

export const GET_CUSTOMERS_BY_CONNECTION_ID = {
  endpoint: (connectionId, page) =>
    `${CUSTOMERS_SERVICE_BASE_URL}/${connectionId}/customer?page=${page}`,
};

export const GET_CONNECTION_BY_ORGANISATION_ID = {
  endpoint: (orgId) => `${CONNECTION_SERVICE_BASE_URL}/${orgId}`,
  successStatusCode: 200,
};

export const GET_CONNECTION_DETAILS_BY_ORGANISATION_ID = {
  endpoint: (id, orgId) => `${CONNECTION_SERVICE_BASE_URL}/${id}/${orgId}`,
  successStatusCode: 200,
};

export const POST_CONNECTION_DETAILS = {
  endpoint: `${CONNECTION_SERVICE_BASE_URL}`,
  successStatusCode: 200,
};

/** ***************************************** APIkeys ********************************************* */
export const GET_API_KEYS_BY_ORGANISATION_ID = {
  endpoint: (page, offSet, limit, search, orgId, environment) =>
    `${APIKEY_SERVICE_BASE_URL}/${orgId}?environment=${environment}&pageNumber=${
      page ?? 1
    }&offSet=${offSet ?? 0}&limit=${limit ?? 10}${search && `&name=${search}`}`,
  successStatusCode: 200,
};

export const GET_GENERATE_API_KEY = {
  endpoint: (orgId) => `${APIKEY_SERVICE_BASE_URL}/generateAPIkey`,
  successStatusCode: 200,
};

export const GET_GENERATE_SOURCE_SECRET_CODE = {
  endpoint: (orgId) => `${APIKEY_SERVICE_BASE_URL}/generateSourceCode`,
  successStatusCode: 200,
};

export const POST_CREATE_API_KEY = {
  endpoint: `${APIKEY_SERVICE_BASE_URL}`,
  successStatusCode: 200,
};

export const PUT_UPDATE_API_KEY = {
  endpoint: (apiKeyID) => `${APIKEY_SERVICE_BASE_URL}/${apiKeyID}`,
  successStatusCode: 200,
};

/** ***************************************** WebHook ********************************************* */

export const GET_WEBHOOK_BY_ORGANISATION_ID = {
  endpoint: (orgId, pageNo, limit) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/webhooks?page=${pageNo}&page_size=${limit}`,
};

export const SEARCH_ORGANIZATION = {
  endpoint: (searchText) => `${ORG_SERVICE_BASE_URL}?search=${searchText}`,
};

export const SEARCH_CONNECTION_BY_ORGANISATION_ID = {
  endpoint: (orgId, searchText) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/connections?search=${searchText}`,
};

export const SEARCH_WEBHOOK_BY_ORGANISATION_ID = {
  endpoint: (orgId, searchText) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/webhooks?search=${searchText}`,
};

export const SEARCH_CUSTOMER_BY_ORGANISATION_ID = {
  endpoint: (orgId, searchText) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/customers?search=${searchText}`,
};

export const GET_WEBHOOK_BY_WEBHOOK_ID = {
  endpoint: (orgId, webhookId) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/webhooks/${webhookId}`,
};

export const DELETE_WEBHOOK = {
  endpoint: (orgId, webHookId) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/webhooks/${webHookId}`,
};

export const POST_WEBHOOK = {
  endpoint: (orgId) => `${ORG_SERVICE_BASE_URL}/${orgId}/webhooks`,
};

export const PATCH_WEBHOOK = {
  endpoint: (orgId, webhookId) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/webhooks/${webhookId}`,
};

export const PATCH_WEBHOOK_TOKEN = {
  endpoint: (orgId, webHookId) =>
    `${ORG_SERVICE_BASE_URL}/${orgId}/webhooks/${webHookId}/token`,
};

export const POST_CREATE_WEB_HOOK = {
  endpoint: `${WEBHOOK_SERVICE_BASE_URL}`,
  successStatusCode: 200,
};

export const PUT_UPDATE_WEB_HOOK = {
  endpoint: (webHookId) => `${WEBHOOK_SERVICE_BASE_URL}/${webHookId}`,
  successStatusCode: 200,
};

export const GET_GENERATE_VERIFICATION_TOKEN = {
  endpoint: (orgId) => `${WEBHOOK_SERVICE_BASE_URL}/generateVerificationCode`,
  successStatusCode: 200,
};

/** ***************************************** Configurations ********************************************* */
export const GET_CONFIGURATION_BY_CONNECTION_ID = {
  endpoint: (connectionId) =>
    `${CONFIGURATION_SERVICE_BASE_URL}/${connectionId}`,
  successStatusCode: 200,
};

/** ***************************************** User's ********************************************* */
export const POST_CREATE_USER = {
  endpoint: `${USER_SERVICE_BASE_URL}/createuser`,
  successStatusCode: 200,
};

export const GET_INVITATION_PENDING_USER_INFO = {
  endpoint: (orgId) => `${USER_SERVICE_BASE_URL}/invitedusers/${orgId}`,
  successStatusCode: 200,
};

export const GET_USER_BY_ID = {
  endpoint: (userId, orgId) => `${USER_SERVICE_BASE_URL}/${userId}/${orgId}`,
  successStatusCode: 200,
};

export const PUT_UPDATE_USERS_MFA_DATA = {
  endpoint: `${USER_SERVICE_BASE_URL}/mfa`,
  successStatusCode: 200,
};

export const DELETE_INVITED_USER = {
  endpoint: `${USER_SERVICE_BASE_URL}/removeinvitation`,
  successStatusCode: 200,
};

export const PUT_UPDATE_PROFILE_PIC = {
  endpoint: `${USER_SERVICE_BASE_URL}/updateimage`,
  successStatusCode: 200,
};

export const PATCH_UPDATE_USER_DATA_BY_USER_ID = {
  endpoint: (userId, orgId) =>
    `${USER_SERVICE_BASE_URL}/${userId}?orgId=${orgId}`,
  successStatusCode: 200,
};

/** ***************************************** Logs ********************************************* */
export const POST_LIST_ALL_LOGS_DATA = {
  endpoint: (page, offset, limit, environment) =>
    `${LOGS_SERVICE_BASE_URL}?pageNumber=${page ?? 1}&offSet=${
      offset ?? 0
    }&limit=${limit ?? 10}&environment=${environment}`,
  successStatusCode: 200,
};

export const GET_LOG_DETAILS_BY_LOG_ID = {
  endpoint: (parentLogId) => `${LOGS_SERVICE_BASE_URL}/${parentLogId}`,
  successStatusCode: 200,
};

export const GET_LOG_DETAILS_BY_ORG_ID = {
  endpoint: (
    dataModel,
    eventType,
    connectionId,
    customerId,
    StartDate,
    EndDate,
    orgId,
    page,
    limit
  ) => {
    let url = `${LOGS_SERVICE_BASE_URL}?${
      dataModel ? `DataModel=${dataModel}&` : ""
    }${eventType ? `EventType=${eventType}&` : ""}${
      connectionId ? `connectionid=${connectionId}&` : ""
    }${customerId ? `customerid=${customerId}&` : ""}${
      StartDate ? `StartDate=${StartDate}&` : ""
    }${EndDate ? `EndDate=${EndDate}&` : ""}${"organizationid=" + orgId}${
      "&page=" + page
    }${"&page_size=" + limit}`;

    return url;
  },
};

/** ***************************************** Data Model ********************************************* */
export const GET_ALL_DATA_MODELS = {
  endpoint: `${DATAMODEL_SERVICE_BASE_URL}`,
  successStatusCode: 200,
};

/** ***************************************** Event Type ********************************************* */
export const GET_EVENT_TYPE_DETAILS_BY_DATA_MODEL_ID = {
  endpoint: (dataModelId) => `${EVENT_TYPE_SERVICE_BASE_URL}/${dataModelId}`,
  successStatusCode: 200,
};
