import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/logs.scss";
import LogItem from "./LogItem";
import AppLayout from "../../components/layout/AppLayout";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import {
  bgColorBtnSelector,
  calculateTotalPageCount,
  getOrganizationDetails,
  statusSelector,
} from "../../utils";
import { logsService } from "../../services/logsMX";
import moment from "moment-timezone";
import Loader from "../../components/core/Loader";
import DataNotAvailable from "../../components/core/DataNotAvailable";
import { LOGS_FILTERS } from "../../services/initialValues";
import Footer from "../../components/core/Footer";
import ReactPaginate from "react-paginate";
import Filters from "./Filters";

const Logs = () => {
  const [loading, setLoading] = useState(false);

  const [logsData, setLogsData] = useState([]);
  const [displayObject, setDisplayObject] = useState({});

  const [activeLogItemIndex, setActiveLogItemIndex] = useState(-1);

  const [jsonObjSection, setJsonObjSection] = useState(false);

  const [isLogFlowOpen, setIsLogFLowOpen] = useState(false);
  const [showLogFilters, setShowLogFilters] = useState(true);
  const [ehrAPIs, setEhrAPIs] = useState([]);
  const [ehrType, setEhrType] = useState([]);

  // Filters required data
  const [filters, setFilters] = useState(LOGS_FILTERS);
  const [totalLogsCount, setTotalLogsCount] = useState(10);

  // Filter Handler
  const filterHandler = ({ target: { name, value } }) => {
    setFilters({ ...filters, [name]: value });
  };

  let timeStamp = moment(logsData[activeLogItemIndex]?.log?.timeStamp).format(
    "DD-MM-YYYY hh:mm:ss A"
  );
  const orgId = getOrganizationDetails(),
    PAGE = 1,
    LOG_RECORD_LIMIT = 6;

  const updateDisplayObject = (
    methodType = "",
    statusCode = "",
    object = { body: null }
  ) => {
    const displayObject = {
      methodType: methodType,
      statusCode: statusCode,
      object: object,
    };
    setDisplayObject(displayObject);
  };

  const updateEhrDisplayObject = (ehrIndex) => {
    if (ehrType === "ehrReq") {
      const displayObject = {
        methodType: logsData[activeLogItemIndex]?.ehr_method[ehrIndex],
        statusCode: logsData[activeLogItemIndex]?.ehr_status_code[ehrIndex],
        object: logsData[activeLogItemIndex]?.ehr_body[ehrIndex],
      };

      setDisplayObject(displayObject);
    } else {
      const displayObject = {
        methodType: logsData[activeLogItemIndex]?.ehr_method[ehrIndex],
        statusCode: logsData[activeLogItemIndex]?.ehr_status_code[ehrIndex],
        object: logsData[activeLogItemIndex]?.ehr_response[ehrIndex],
      };
      setDisplayObject(displayObject);
    }
  };

  const logItemHandler = (stageType) => {
    setJsonObjSection(true);
    updateDisplayObject();
    switch (stageType) {
      case "medReq":
        setEhrAPIs([]);
        updateDisplayObject(
          logsData[activeLogItemIndex]?.method,
          logsData[activeLogItemIndex]?.status_code,
          logsData[activeLogItemIndex]?.body
        );
        break;
      case "medRes":
        setEhrAPIs([]);
        updateDisplayObject(
          logsData[activeLogItemIndex]?.method,
          logsData[activeLogItemIndex]?.status_code,
          logsData[activeLogItemIndex]?.response
        );
        break;
      case "webhook":
        setEhrAPIs([]);
        updateDisplayObject(
          logsData[activeLogItemIndex]?.webhook_method,
          logsData[activeLogItemIndex]?.status_code,
          logsData[activeLogItemIndex]?.webhook_body
            ? logsData[activeLogItemIndex]?.webhook_body
            : {
                webhook: null,
              }
        );
        break;
      case "ehrReq":
        setEhrType("ehrReq");
        setEhrAPIs(logsData[activeLogItemIndex]?.ehr_api);

        break;
      case "ehrRes":
        setEhrAPIs(logsData[activeLogItemIndex]?.ehr_api);
        setEhrType("ehrRes");
        break;
    }
  };

  useEffect(() => {
    getLogs(1);
  }, [filters]);

  // GET Logs by using organization ID
  const getLogs = (page = PAGE) => {
    setLoading(true);
    logsService
      .getLogsByOrgId({ ...filters }, orgId, page, LOG_RECORD_LIMIT)
      .then((res) => {
        let logData = res.results;

        res.results.map((ele, index) => {
          logData[index].id = ele.uuid;
          logData[index].added_on = ele.added_on;
          logData[index].api = ele.api;
          logData[index].headers = ele.headers ? JSON.parse(ele.headers) : "";
          logData[index].body = ele.body ? JSON.parse(ele.body) : "";
          logData[index].method = ele.method;
          logData[index].client_ip_address = ele.client_ip_address;
          logData[index].response = ele.response
            ? JSON.parse(ele.response)
            : "";

          logData[index].ehr_api = ele.ehr_api;
          logData[index].ehr_body = ele.ehr_body ? ele.ehr_body.slice() : "";
          logData[index].ehr_response = ele.ehr_response;
          logData[index].ehr_method = ele.ehr_method;
          logData[index].ehr_status_code = ele.ehr_status_code;

          logData[index].status_code = ele.status_code;
          logData[index].execution_time = ele.execution_time;
          logData[index].organization = ele.organization;
          logData[index].connection = ele.connection;
          logData[index].customer = ele.customer;
        });

        setLogsData(logData);
        setTotalLogsCount(res.count);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <AppLayout>
      {loading && <Loader />}
        <div className="logs-container">
          <div className="log-header">
            <h1 className="font-lato text-[40px] text-[#003344] xl:text-[30px]">
              Logs
            </h1>
            <div className="log-buttons xl:w-[24%]">
              <button
                className="filled-custom-btn text-[20px]  bg-[#076993] text-[#ffffff]   w-[144px] h-[36px] text-[#3A3A3A] xl:w-[110px] xl:text-[16px] xl:h-[28px] rounded-[5px]"
                onClick={() => setShowLogFilters(!showLogFilters)}
              >
                {`${showLogFilters ? "Hide" : "Show"}` + " Filters"}
              </button>
              {/* <button className="text-[20px] bg-[#076993] text-[#ffffff]  w-[144px] h-[36px] text-[#3A3A3A]text-[#ffffff] rounded-[5px] xl:w-[110px] xl:text-[16px] xl:h-[28px] ml-[20px]">
                Share
              </button> */}
            </div>
          </div>

          {showLogFilters && (
            <Filters
              filters={filters}
              setFilters={setFilters}
              filterHandler={filterHandler}
              setLoading={setLoading}
            />
          )}

          {logsData?.length !== 0 ? (
            <div
              className={
                "logs-block flex " +
                `${showLogFilters && "filter-active-height"}`
              }
            >
              <div className="logs-list-block">
                <>
                  {logsData?.length !== 0 ? (
                    logsData.map((_logItem, index) => (
                      <Fragment key={`log-data-${index}`}>
                        <LogItem
                          // status={statusSelector(_logItem?.status_code)}
                          date={_logItem?.added_on}
                          name={_logItem?.body?.Meta?.DataModel}
                          eventType={_logItem?.body?.Meta?.EventType}
                          clickLogItem={() => {
                            setIsLogFLowOpen(true);
                            setActiveLogItemIndex(index);
                            setJsonObjSection(false);
                          }}
                          activeLogItem={index === activeLogItemIndex}
                        />
                      </Fragment>
                    ))
                  ) : (
                    <div className="logItem-container">
                      <p>Logs Data not available</p>
                    </div>
                  )}

                  {totalLogsCount > LOG_RECORD_LIMIT && (
                    <div className="d-flex fixed bottom-[55px] w-[calc(26%_+_4px)] justify-end items-center bg-[#fff] h-[40px] border-top-[#CECECE]">
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={calculateTotalPageCount(
                          totalLogsCount,
                          LOG_RECORD_LIMIT
                        )}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={(selectedItem) =>
                          getLogs(selectedItem?.selected + 1)
                        }
                        containerClassName="pagination"
                        activeClassName="active"
                      />
                    </div>
                  )}
                </>
              </div>

              {isLogFlowOpen && (
                <div className="log-flow-container justify-between">
                  <div className="flex mt-[10px]  text-[#3A3A3A] xl:text-[16px] xl:mt-[2px]">
                    {" "}
                    <div className="ml-[20px]">
                      <span>Log: </span>
                      <span className="text-[#939393] ml-2">
                        {logsData[activeLogItemIndex]?.id || "-"}
                      </span>
                    </div>
                    <div className=" ml-[20px]">
                      <span>Timestamp: </span>
                      <span className="text-[#939393] ml-2">
                        {timeStamp || "-"}
                      </span>
                    </div>
                    <div className="ml-[20px]">
                      <span> Execution Time:</span>
                      <span className="text-[#939393] ml-2">
                        {logsData[activeLogItemIndex]?.execution_time || "-"}
                      </span>
                    </div>
                  </div>
                  <div className="flex ml-[20px]">
                    <div>
                      <span>Connection Id: </span>
                      <span className="text-[#939393] ml-2">
                        {logsData[activeLogItemIndex]?.connection || "-"}
                      </span>
                    </div>
                    <div className="ml-4">
                      <span>Customer Id: </span>
                      <span className="text-[#939393] ml-2">
                        {logsData[activeLogItemIndex]?.customer || "-"}
                      </span>
                    </div>
                  </div>

                  <div className="flow-block">
                    <div
                      className="log-flow-item"
                      onClick={() => {
                        logItemHandler("medReq");
                      }}
                    >
                      <div className="   ">
                        <div className="flex text-[20px] text-[#3A3A3A] xl:text-[16px]">
                          <p className=" ">IntraConnects Request</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="log-flow-item"
                      onClick={() => {
                        logItemHandler("ehrReq");
                      }}
                    >
                      <div className="flex  mb-[5px]">
                        <p className="text-[20px] text-[#3A3A3A] xl:text-[16px]">
                          EHR Request
                        </p>
                      </div>
                    </div>
                    <div
                      className="log-flow-item"
                      onClick={() => {
                        logItemHandler("medRes");
                      }}
                    >
                      <div className="  mb-[5px]">
                        <div className="flex">
                          <p className="text-[20px] text-[#3A3A3A] xl:text-[16px]">
                            IntraConnects Response
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="log-flow-item"
                      onClick={() => {
                        logItemHandler("ehrRes");
                      }}
                    >
                      <div className="flex  mb-[5px]">
                        <p className="text-[20px] text-[#3A3A3A] xl:text-[16px]">
                          EHR Response
                        </p>
                      </div>
                    </div>
                    <div
                      className="log-flow-item"
                      onClick={() => logItemHandler("webhook")}
                    >
                      <div className="flex  mb-[5px]">
                        <p className="text-[20px] text-[#3A3A3A] xl:text-[16px]">
                          Webhook
                        </p>
                      </div>
                    </div>
                  </div>

                  {jsonObjSection && (
                    <div className="json-obj-block">
                      <div className="api-block cursor-pointer">
                        {ehrAPIs?.map((ehrApI, index) => (
                          <div
                            className={`api-col`}
                            key={index}
                            onClick={() => updateEhrDisplayObject(index)}
                          >
                            {ehrApI}
                          </div>
                        ))}
                      </div>
                      <div className="request-response-block">
                        <div className="editor-header flex items-center h-[50px] xl:h-[32px]">
                          <div className="">
                            <span>Communication Method: </span>
                            <span className="text-[#939393] ml-2">
                              {displayObject.methodType || "N/A"}
                            </span>
                          </div>

                          <div className="flex ml-[5%]">
                            <span
                              className="rounded-[5px] font-lato text-[18px] px-[9px] py-[6px] xl:text-[14px]"
                              style={bgColorBtnSelector(
                                statusSelector(displayObject.statusCode)
                              )}
                            >
                              {displayObject?.statusCode
                                ? statusSelector(displayObject?.statusCode)
                                : "N/A"}
                            </span>
                          </div>
                        </div>

                        <div
                          className={`json-input-block ${
                            ehrAPIs.length > 0
                              ? `filter-active-json-input-block-ehr`
                              : `filter-active-json-input-block`
                          }`}
                        >
                          <JSONInput
                            id="a_unique_id"
                            placeholder={displayObject && displayObject.object}
                            theme="light_mitsuketa_tribute"
                            colors={{
                              default: "#939393",
                              background: "#fff",
                              string: "#939393",
                              keys: "#939393",
                              number: "#939393",
                              colon: "#939393",
                              keys_whiteSpace: "#939393",
                              primitive: "#939393",
                            }}
                            locale={locale}
                            height="100%"
                            width="100%"
                            viewOnly={false}
                            confirmGood={false}
                            style={{
                              contentBox: {
                                color: "#000",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <DataNotAvailable data={"Logs"} className="mt-[20px]" />
          )}
        </div>
        <Footer className={"bottom-[5px]"} />
    </AppLayout>
  );
};

export default Logs;
