import React from "react";
import AppLayout from "../../components/layout/AppLayout";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DropdownList from "../../components/core/DropdownList";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { CONNECTIONS } from "../../utils/routeConstants";
import selectConstants from "./constants";
import { TextField } from "@mui/material";
import { organizationService } from "../../services/organizationMX";
import { getOrganizationDetails, nameRegex, webSiteRegex } from "../../utils";
import CustomCenterModal from "../../components/core/CustomCenterModal";

const list = [
  "athena",
  "openepic",
  "cerner",
  "nextgen",
  "elationemr",
  "openemr",
];
const APP_TYPE_LIST = ["system", "provider", "patient"];

function ConnectionUpdate() {
  const [connectionData, setConnectionData] = useState("");
  const [appTypeList, setAppTypeList] = useState(APP_TYPE_LIST);
  const [formFieldsArray, setFormFieldsArray] = useState([]);

  const [isFieldsValid, setIsFieldsValid] = useState(false);
  const [isDataValid, setIsDataValid] = useState(true);

  const [connectionValue, setConnectionValue] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const NAME_CHARACTER_LIMIT_ERROR =
    "This field should have less then 60 character";
  const FIELD_CHARACTER_LIMIT_ERROR =
    "This field should have less then 250 character";
  const URL_CHARACTER_LIMIT_ERROR =
    "This field should have less then 250 character";
  const URL_FORMAT_ERROR = "URL field should be in the right format";
  const NAME_FORMAT_ERROR = "Special characted not allowed";
  const REQUIRED_MESSAGE = "Required";

  useEffect(() => {
    setConnectionData(location.state);

    if (location.state?.ehr_name === "nextgen") {
      setAppTypeList(["system", "patient"]);
    }

    setFormFieldsArray(
      selectConstants(location.state?.ehr_name, location.state?.app_type)
    );
  }, []);

  useEffect(() => {
    setFormFieldsArray(
      selectConstants(connectionData?.ehr_name, connectionData?.app_type)
    );

    if (connectionData.ehr_name === "nextgen")
      setAppTypeList(["system", "patient"]);
    else if (
      connectionData.ehr_name === "openemr" ||
      connectionData.ehr_name === "elationemr"
    )
      setAppTypeList(["system"]);
    else setAppTypeList(APP_TYPE_LIST);
  }, [connectionData.ehr_name, connectionData.app_type]);

  function changeFormFields({ target: { name, value } }) {
    setConnectionData({ ...connectionData, [name]: value });

    let copyFormFieldsArray = formFieldsArray;

    const fieldIndex = copyFormFieldsArray.findIndex(
      (ele) => ele.fieldName === name
    );

    const item = copyFormFieldsArray[fieldIndex];
    if (item) {
      if (value) {
        item.error = false;
        item.message = "";
        setIsFieldsValid(false);

        if (value.length > 60 && item.fieldName === "connection_name") {
          item.error = true;
          item.message = NAME_CHARACTER_LIMIT_ERROR;
          setIsFieldsValid(true);
        } else if (value.length > 250) {
          item.error = true;
          item.message = FIELD_CHARACTER_LIMIT_ERROR;
          setIsFieldsValid(true);

          if (item.fieldName === "scope") {
            item.error = false;
            item.message = "";
            setIsFieldsValid(false);
          } else if (item.fieldName === "private_key") {
            item.error = false;
            item.message = "";
            setIsFieldsValid(false);
          } else if (item.fieldName === "access_token") {
            item.error = false;
            item.message = "";
            setIsFieldsValid(false);
          } else if (item.fieldName === "refresh_token") {
            item.error = false;
            item.message = "";
            setIsFieldsValid(false);
          } else if (item.fieldName === "org_redirect_uri") {
            if (value.length > 1000) {
              item.error = true;
              item.message = URL_CHARACTER_LIMIT_ERROR;
              setIsFieldsValid(true);
            } else {
              item.error = false;
              item.message = "";
              setIsFieldsValid(false);
            }
          }
        } else if (item.fieldName === "org_redirect_uri") {
          if (webSiteRegex.test(value)) {
            item.error = false;
            item.message = "";
            setIsFieldsValid(false);
          } else {
            item.error = true;
            item.message = URL_FORMAT_ERROR;
            setIsFieldsValid(true);
          }
        } else if (item.fieldName === "connection_name") {
          if (nameRegex.test(value)) {
            item.error = false;
            item.message = "";
            setIsFieldsValid(false);
          } else {
            item.error = true;
            item.message = NAME_FORMAT_ERROR;
            setIsFieldsValid(true);
          }
        }
      } else {
        if (value === "" && item.isReq === true) {
          item.error = true;
          item.message = REQUIRED_MESSAGE;
          setIsFieldsValid(true);
        } else if (value === "" && item.isReq === false) {
          item.error = false;
          item.message = "";
          setIsFieldsValid(false);
        }
      }
    }

    setFormFieldsArray(copyFormFieldsArray);
  }

  function saveUpdatedData() {
    const orgId = getOrganizationDetails();

    let copyFormFieldsArray = formFieldsArray;
    let isFormValid = true;

    copyFormFieldsArray.map((item) => {
      if (item.isReq) {
        if (!connectionData[item.fieldName]) {
          item.error = true;
          item.message = REQUIRED_MESSAGE;
          isFormValid = false;
          setIsDataValid(!isDataValid);
        } else {
          item.error = false;
          item.message = "";
          setIsDataValid(true);
        }
      }
    });

    setFormFieldsArray(copyFormFieldsArray);

    if (isFormValid && !isFieldsValid) {
      organizationService
        .updateConnection(orgId, connectionData?.uuid, connectionData)
        .then((res) => {
          if (res) history.push(CONNECTIONS.DASHBOARD);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }

  return (
    <AppLayout>
      <section className="connection-container bg-[#EDF6F9] p-[40px]  ">
        <div className="text-[20px]">
          <span
            className="text-[#939393] cursor-pointer"
            onClick={() => history.push(CONNECTIONS.DASHBOARD)}
          >
            Connections
          </span>
          <span>
            <ArrowForwardIosIcon fontSize="small" /> Update Connection
          </span>
        </div>
        <div className="bg-[#ffffff] pl-[5%] tabcontainers overflow-auto mt-[2%]">
          <div className="bg-[#ffffff] pl-[50px] xl:pt-[25px] xl:pl-[25px] tabcontainers overflow-auto">
            <div className="flex items-center mt-[40px] w-[full]">
              <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
                EHR Name
              </span>
              <DropdownList
                dropdownName="ehr_name"
                className="w-[698px] h-[56px] rounded-[10px] mr-[50px]"
                list={list}
                onChange={changeFormFields}
                selectedvalue={connectionData.ehr_name}
                // label="EHR Name"
                // labelId="EHR Name"
              />
            </div>

            <div className="flex items-center mt-[40px] w-full">
              <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
                App Type
              </span>
              <DropdownList
                dropdownName="app_type"
                className="w-[698px] h-[56px] rounded-[10px] mr-[50px] "
                list={appTypeList}
                onChange={changeFormFields}
                selectedvalue={connectionData.app_type}
                // label="App Type"
                // labelId="App Type"
              />
            </div>
            {formFieldsArray &&
              formFieldsArray.map((item, index) => (
                <div className="flex items-center mt-[40px] w-full" key={index}>
                  <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
                    {item.label}
                  </span>
                  <TextField
                    className="textinput-field w-[53.5%]"
                    name={item.fieldName}
                    // label={item.label}
                    helperText={item.message}
                    error={item.error}
                    onChange={changeFormFields}
                    value={connectionData[item.fieldName]}
                    variant="outlined"
                    id={item.name}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="mt-[20px]">
          <button
            className="w-[160px] h-[56px] text-[20px] font-lato rounded-[5px] bg-[#ffffff] border-[1px]"
            onClick={() => history.push(CONNECTIONS.DASHBOARD)}
          >
            CANCEL
          </button>
          <button
            className="w-[160px] h-[56px] text-[20px] text-[#ffffff] font-lato rounded-[5px] bg-[#076993] ml-[20px]"
            onClick={() => setConnectionValue(true)}
          >
            UPDATE
          </button>
        </div>

        <CustomCenterModal
          saveButtonTitle={"UPDATE"}
          closeButtonTitle={"CANCEL"}
          openCenterModal={connectionValue}
          onCloseCenterModal={() => setConnectionValue(false)}
          closeButtonHandler={() => setConnectionValue(false)}
          className=""
          saveButtonHandler={() => saveUpdatedData()}
        >
          <p className="text-[20px] font-lato ">
            Are you sure you want to update this Connection?
          </p>
        </CustomCenterModal>
      </section>
    </AppLayout>
  );
}

export default ConnectionUpdate;
