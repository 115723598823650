export const HOME = "/";
export const SIGN_UP = "/sign-up";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/confirm-password/:pk/:token";
export const VERIFY_EMAIL = "/verify-email/:token";
export const DASHBOARD = "/dashboard";
export const OVERVIEW = "/overview";

export const CUSTOMER = {
  DASHBOARD: "/customers/dashboard",
  PROVIDER: "/customers/provider",
  PATIENT: "/customers/patient",
  CREATE: "/customers/create",
  ADD_CONNECTION: "/customers/add-connection",
  UPDATE: "/customers/update",
  ADD_WEBHOOK: "/customers/add-webhook",
};

export const CONNECTIONS = {
  DASHBOARD: "/connections",
  CREATE: "/connections/create",
  LIST: "/connections/list",
  UPDATE: "/connection/update",
};

export const WEBHOOK = {
  DASHBOARD: "/webhook/dashboard",
  CREATE: "/webhook/create",
  UPDATE: "/webhook/update",
};

export const LOG = "/logs";

export const SETTINGS = {
  MANAGE_ORGANIZATION: "/settings/manage-organisation",
  ORGANIZATION_PROFILE: "/settings/organisation-profile",
  MANAGE_USERS: "/settings/manage-user",
  USER_SETTINGS: "/settings/user-settings",
};

export const NOT_LOGGED = "/not-logged";

export const LAYOUT = "/layout";

export const NOT_FOUND = "";
