import React from "react";
import "../../assets/styles/common.scss";

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const MultiSelectDropdown = ({
  className,
  list,
  onChange,
  selectedvalue,
  label,
  dropdownName,
  error,
}) => {
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <Box>
      <FormControl error={error && error}>
        <InputLabel id="multi-select-dropdown">{label ?? ""}</InputLabel>
        <Select
          id="multi-select-dropdown"
          name={dropdownName ?? undefined}
          // sx={{ minWidth: 150, height: 36 }}
          value={selectedvalue || ""}
          label={label ?? ""}
          multiple
          onChange={handleChange}
          className={className ?? "dropdown "}
        >
          {list?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item ?? "N/A"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default MultiSelectDropdown;
