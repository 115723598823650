import axios from "axios";
import { toast } from "react-toastify";
import { getHeaders } from ".";
import { toastId } from "../utils";
import {
  POST_CREATE_USER,
  GET_INVITATION_PENDING_USER_INFO,
  GET_USER_BY_ID,
  PUT_UPDATE_USERS_MFA_DATA,
  DELETE_INVITED_USER,
  PUT_UPDATE_PROFILE_PIC,
  PATCH_UPDATE_USER_DATA_BY_USER_ID,
} from "./endpoints";

export const userService = {
  createUsersData: (data, token) => axios
    .post(POST_CREATE_USER.endpoint, data, {
      headers: getHeaders(token),
    })
    .then((resp) => {
      if (resp.status === POST_CREATE_USER.successStatusCode) {
        return resp.status;
      } if (resp.status === 400) {
        return resp.status;
      }
      alert(`Error in API: ${resp.status}`);
      return null;
    })
    .catch((err) => {
      if (err.response?.data?.error && !toast.isActive(toastId)) {
        toast.error(err.response?.data?.error, { toastId });
      }
    }),

  getInvitationPendingUsersData: (orgId, token) => axios
    .get(GET_INVITATION_PENDING_USER_INFO.endpoint(orgId), {
      headers: getHeaders(token),
    })
    .then((resp) => {
      if (
        resp.status === GET_INVITATION_PENDING_USER_INFO.successStatusCode
      ) {
        return resp.data;
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data?.title ?? "Error in API");
    }),

  fetchUsersDataById: (userId, orgId, token) => axios
    .get(GET_USER_BY_ID.endpoint(userId, orgId), {
      headers: getHeaders(token),
    })
    .then((resp) => {
      if (resp.status === GET_USER_BY_ID.successStatusCode) {
        return resp.data;
      } if (resp.status === 400) {
        return resp.status;
      }
      alert(`Error in API: ${resp.status}`);
      return null;
    })
    .catch((err) => {
      if (err.response?.data?.error && !toast.isActive(toastId)) {
        toast.error(err.response?.data?.error, { toastId });
      }
      if (!toast.isActive(toastId)) {
        toast.error(err?.response?.data?.title ?? "Error in API");
      }
    }),

  fetchUpdateUsersMFAData: (data, token) => axios
    .put(PUT_UPDATE_USERS_MFA_DATA.endpoint, data, {
      headers: getHeaders(token),
    })
    .then((resp) => {
      if (resp.status === PUT_UPDATE_USERS_MFA_DATA.successStatusCode) {
        return resp.data;
      } if (resp.status === 400) {
        return resp.status;
      }
      alert(`Error in API: ${resp.status}`);
      return null;
    })
    .catch((err) => {
      if (err.response?.data?.error && !toast.isActive(toastId)) {
        toast.error(err.response?.data?.error, { toastId });
      }
      if (!toast.isActive(toastId)) {
        toast.error(err?.response?.data?.title ?? "Error in API");
      }
    }),

  fetchDeleteInvitedUser: (data, token) => axios
    .put(DELETE_INVITED_USER.endpoint, data, {
      headers: getHeaders(token),
    }, data)
    .then((resp) => {
      if (resp.status === DELETE_INVITED_USER.successStatusCode) {
        return resp.data;
      } if (resp.status === 400) {
        return resp.status;
      }
      alert(`Error in API: ${resp.status}`);
      return null;
    })
    .catch((err) => {
      if (err.response?.data?.error && !toast.isActive(toastId)) {
        toast.error(err.response?.data?.error, { toastId });
      }
      if (!toast.isActive(toastId)) {
        toast.error(err?.response?.data?.title ?? "Error in API");
      }
    }),

  fetchUpdateUsersProfilePic: (data, token) => axios
    .put(PUT_UPDATE_PROFILE_PIC.endpoint, data, {
      headers: getHeaders(token),
    })
    .then((resp) => {
      if (resp.status === PUT_UPDATE_PROFILE_PIC.successStatusCode) {
        return resp.data;
      } if (resp.status === 400) {
        return resp.status;
      }
      alert(`Error in API: ${resp.status}`);
      return null;
    })
    .catch((err) => {
      if (err.response?.data?.error && !toast.isActive(toastId)) {
        toast.error(err.response?.data?.error, { toastId });
      }
      if (!toast.isActive(toastId)) {
        toast.error(err?.response?.data?.title ?? "Error in API");
      }
    }),

  patchUpdateUsersInformation: (userId, orgId, data, token) => axios
    .patch(PATCH_UPDATE_USER_DATA_BY_USER_ID.endpoint(userId, orgId), data, {
      headers: getHeaders(token),
    })
    .then((resp) => {
      if (resp.status === PATCH_UPDATE_USER_DATA_BY_USER_ID.successStatusCode) {
        return resp.data;
      } if (resp.status === 400) {
        return resp.status;
      }
      alert(`Error in API: ${resp.status}`);
      return null;
    })
    .catch((err) => {
      if (err.response?.data?.error) {
        toast.error(err.response?.data?.error);
      }
      toast.error(err?.response?.data?.title ?? "Error in API");
    }),

};
