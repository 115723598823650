import React, { useState } from "react";
import "../../assets/styles/common.scss";

import { Box, FormControl, MenuItem, Select,InputLabel } from "@mui/material";

const DropdownV2 = ({
  className,
  list,
  onChange,
  selectedvalue,
  placeholder,
  label,
  dropdownName,
}) => {
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <Box>
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select
          id="demo-simple-select-label"
          name={dropdownName ?? undefined}
          sx={{ minWidth: 150, maxWidth: 698, height: 36 }}
          value={selectedvalue || ""}
          placeholder={placeholder}
          renderValue={(value) => (value ? value : placeholder)}
          onChange={handleChange}
          className={className ?? "dropdown "}
          label={label}
        >
          {list?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item.name ?? "N/A"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default DropdownV2;
