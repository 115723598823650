import React from "react";
import AppLayout from "../../components/layout/AppLayout";
import { CUSTOMER } from "../../utils/routeConstants";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InputText from "../../components/core/InputText";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { organizationService } from "../../services/organizationMX";
import { CREATE_NEW_CUSTOMER_VALUES } from "../../services/initialValues";
import { getOrganizationDetails } from "../../utils";
import { deepCopy } from "../../utils";
import { validateEmail } from "../../utils/ValidatationFunctions";
import { validateName } from "./Validations";
import CustomCenterModal from "../../components/core/CustomCenterModal";
function CustomerUpdate() {
  const [updateValues, setUpdateValues] = useState(
    deepCopy(CREATE_NEW_CUSTOMER_VALUES)
  );

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let copiedUpdateValues = { ...updateValues };

    copiedUpdateValues.name.fieldValue = location.state.name;
    copiedUpdateValues.email.fieldValue = location.state.email;

    setUpdateValues(copiedUpdateValues);
  }, []);

  const [showValue, setShowValue] = useState(false);

  const changeFormFields = ({ target: { value, name } }) => {
    let copiedUpdateValues = { ...updateValues };

    if (name === "name") {
      copiedUpdateValues.name.fieldValue = value;
      validateName(value, copiedUpdateValues);
    }

    if (name === "email") {
      copiedUpdateValues.email.fieldValue = value;
      validateEmail("email", value, copiedUpdateValues);
    }

    setUpdateValues(copiedUpdateValues);
  };

  function getUpdateCustomer() {
    if (!updateValues.name.isError && !updateValues.email.isError) {
      setShowValue(true);
    }
  }
  function saveUpdatedData() {
    let orgId = getOrganizationDetails();

    if (!updateValues.name.isError && !updateValues.email.isError) {
      const reqBody = {
        name: updateValues.name.fieldValue,
        email: updateValues.email.fieldValue,
        practice_id: location.state.practice_id,
        enterprise_id: location.state.enterprise_id,
        webhook: location.state.webhook,
        connection: location.state.connection,
      };

      if (reqBody?.connection.length > 0) {
        reqBody.connection.map((connectionItem, index) => {
          reqBody.connection[index] = connectionItem.uuid;
        });
      }

      if (reqBody?.webhook) reqBody.webhook = reqBody.webhook.uuid;

      organizationService
        .updateCustomer(orgId, location.state.uuid, reqBody)
        .then((res) => {
          if (res) {
            history.push(CUSTOMER.DASHBOARD);
          }
        })
        .catch((err) => {
          console.log("err:  ", err);
        });
    }
  }

  return (
    <AppLayout>
      <section className="connection-container bg-[#EDF6F9] p-[40px]">
        <div className="text-[20px]">
          <span
            className="text-[#939393] cursor-pointer"
            onClick={() => history.push(CUSTOMER.DASHBOARD)}
          >
            Customers
          </span>
          <span>
            <ArrowForwardIosIcon fontSize="small" /> Update Customer
          </span>
        </div>

        <div className="bg-[#ffffff] pl-[5%] tabcontainers overflow-auto mt-[2%]">
          <div className="flex items-center mt-[4%] w-[full]">
            <span className="text-[20px]  text-[#3A3A3A] w-[15%]">Name</span>
            <InputText
              name="name"
              error={updateValues.name.isError}
              helperText={
                updateValues.name.isError && updateValues.name.errorMessage
              }
              onChange={changeFormFields}
              value={updateValues.name.fieldValue}
            />
          </div>

          <div className="flex items-center mt-[4%] w-[full]">
            <span className="text-[20px]  text-[#3A3A3A] w-[15%]">Email</span>
            <InputText
              name="email"
              type="text"
              value={updateValues.email.fieldValue}
              onChange={changeFormFields}
              error={updateValues.email.isError}
              helperText={
                updateValues.email.isError && updateValues.email.errorMessage
              }
            />
          </div>
          <div className="mt-[20px] text-[20px]">
            <button
              className="w-[160px] h-[56px]  rounded-[5px] bg-[#ffffff] border-[1px]"
              onClick={() => {
                history.push(CUSTOMER.DASHBOARD);
              }}
            >
              CANCEL
            </button>
            <button
              className="w-[160px] h-[56px] text-[#ffffff] rounded-[5px] bg-[#076993] ml-[20px]"
              onClick={getUpdateCustomer}
            >
              Update{" "}
            </button>
          </div>
        </div>
      </section>

      <CustomCenterModal
        saveButtonTitle={"UPDATE"}
        closeButtonTitle={"CANCEL"}
        openCenterModal={showValue}
        onCloseCenterModal={() => setShowValue(false)}
        closeButtonHandler={() => setShowValue(false)}
        className=""
        saveButtonHandler={() => {
          {
            saveUpdatedData();
          }
        }}
      >
        <p className="text-[20px] font-lato ">
          Are you sure you want to update this Customer?
        </p>
      </CustomCenterModal>
    </AppLayout>
  );
}

export default CustomerUpdate;
