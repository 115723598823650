import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CustomButton from "../../components/core/CustomButton";
import { MFA_DATA } from "../../services/initialValues";
import { userService } from "../../services/userMX";
import { toast } from "react-toastify";
import LoadingButton from '@mui/lab/LoadingButton';
import { getTokenDetails } from "../../utils";

const TwoFactorAuth = () => {
  const [token, setToken] = useState(getTokenDetails())
  const [otpSection, setOtpSection] = useState(false);
  const [currentUsersMfaData, setCurrentUsersMfaData] = useState(MFA_DATA);
  const [modalLoading, setModalLoading] = useState(false);

  const mfaOnChangeHandler = ({ target: { value, name } }) => {
    let copiedUsersMfaValues = { ...currentUsersMfaData };
    copiedUsersMfaValues[name] = value;
    setCurrentUsersMfaData(copiedUsersMfaValues);
  };

  // update MFA data of current user
  const fetchUpdateUsersMFAData = () => {
    let body = {
      method: currentUsersMfaData.method,
      countryCode: currentUsersMfaData.countryCode,
      phoneNumber: currentUsersMfaData.phoneNumber
    }

    setModalLoading(true)
    userService
    .fetchUpdateUsersMFAData(body, token)
    .then((resp) => {
      if(resp?.value){
        setCurrentUsersMfaData(resp.value);
        setOtpSection(true)
        toast.success(resp.value)
      }
    })
    .catch((err) => console.log(err))
    .finally(() => setModalLoading(false));
  };

  const resetAll = () => {
    let copiedUsersMfaValues = { ...currentUsersMfaData };
    currentUsersMfaData.method = "";
    currentUsersMfaData.countryCode = "";
    currentUsersMfaData.phoneNumber = "";
    setCurrentUsersMfaData(copiedUsersMfaValues); 
  }

  return (
    <div className="mt-[20px] shadow-[0px_3px_6px_#00000029] bg-[#ffffff] p-[20px]">
      <h1 className="text-[20px] font-lato text-[#3a3a3a] xl:text-[16px]">
        Two Factor Authentication
      </h1>
      <div className="flex">
        <div className="flex flex-col mt-[20px] w-1/6">
          <span className="text-[20px] font-lato text-[#3a3a3a] xl:text-[16px]">
            Preferred Method
          </span>
          <span className="text-[20px] font-lato text-[#3a3a3a] xl:text-[16px] xl:pt-[16px] mt-[38px]">
            Country Code
          </span>
          <span className="text-[20px] font-lato text-[#3a3a3a] xl:text-[16px] mt-[52px]">
            Phone
          </span>
        </div>
        <div className="flex flex-col mt-[20px]">
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                name="method"
                value="Text"
                control={<Radio />}
                label="Text"
                className="text-[20px] font-lato text-[#3a3a3a] xl:text-[16px]"
                onChange={mfaOnChangeHandler}
              />
              <FormControlLabel
                name="method"
                value="Phone Call"
                control={<Radio />}
                label="Phone Call"
                className="text-[20px] font-lato text-[#3a3a3a] xl:text-[16px]"
                onChange={mfaOnChangeHandler}
              />
            </RadioGroup>
          </FormControl>

          <TextField
            name="countryCode"
            // label="Enter country code"
            placeholder="Enter country code"
            className="textinput-field w-[698px] mt-[32px]"
            onChange={mfaOnChangeHandler}
          />

          <TextField
            name="phoneNumber"
            placeholder="Enter Phone no"
            className="textinput-field w-[698px] mt-[32px]"
            onChange={mfaOnChangeHandler}
          />

          <div className=" mt-[40px]">
            {!modalLoading ?
              <CustomButton
                iconName="reply-right"
                text="SEND ACTIVATION"
                className="w-[250px] h-[56px] bg-[#076993] text-[20px] filled-custom-btn  text-[#ffffff] rounded-[5px] float-right  xl:h-[38px] xl:w-[190px]"
                onClick={fetchUpdateUsersMFAData}
              />: 
              <LoadingButton
                loading={modalLoading}
                loadingPosition="center"
                variant="outlined"
                className="w-[231px] rounded-[5px] text-[20px] font-lato h-[56px] text-[#fff] float-right"
              />}
          </div>
        </div>
      </div>
      {otpSection && (
        <>
          <div className="flex mt-[20px]">
            <span className="w-1/6 pt-[15px] text-[20px] font-lato text-[#3a3a3a] xl:text-[16px] lg:text-[12px]">
              Enter OTP
            </span>
            <TextField
              className="textinput-field w-[698px]"
              // label="OTP"
              placeholder="Type here"
            />
          </div>
          <div className="flex justify-end mt-[20px] w-[67%]  xl:w-[90%]">
            <CustomButton
              iconName="reply-right"
              text="RESEND OTP"
              className="empty-custom-btn text-[20px] text-[#3A3A3A] font-lato w-[185px] h-[56px] mr-[20px] xl:w-[130px] xl:h-[38px]"
              // onClick={() => history.push("/")}
            />
            <CustomButton
              iconName="reply-right"
              text="VERIFY"
              className="filled-custom-btn text-[20px] font-lato w-[165px] h-[56px] xl:w-[130px] xl:h-[38px]"
              onClick={() => toast.success('Work in progress!!!')}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TwoFactorAuth;
