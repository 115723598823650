import React, { useEffect, useState } from "react";
import "../../assets/styles/sidemenu.scss";
import LogoIcon from "../../assets/images/whiteLogo.svg";
import OverviewIcon from "../../assets/images/overview_icon.svg";
import ConnectionIcon from "../../assets/images/connection_icon.svg";
import APIKeyIcon from "../../assets/images/api_key_icon.svg";
import LogsIcon from "../../assets/images/logs_icon.svg";
import SettingsIcon from "../../assets/images/settings_icon.svg";
import SignoutIcon from "../../assets/images/signout_icon.svg";
import CustomerIcon from "../../assets/images/people_white.svg";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";
import CustomCenterModal from "../core/CustomCenterModal";
import { getStoredData, logOutHandler } from "../../utils";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  CONNECTIONS,
  CUSTOMER,
  WEBHOOK,
  LOG,
  SETTINGS,
  OVERVIEW,
  DASHBOARD,
} from "../../utils/routeConstants";

const SideBar = () => {
  let history = useHistory();
  const { pathname } = useLocation();

  const [orgDetail, setOrgDetail] = useState(0);
  const [showSignoutModal, setShowSignoutModal] = useState(false);

  useEffect(() => {
    if (getStoredData.getOrgItem()) {
      let org = getStoredData.getOrgItem();
      setOrgDetail(JSON.parse(org));
    }
  }, []);

  return (
    <>
      <div id="sidemenu-container">
        <div className="menu-item-block h-full">
          <NavLink to={DASHBOARD}>
            <img
              src={LogoIcon}
              className="dashboard-logo  w-[210px] h-[89px] ml-[30px] xl:w-[180px]  lg:w-[120px]"
            />
          </NavLink>
          <div className="nav-section">
            <NavLink
              to={OVERVIEW}
              className={(isActive) =>
                "flex cursor-pointer " +
                (isActive ? "selected-menu-item" : " menu-item")
              }
            >
              <img src={OverviewIcon} className="mr-5" />
              <p className="menu-text">Overview</p>
            </NavLink>
            <NavLink
              to={CONNECTIONS.DASHBOARD}
              className={(isActive) =>
                "flex cursor-pointer " +
                (isActive ? "selected-menu-item" : " menu-item")
              }
            >
              <img src={ConnectionIcon} className="mr-5" />
              <p className="menu-text">
                Connections
                <span className="menu-count ml-[10px]">
                  {getStoredData.getConnectionCount() || 0}
                </span>
              </p>
            </NavLink>

            {/* <NavLink
              to="/customers/dashboard"
              className={(isActive) =>
                "flex cursor-pointer " +
                (isActive ? "selected-menu-item" : " menu-item")
              }
            >
              <img src={CustomerIcon} className="mr-6" />
              <p className="menu-text">Customers</p>
            </NavLink> */}

            <NavLink
              to={CUSTOMER.DASHBOARD}
              className={() =>
                [
                  CUSTOMER.DASHBOARD,
                  CUSTOMER.PROVIDER,
                  CUSTOMER.PATIENT,
                ].includes(pathname)
                  ? "flex cursor-pointer selected-menu-item"
                  : "flex cursor-pointer menu-item"
              }
            >
              <img src={CustomerIcon} className="mr-6" />
              <p className="menu-text">
                Customers
                <span className="menu-count  ml-[31px]">
                  {getStoredData.getCustomerCount() || 0}
                </span>
              </p>
            </NavLink>

            <NavLink
              to={WEBHOOK.DASHBOARD}
              className={(isActive) =>
                "flex cursor-pointer " +
                (isActive ? "selected-menu-item" : " menu-item")
              }
            >
              <img src={CustomerIcon} className="mr-6" />
              <p className="menu-text">
                Webhook
                <span className="menu-count ml-[42px] ">
                  {getStoredData.getWebhookCount() || 0}
                </span>
              </p>
            </NavLink>

            <NavLink
              to={LOG}
              className={(isActive) =>
                "flex cursor-pointer " +
                (isActive ? "selected-menu-item" : " menu-item")
              }
            >
              <img src={LogsIcon} className="mr-5" />
              <p className="menu-text">Logs</p>
            </NavLink>
            <NavLink
              to={SETTINGS.MANAGE_ORGANIZATION}
              className={() =>
                [
                  SETTINGS.MANAGE_ORGANIZATION,
                  SETTINGS.ORGANIZATION_PROFILE,
                  SETTINGS.MANAGE_USERS,
                  SETTINGS.USER_SETTINGS,
                ].includes(pathname)
                  ? "flex cursor-pointer selected-menu-item"
                  : "flex cursor-pointer menu-item"
              }
            >
              <img src={SettingsIcon} className="mr-5" />
              <p className="menu-text">Settings</p>
            </NavLink>

            <div className="flex cursor-pointer menu-item">
              <img
                src={SignoutIcon}
                className="mr-5 cursor-pointer"
                onClick={() => setShowSignoutModal(true)}
              />
              <p
                className="menu-text cursor-pointer text-[20px]"
                onClick={() => setShowSignoutModal(true)}
              >
                Sign Out
              </p>
            </div>
          </div>
          <div className="flex sidemenu-footer">
            <div className="flex-col">
              <p> Organization</p>
              <Tooltip arrow title={orgDetail?.name || "N/A"}>
                <p>{orgDetail?.name?.slice(0, 13) ?? "N/A"}</p>
              </Tooltip>
            </div>
            <div className="flex-col ml-[3%]">
              <div>Env Type</div>
              <p
                className={
                  orgDetail?.org_type == "sandbox"
                    ? "text-green-900"
                    : orgDetail?.org_type == "stage"
                    ? "text-yellow-900"
                    : "text-red-900"
                }
              >
                {orgDetail?.org_type ?? "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <CustomCenterModal
        saveButtonTitle={"YES"}
        closeButtonTitle={"NO"}
        openCenterModal={showSignoutModal}
        onCloseCenterModal={() => setShowSignoutModal(false)}
        closeButtonHandler={() => setShowSignoutModal(false)}
        className=""
        saveButtonHandler={() => {
          logOutHandler();
          history.push("/");
        }}
      >
        <p className="text-[20px] font-lato font-bold">
          Are you sure you want to logout?
        </p>
      </CustomCenterModal>
    </>
  );
};

export default SideBar;
