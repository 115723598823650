import {
  noWhiteSpaceRegex,
  onlyAlphaNumericUnderscoreRegex,
  ipRegex,
  webSiteRegex,
} from "../../utils";

const EMPTY_NAME_ERROR_MESSAGE = " Name Should Not be Empty";
const NAME_LENGTH_ERROR_MESSAGE = "Name Should Not be More Than 60 Characters";
const WHITESPACE_NAME_ERROR_MESSAGE = "Name Should Not Contain Space";

const ALPHANUMERIC_NAME_ERROR_MESSAGE =
  "Name Should Only contain Alphabets, Digits and Underscores";

const EMPTY_WEBHOOKURL_ERROR_MESSAGE = "URL Should Not be Empty";
const INVALID_URL_ERROR_MESSAGE = "Invalid URL";
const LENGTH_URL_ERROR_MESSAGE = "URL Length Should Not be More Than 1000";
const SPACE_URL_ERROR_MESSAGE = "URL Should Not Contain Any Spaces";

export const validateName = (value, validationState) => {
  if (value === "") {
    validationState.webHookName.isError = true;
    validationState.webHookName.errorMessage = EMPTY_NAME_ERROR_MESSAGE;
  } else {
    if (value.length > 60) {
      validationState.webHookName.isError = true;
      validationState.webHookName.errorMessage = NAME_LENGTH_ERROR_MESSAGE;
    } else if (noWhiteSpaceRegex.test(value)) {
      validationState.webHookName.isError = true;
      validationState.webHookName.errorMessage = WHITESPACE_NAME_ERROR_MESSAGE;
    } else if (!onlyAlphaNumericUnderscoreRegex.test(value)) {
      validationState.webHookName.isError = true;
      validationState.webHookName.errorMessage =
        ALPHANUMERIC_NAME_ERROR_MESSAGE;
    } else {
      validationState.webHookName.isError = false;
    }
  }
};

export const validateURL = (value, validationState) => {
  if (value === "") {
    validationState.webHookURL.isError = true;
    validationState.webHookURL.errorMessage = EMPTY_WEBHOOKURL_ERROR_MESSAGE;
  } else {
    if (!webSiteRegex.test(value) && !ipRegex.test(value)) {
      validationState.webHookURL.isError = true;
      validationState.webHookURL.errorMessage = INVALID_URL_ERROR_MESSAGE;
    } else if (noWhiteSpaceRegex.test(value)) {
      validationState.webHookURL.isError = true;
      validationState.webHookURL.errorMessage = SPACE_URL_ERROR_MESSAGE;
    } else if (value.length > 1000) {
      validationState.webHookURL.isError = true;
      validationState.webHookURL.errorMessage = LENGTH_URL_ERROR_MESSAGE;
    } else {
      validationState.webHookURL.isError = false;
    }
  }
};
