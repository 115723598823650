import axios from "axios";
import { toast } from "react-toastify";
import { toastId } from "../utils";
import {
  GET_ORGANIZATIONS,
  POST_CREATE_ORGANIZATIONS,
  GET_ORGANIZATIONS_BY_ID,
  DELETE_ORGANIZATION_BY_ID,
  PATCH_ORGANIZATIONS_BY_ID,
  GET_USER_INFO,
  GET_CONNECTIONS_BY_ORGANIZATION_ID,
  POST_ORGANIZATION,
  GET_CUSTOMER_BY_ORGANIZATION_ID,
  POST_CUSTOMER_BY_ORGANIZATION_ID,
  POST_CONNECTIONS_BY_CUSTOMER_ID,
  GET_ORGANIZATIONS_PROFILE_BY_ID,
  GET_PROVIDERS_BY_CUSTOMERS_ID,
  GET_PATIENTS_BY_CUSTOMERS_ID,
  DELETE_CONNECTION_BY_ID,
  DELETE_CUSTOMER_BY_ID,
  PATCH_CONNECTION_BY_ID,
  PATCH_CUSTOMER_BY_ID,
  SEARCH_WEBHOOK_BY_ORGANISATION_ID,
  SEARCH_CUSTOMER_BY_ORGANISATION_ID,
  SEARCH_CONNECTION_BY_ORGANISATION_ID,
  SEARCH_ORGANIZATION,
} from "./endpoints";
import { getTokenDetails } from "../utils";

export const organizationService = {
  // Get organizations
  // fetchOrganizations: (page, limit) =>
  //   axios
  //     .get(GET_ORGANIZATIONS.endpoint(page, limit))
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  fetchOrganizations: async (page, limit) => {
    let response;
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(GET_ORGANIZATIONS.endpoint(page, limit), options);
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Post organzation
  // createOrganizations: (data) =>
  //   axios
  //     .post(POST_CREATE_ORGANIZATIONS.endpoint, data)
  //     .then((resp) => {
  //       if (resp) {
  //         toast.success("Organization created successfully!", {
  //           toastId,
  //         });
  //         return resp;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),
  createOrganizations: async (data) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(POST_CREATE_ORGANIZATIONS.endpoint, options);
      if (response) {
        toast.success("Organization created successfully!", {
          toastId,
        });
        response = await response.json();
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg[0]][0]);
    }
    return response;
  },

  // Get organization by Id
  // getOrganizationsByID: (organizationId) =>
  //   axios
  //     .get(GET_ORGANIZATIONS_BY_ID.endpoint(organizationId))
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),
  getOrganizationsByID: async (organizationId) => {
    let response;

    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
    };

    try {
      response = await fetch(
        GET_ORGANIZATIONS_BY_ID.endpoint(organizationId),
        options
      );
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Update organization info by Id
  // patchUpdateOrganizationInfoById: (organizationId, data, token) =>
  //   axios
  //     .patch(PATCH_ORGANIZATIONS_BY_ID.endpoint(organizationId), data)
  //     .then((resp) => {
  //       if (resp) {
  //         toast.success("Succesfully updated!", { toastId });
  //         return resp.data;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),

  patchUpdateOrganizationInfoById: async (organizationId, data) => {
    let response;

    let options = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(
        PATCH_ORGANIZATIONS_BY_ID.endpoint(organizationId),
        options
      );
      if (response) {
        toast.success("Succesfully updated!", { toastId });
        response = await response.json();
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Get all users data from current organization
  getUsersData: (page, token) =>
    axios
      .get(GET_USER_INFO.endpoint(page))
      .then((resp) => {
        GET_PROVIDER_BY_ORGANIZATION_ID;
        if (resp?.data) return resp.data;
      })
      .catch((err) => {
        console.log("err: ", err);
      }),

  // Get connections by organization Id
  // fetchConnections: (page, orgId, limit) =>
  //   axios
  //     .get(GET_CONNECTIONS_BY_ORGANIZATION_ID.endpoint(page, orgId, limit))
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),
  fetchConnections: async (page, orgId, limit) => {
    let response;

    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
    };

    try {
      response = await fetch(
        GET_CONNECTIONS_BY_ORGANIZATION_ID.endpoint(page, orgId, limit),
        options
      );
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Get customer by organization Id
  // fetchCustomers: (orgId, page, limit) =>
  //   axios
  //     .get(GET_CUSTOMER_BY_ORGANIZATION_ID.endpoint(orgId, page, limit))
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),

  fetchCustomers: async (orgId, page, limit) => {
    let response;

    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
    };

    try {
      response = await fetch(
        GET_CUSTOMER_BY_ORGANIZATION_ID.endpoint(orgId, page, limit),
        options
      );
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Post customer
  // createCustomers: (orgId, body) =>
  //   axios
  //     .post(POST_CUSTOMER_BY_ORGANIZATION_ID.endpoint(orgId), body)
  //     .then((resp) => {
  //       if (resp) {
  //         toast.success("Customer created successfully!", { toastId });
  //         return resp;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),

  searchOrganizationDashboard: async (searchvalue) => {
    let response;

    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
    };

    try {
      response = await fetch(
        SEARCH_ORGANIZATION.endpoint(searchvalue),
        options
      );

      if (response.ok) {
        response = await response.json();

        if (searchvalue.length != 0) {
          if (response.count == 0) {
            toast.success("Organization Not Found!", { toastId });
          } else {
            toast.success("Organization Found!", { toastId });
          }
        }
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  searchOrganization: async (searchvalue) => {
    let response;

    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
    };

    try {
      response = await fetch(
        SEARCH_ORGANIZATION.endpoint(searchvalue),
        options
      );

      if (response.ok) {
        response = await response.json();

        if (searchvalue.length != 0) {
          if (response.count == 0) {
            toast.success("Organization Not Found!", { toastId });
          } else {
            toast.success("Organization Found!", { toastId });
          }
        }
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },
  searchConnection: async (orgId, searchvalue) => {
    let response;

    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
    };

    try {
      response = await fetch(
        SEARCH_CONNECTION_BY_ORGANISATION_ID.endpoint(orgId, searchvalue),
        options
      );

      if (response.ok) {
        response = await response.json();
        if (searchvalue.length != 0) {
          if (response.count == 0) {
            toast.success("Connection Not Found!", { toastId });
          } else {
            toast.success("Connection Found!", { toastId });
          }
        }
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  searchCustomer: async (orgId, searchvalue, pageName) => {
    let response;

    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
    };

    try {
      response = await fetch(
        SEARCH_CUSTOMER_BY_ORGANISATION_ID.endpoint(orgId, searchvalue),
        options
      );

      if (response.ok) {
        response = await response.json();

        if (searchvalue.length != 0) {
          if (response.count == 0) {
            toast.success("Customer Not Found!", { toastId });
          } else {
            toast.success("Customer Found!", { toastId });
          }
        }
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  searchWebHook: async (orgId, searchvalue) => {
    let response;

    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
    };

    try {
      response = await fetch(
        SEARCH_WEBHOOK_BY_ORGANISATION_ID.endpoint(orgId, searchvalue),
        options
      );

      if (response.ok) {
        response = await response.json();

        if (searchvalue.length != 0) {
          if (response.count == 0) {
            toast.success("Webhook Not Found!", { toastId });
          } else {
            toast.success("Webhook Found!", { toastId });
          }
        }
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },
  createCustomers: async (orgId, body) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      response = await fetch(
        POST_CUSTOMER_BY_ORGANIZATION_ID.endpoint(orgId),
        options
      );

      if (response.ok) {
        toast.success("Customer created successfully!", { toastId });
        response = await response.json();
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Patch connection into customer
  // patchConnectionstoCustomer: (customerId, orgId, body) =>
  //   axios
  //     .patch(POST_CONNECTIONS_BY_CUSTOMER_ID.endpoint(customerId, orgId), body)
  //     .then((resp) => {
  //       // console.log("body:  ",body);
  //       if (resp) {
  //         // toast.success("Connections added successfully!", {
  //         //   toastId,
  //         // });
  //         return resp;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),

  patchConnectionstoCustomer: async (customerId, orgId, body) => {
    let response;

    let options = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      response = await fetch(
        POST_CONNECTIONS_BY_CUSTOMER_ID.endpoint(customerId, orgId),
        options
      );

      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }

    return response;
  },

  // Get Organization by Id
  // getOrganizationProfileByID: (organizationId, token) =>
  //   axios
  //     .get(GET_ORGANIZATIONS_PROFILE_BY_ID.endpoint(organizationId))
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),
  //Not using this call
  getOrganizationProfileByID: async (organizationId, token) => {
    let response;
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(
        GET_ORGANIZATIONS_PROFILE_BY_ID.endpoint(organizationId),
        options
      );
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }

    return response;
  },

  // Delete organization by Id
  // deleteOrganizationById: (organizationId) =>
  //   axios
  //     .delete(DELETE_ORGANIZATION_BY_ID.endpoint(organizationId))
  //     .then((resp) => {
  //       if (resp) {
  //         toast.success("Organization deleted successfully", {
  //           toastId,
  //         });
  //         return resp.data;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),

  deleteOrganizationById: async (organizationId) => {
    let response;

    let options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(
        DELETE_ORGANIZATION_BY_ID.endpoint(organizationId),
        options
      );
      if (response.ok) {
        toast.success("Organization Deleted Successfully");
      }
      // response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Post connection
  // addConnection: (body, orgId) =>
  //   axios
  //     .post(POST_ORGANIZATION.endpoint(orgId), body)
  //     .then((resp) => {
  //       if (resp) {
  //         toast.success("Connection created successfully", {
  //           toastId,
  //         });
  //         return resp;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),
  addConnection: async (body, orgId) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      response = await fetch(POST_ORGANIZATION.endpoint(orgId), options);
      if (response) {
        toast.success("Connection created successfully", { toastId });
      }
      response = await response.json();
    } catch (error) {
      console.log(error);
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Get providers
  // fetchProviders: (orgId, customerId, limit, page) =>
  //   axios
  //     .get(
  //       GET_PROVIDERS_BY_CUSTOMERS_ID.endpoint(orgId, customerId, limit, page)
  //     )
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),
  fetchProviders: async (orgId, customerId, limit, page) => {
    let response;
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(
        GET_PROVIDERS_BY_CUSTOMERS_ID.endpoint(orgId, customerId, limit, page),
        options
      );
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Get patients
  // fetchPatients: (orgId, customerId, limit, page) =>
  //   axios
  //     .get(
  //       GET_PATIENTS_BY_CUSTOMERS_ID.endpoint(orgId, customerId, limit, page)
  //     )
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  fetchPatients: async (orgId, customerId, limit, page) => {
    let response;
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(
        GET_PATIENTS_BY_CUSTOMERS_ID.endpoint(orgId, customerId, limit, page),
        options
      );
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Delete Connection
  // deleteConnection: (orgId, connectionId) =>
  //   axios
  //     .delete(DELETE_CONNECTION_BY_ID.endpoint(orgId, connectionId))
  //     .then((resp) => {
  //       if (resp.status === 204) {
  //         toast.success("Connection deleted successfully", {
  //           toastId,
  //         });
  //         return resp;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),
  deleteConnection: async (orgId, connectionId) => {
    let response;

    let options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(
        DELETE_CONNECTION_BY_ID.endpoint(orgId, connectionId),
        options
      );
      if (response.ok) {
        toast.success("Connection Deleted Successfully", {
          toastId,
        });
      }
      // response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Delete Customer
  // deleteCustomer: (orgId, customerId) =>
  //   axios
  //     .delete(DELETE_CUSTOMER_BY_ID.endpoint(orgId, customerId))
  //     .then((resp) => {
  //       if (resp.status === 204) {
  //         toast.success("Customer deleted successfully", {
  //           toastId,
  //         });
  //         return resp;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),

  deleteCustomer: async (orgId, customerId) => {
    let response;

    let options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(
        DELETE_CUSTOMER_BY_ID.endpoint(orgId, customerId),
        options
      );
      if (response.ok) {
        toast.success("Customer Deleted Successfully", {
          toastId,
        });
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Update Connection
  // updateConnection: (orgId, connectionId, body) =>
  //   axios
  //     .patch(PATCH_CONNECTION_BY_ID.endpoint(orgId, connectionId), body)
  //     .then((resp) => {
  //       if (resp) {
  //         toast.success("Connection updated successfully", {
  //           toastId,
  //         });
  //         return resp.data;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err:", err);
  //     }),
  updateConnection: async (orgId, connectionId, body) => {
    let response;

    let options = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      response = await fetch(
        PATCH_CONNECTION_BY_ID.endpoint(orgId, connectionId),
        options
      );

      if (response) {
        toast.success("Connection updated successfully", { toastId });
      }
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }

    return response;
  },

  // Update Customer
  // updateCustomer: (orgId, customerId, body) =>
  //   axios
  //     .patch(PATCH_CUSTOMER_BY_ID.endpoint(orgId, customerId), body)
  //     .then((resp) => {
  //       if (resp) {
  //         toast.success("Customer updated successfully", {
  //           toastId,
  //         });
  //         return resp.data;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err:", err);
  //     }),

  updateCustomer: async (orgId, customerId, body) => {
    let response;

    let options = {
      method: "PATCH",
      headers: {
        Accept: "*/*",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      response = await fetch(
        PATCH_CUSTOMER_BY_ID.endpoint(orgId, customerId),
        options
      );
      if (response.ok) {
        toast.success("Customer Updated Successfully", {
          toastId,
        });
      }
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },
};
