import React, {useEffect, useState} from 'react';

const ToggleCheckbox = ({
  isEnabled,
  onChangeHandler,
}) => {
  const toBool = b => b !== undefined ? (
    typeof b === 'string' ? b.toLowerCase() === 'true' : b
  ) : false;

  const [isTrue, setIsTrue] = useState(toBool(isEnabled));

  useEffect(() => {
    setIsTrue(toBool(isEnabled));
  }, [isEnabled]);

  const checkboxChangeHandler = e => {
    setIsTrue(!isTrue);
    if (onChangeHandler) {
      onChangeHandler(!isTrue);
    }
  };

  return (
    <div className="re-custom-toggle re-custom-toggle-green re-custom-toggle-lg">
      <label className="re-custom-toggle-label">
        <input
          type="checkbox"
          checked={isTrue}
          onChange={checkboxChangeHandler}
        />
        <span/>
      </label>
    </div>
  )
};

export default ToggleCheckbox;
