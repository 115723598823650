import React from "react";

const DataNotAvailable = ({
  data,
  className
}) => {
  return(
    <div className={"data-not-available-container " + `${className}`}>
      <p className="text-[20px]">Oops! {data ?? ''} are not available.</p>
    </div>
  )
}

export default DataNotAvailable;
