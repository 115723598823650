import React from "react";
import "../../assets/styles/common.scss";
import "../../assets/styles/components.scss";
import LogoIcon from "../../assets/images/blueLogo.svg";
import BackgroundImage from "../../assets/images/authBackgroundImg.jpg";
import Footer from "../../components/core/Footer";
import { getInnerHeight, getInnerHeightForImage } from "../../utils";

const UserAuthHome = ({ children }) => {
  return (
    <>
      <div className="RE-container">
        <div className="RE-user-auth-container flex flex-row justify-between">
          <div className="auth-section basis-1/2">
            <img
              src={LogoIcon}
              className="w-[60%] h-[20%] xl:w-[280.5px] xl:h-[96px] lg:h-[80px] lg:w-[250px]"
            />
            {children}
          </div>
          <div className="bgImg">
            <img
              src={BackgroundImage}
              style={{ height: getInnerHeightForImage() }}
            ></img>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default UserAuthHome;
