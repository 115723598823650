import React, { useState } from "react";
import "../../assets/styles/common.scss";

import { useHistory } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";

const APIKeyTabs = ({
  tabs,
  pages,
  activeTab
}) => {
  const history = useHistory();
  const [currentActiveTab, setCurrentActiveTab] = useState(activeTab ?? 0);
  return (
    <Box>
      <TabContext value={currentActiveTab}>
        <Box sx={{ }}>
          <TabList
            aria-label="lab API tabs example"
            onChange={(event, value) => {
              setCurrentActiveTab(value)
              history.push(tabs[value].route)
            }}
            className="tabs-nav-header"
          >
            {tabs.map((_tab, _idx) => (<Tab key={_idx} label={_tab.name} value={_idx}></Tab>))}
          </TabList>
        </Box>
        <TabPanel className="p-0" value={currentActiveTab}>{pages[currentActiveTab]}</TabPanel>
      </TabContext>
    </Box>
  );
};
export default APIKeyTabs;
