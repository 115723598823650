import React, { useEffect, useState } from "react";
import "../../assets/styles/dashboard.scss";
import "../../assets/styles/components.scss";

import arrow from "../../assets/images/bullet_arrow.svg";
import copy from "../../assets/images/copy.svg";
import organizationIcon from "../../assets/images/orgIcon.png";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { CUSTOMER, WEBHOOK } from "../../utils/routeConstants";
import { getStoredData } from "../../utils";

const OrganizationItem = ({
  onClick,
  className,
  customer,
  deleteConnection,
  deleteCusotmer,
  updateConnectionRoute,
  connection,
  updateCustomerRoute,
  webHook,
  deleteWebHook,
  updateWebHook,
  addWebHook,
  organization,
}) => {
  const history = useHistory();
  const organizationDetails = JSON.parse(getStoredData.getOrgItem());
  return (
    <div className="flex org-item-block  pt-[20px]">
      {/* Organization Card */}
      {organization && (
        <figure className="org-fig cursor-pointer" onClick={onClick}>
          <img
            src={
              organization?.logo?.additionalProp1.slice(0, 4) === "data"
                ? organization?.logo?.additionalProp1
                : organizationIcon
            }
            alt="org_icon"
            className={
              "shadow-[0px_0px_6px_#00000029] org-icon " + `${className ?? ""}`
            }
          ></img>
        </figure>
      )}
      {!customer && !connection && !webHook && (
        <div className="org-details cursor-pointer " onClick={onClick}>
          {organization?.name.length > 25 ? (
            <Tooltip title={organization?.name} placement="top">
              <h1 className="title text-[#3A3A3A] font-lato ">
                {organization?.name.slice(0, 25)} {"..."}
              </h1>
            </Tooltip>
          ) : (
            <h1 className="title text-[#3A3A3A] font-lato ">
              {organization?.name || "N/A"}
            </h1>
          )}

          {organization?.alertemail.length > 25 ? (
            <Tooltip title={organization?.alertemail} placement="top">
              <span className="description1 text-[#3A3A3A] font-lato ">
                {organization?.alertemail.slice(0, 25)} {"..."}
              </span>
            </Tooltip>
          ) : (
            <span className="description1 text-[#3A3A3A] font-lato ">
              {organization?.alertemail || "N/A"}
            </span>
          )}
        </div>
      )}

      {/* Connection Card */}
      {connection && (
        <div className="org-details font-lato px-[5px] pb-[5px]">
          <div className="flex justify-end">
            <span
              onClick={() => {
                history.push({
                  pathname: updateConnectionRoute,
                  state: connection,
                });
              }}
              className="text-green-900 cursor-pointer title"
            >
              Update
            </span>
            <span
              onClick={() => deleteConnection(connection?.uuid)}
              className="text-red-600 cursor-pointer title pl-[5%]"
            >
              Delete
            </span>
          </div>
          <h1 className="title ">
            <span>Name: </span>
            {connection?.connection_name.length > 35 ? (
              <Tooltip title={connection?.connection_name} placement="top">
                <span>
                  {connection?.connection_name.slice(0, 35)} {"..."}
                </span>
              </Tooltip>
            ) : (
              connection?.connection_name
            )}
          </h1>
          <div className="pb-[7px] pt-[5px]">
            <span className="heading ">ID: </span>{" "}
            <span className="description1">{connection?.uuid || "N/A"}</span>
          </div>
          <div className="pb-[7px] ">
            <span className="heading ">EHR Name: </span>
            <span className="description1">
              {" "}
              {connection?.ehr_name || "N/A"}{" "}
            </span>
          </div>
          <div className="pb-[7px]">
            <span className="heading ">App Type: </span>
            <span className="description1">
              {connection?.app_type || "N/A"}{" "}
            </span>
          </div>
        </div>
      )}

      {/* Customer Card */}
      {customer && (
        <div className="org-details font-lato pr-[10px]">
          <div className="flex justify-end">
            <span
              onClick={() => {
                history.push({
                  pathname: updateCustomerRoute,
                  state: customer,
                });
              }}
              className="text-green-900 cursor-pointer title"
            >
              Update
            </span>
            <span
              onClick={() => deleteCusotmer(customer?.uuid)}
              className="text-red-600 cursor-pointer title pl-[5%]"
            >
              Delete
            </span>
          </div>

          <div className="title flex">
            {" "}
            <span>Name:&nbsp;</span>
            {customer?.name.length > 35 ? (
              <Tooltip title={customer?.name} placement="top">
                <span className="ml-[10px] customerName">
                  {customer?.name.slice(0, 35)} {"..."}
                </span>
              </Tooltip>
            ) : (
              <span className="ml-[10px] customerName">{customer?.name}</span>
            )}
          </div>

          <div className="flex pb-[5px] ">
            <span className="heading ">ID: </span>
            <span className="description2 ml-[10px]">
              {customer?.uuid || "N/A"}
            </span>
            <span
              className="pl-[5px]"
              onClick={() => navigator.clipboard.writeText(`${customer?.uuid}`)}
            >
              {" "}
              <Tooltip arrow title="COPY" placement="right">
                <img
                  src={copy}
                  width="60%"
                  className="pt-[3px] cursor-pointer"
                />
              </Tooltip>
            </span>
          </div>

          <div className="pt-[5px] flex">
            <span
              onClick={() => {
                history.push({
                  pathname: CUSTOMER.PROVIDER,
                  state: customer,
                });
              }}
              className="text-blue-400 cursor-pointer title"
            >
              Providers
            </span>
            <span
              onClick={() => {
                history.push({
                  pathname: CUSTOMER.PATIENT,
                  state: customer,
                });
              }}
              className="text-blue-400 cursor-pointer title pl-[5%]"
            >
              Patients
            </span>
          </div>

          <div className="mt-[10px]">
            <div
              className="flex heading cursor-pointer"
              onClick={() => {
                history.push({
                  pathname: addWebHook,
                  state: customer,
                });
              }}
            >
              Webhooks:{" "}
              <span className="ml-[10px] description2">
                {customer?.webhook?.uuid}
              </span>
              <span className="pl-[1%]">
                {" "}
                <img src={arrow} alt="add connections" width="60%"></img>{" "}
              </span>
            </div>
          </div>

          <div className="mt-[15px]">
            <div className="flex heading cursor-pointer" onClick={onClick}>
              Connections:{" "}
              <span>&nbsp; {customer?.connection[0]?.ehr_name}</span>
              <span className="pl-[1%]">
                {" "}
                <img src={arrow} alt="add connections" width="60%"></img>{" "}
              </span>
            </div>
            {customer?.connection?.map((connection, index) => (
              <div key={index} className="mt-[1%]">
                <div className="flex heading">
                  <span className="description1 font-semibold">
                    {connection?.app_type}:{" "}
                  </span>
                  <span className="ml-[10px] description2">
                    {connection?.uuid}
                  </span>

                  <span
                    className="pl-[3px] cursor-pointer"
                    data-tooltip-target="tooltip-default"
                    onClick={() =>
                      navigator.clipboard.writeText(connection?.uuid)
                    }
                  >
                    <Tooltip arrow title="COPY" placement="right">
                      <img src={copy} width="75%" className="pt-[3px]" />
                    </Tooltip>
                  </span>
                </div>

                <div className="flex ">
                  <a
                    href={
                      organizationDetails.org_type === "production"
                        ? connection?.customer_auth_url_prod
                        : connection?.customer_auth_url_test
                    }
                    target="_blank"
                    className="text-blue-600 cursor-pointer"
                  >
                    {(
                      organizationDetails.org_type === "production"
                        ? connection?.customer_auth_url_prod
                        : connection?.customer_auth_url_test
                    )
                      ? "O Auth Link"
                      : ""}
                  </a>
                  {(organizationDetails.org_type === "production"
                    ? connection?.customer_auth_url_prod
                    : connection?.customer_auth_url_test) && (
                    <span
                      className="pl-[3px]"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${
                            organizationDetails.org_type === "production"
                              ? connection?.customer_auth_url_prod
                              : connection?.customer_auth_url_test
                          }`
                        )
                      }
                    >
                      {" "}
                      <Tooltip arrow title="COPY" placement="right">
                        <img
                          src={copy}
                          width="75%"
                          className="pt-[3px] cursor-pointer"
                        />
                      </Tooltip>
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Webhook Card */}
      {webHook && (
        <div className="org-details font-lato px-[5px] pb-[5px]">
          <div className="flex justify-end">
            <span
              onClick={() => {
                history.push({
                  pathname: updateWebHook,
                  state: webHook,
                });
              }}
              className="text-green-900 cursor-pointer title pr-[4%]"
            >
              Update
            </span>
            {/* <span
              onClick={() => deleteWebHook(webHook?.uuid)}
              className="text-red-600 cursor-pointer title pl-[5%]"
            >
              Delete
            </span> */}
          </div>
          <h1 className="title ">
            <span>Name: </span>
            {webHook?.name.length > 35 ? (
              <Tooltip title={webHook?.name} placement="top">
                <span>
                  {webHook?.name.slice(0, 35)} {"..."}
                </span>
              </Tooltip>
            ) : (
              webHook?.name
            )}
          </h1>
          <div className="flex pb-[7px] pt-[5px]">
            <span className="heading ">ID: </span>{" "}
            <span className="description1 ml-[1%]">
              {webHook?.uuid || "N/A"}
            </span>
          </div>
          <div className="flex pb-[7px] ">
            <span className="heading ">Token: </span>
            <span className="description2 ml-[1%]">
              {webHook?.token || "N/A"}{" "}
            </span>
            <span
              className="pl-[5px]"
              onClick={() => navigator.clipboard.writeText(`${webHook?.token}`)}
            >
              {" "}
              <Tooltip arrow title="COPY" placement="right">
                <img
                  src={copy}
                  width="70%"
                  className="pt-[3px] cursor-pointer"
                />
              </Tooltip>
            </span>
          </div>
          <div className=" pb-[7px] ">
            <span className="heading ">URL: </span>
            <span style={{ fontSize: 18 }}>
              {webHook?.webhook_url.length > 25 ? (
                <Tooltip title={webHook?.webhook_url} placement="top">
                  <span>
                    {webHook?.webhook_url.slice(0, 25)} {"..."}
                  </span>
                </Tooltip>
              ) : (
                webHook?.webhook_url
              )}
            </span>
          </div>
          <div className="pb-[7px]">
            <span className="heading ">Method: </span>
            <span className="description1" style={{ fontSize: 19 }}>
              {webHook?.method || "N/A"}{" "}
            </span>
          </div>
          <div className="flex">
            <span className="heading ">Customers:</span>

            {webHook?.customer && (
              <span className="ml-[1%] description2">
                {webHook?.customer[0]?.uuid}
              </span>
            )}

            {webHook?.customer?.map((customer, index) => (
              <div key={index} className=" description2">
                {index !== 0 && <div>{customer?.uuid} </div>}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* <div className="flex  justify-end card-button ">
        <span
          onClick={() => {
            history.push({
              pathname: updateCustomerRoute,
              state: customer,
            });
          }}
          className="text-green-900 cursor-pointer title ml-[15px]"
        >
          Update
        </span>
        <span
          onClick={() => deleteCusotmer(customer?.uuid)}
          className="text-red-600 cursor-pointer title ml-[5%]"
        >
          Delete
        </span>
      </div> */}
    </div>
  );
};

export default OrganizationItem;
