import React, { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InputText from "../../components/core/InputText";
import { organizationService } from "../../services/organizationMX";
import { useHistory } from "react-router-dom";
import { CREATE_NEW_CUSTOMER_VALUES } from "../../services/initialValues";
import AppLayout from "../../components/layout/AppLayout";
import { validateEmail } from "../../utils/ValidatationFunctions";
import { CUSTOMER } from "../../utils/routeConstants";
import { deepCopy, getStoredData, setStoredData } from "../../utils";
import { validateName } from "./Validations";

function CreateCustomer() {
  const history = useHistory();

  const [customerData, setCustomerData] = useState(
    deepCopy(CREATE_NEW_CUSTOMER_VALUES)
  );

  const changeValueHandler = ({ target: { value, name } }) => {
    let copiedcustomerData = { ...customerData };

    if (name === "name") {
      copiedcustomerData.name.fieldValue = value;
      validateName(value, copiedcustomerData);
    }

    if (name === "email") {
      copiedcustomerData.email.fieldValue = value;

      validateEmail("email", value, copiedcustomerData);
    }

    setCustomerData(copiedcustomerData);
  };

  const fetchCreateCustomerAPI = () => {
    let copiedcustomerData = { ...customerData };

    if (copiedcustomerData.name.fieldValue.length === 0) {
      copiedcustomerData.name.isError = true;
      copiedcustomerData.name.errorMessage = "Name Should Not be Empty";
    }
    if (copiedcustomerData.email.fieldValue.length === 0) {
      copiedcustomerData.email.isError = true;
      copiedcustomerData.email.errorMessage = "Email Should Not be Empty";
    }
    setCustomerData(copiedcustomerData);

    // let orgId = localStorage.getItem("orgId");
    let orgId = getStoredData.getOrgId();

    let reqBody = {
      email: customerData.email.fieldValue,
      name: customerData.name.fieldValue,
    };

    if (!customerData.name.isError && !customerData.email.isError) {
      organizationService
        .createCustomers(orgId, reqBody)
        .then((res) => {
          if (res) {
            let customerCount = parseInt(getStoredData.getCustomerCount());
            setStoredData.setCustomerCount(customerCount + 1);
            history.push(CUSTOMER.DASHBOARD);
          }
        })
        .catch((err) => {
          console.log("err in create cutomer: ", err);
        });
    }
  };

  return (
    <AppLayout>
      <section className="connection-container bg-[#EDF6F9] p-[40px]">
        <div className="text-[20px]">
          <span
            className="text-[#939393] cursor-pointer"
            onClick={() => history.push(CUSTOMER.DASHBOARD)}
          >
            Customers
          </span>
          <span>
            <ArrowForwardIosIcon fontSize="small" /> Create New Customer
          </span>
        </div>

        <div className="bg-[#ffffff] pl-[5%] tabcontainers overflow-auto mt-[2%]">
          <div className="flex items-center mt-[4%] w-[full]">
            <span className="text-[20px]  text-[#3A3A3A] w-[15%]">Name</span>

            <InputText
              name="name"
              label="Name"
              error={customerData.name.isError}
              helperText={
                customerData.name.isError && customerData.name.errorMessage
              }
              onChange={changeValueHandler}
              value={customerData.name.fieldValue}
            />
          </div>

          <div className="flex items-center mt-[4%] w-[full]">
            <span className="text-[20px]  text-[#3A3A3A] w-[15%]">Email</span>
            <InputText
              name="email"
              label="Email"
              type="text"
              error={customerData.email.isError}
              helperText={
                customerData.email.isError && customerData.email.errorMessage
              }
              value={customerData.email.fieldValue}
              onChange={changeValueHandler}
            />
          </div>

          <div className="mt-[20px] text-[20px]">
            <button
              className="w-[160px] h-[56px]  rounded-[5px] bg-[#ffffff] border-[1px]"
              onClick={() => {
                history.push(CUSTOMER.DASHBOARD);
              }}
            >
              CANCEL
            </button>
            <button
              className="w-[160px] h-[56px] text-[#ffffff] rounded-[5px] bg-[#076993] ml-[20px]"
              onClick={fetchCreateCustomerAPI}
            >
              SAVE
            </button>
          </div>
        </div>
      </section>
    </AppLayout>
  );
}

export default CreateCustomer;
