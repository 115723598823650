import React, { Fragment, useState, useEffect } from "react";
import "../../assets/styles/settings.scss";
import Search from "../../components/core/Search";

import { useHistory } from "react-router-dom";

import OrganizationItem from "../../components/Organization/OrganizationItem";
import DataNotAvailable from "../../components/core/DataNotAvailable";
import addOrg from "../../assets/images/addOrg.png";
import { organizationService } from "../../services/organizationMX";
import organizationIcon from "../../assets/images/orgIcon.png";
import Loader from "../../components/core/Loader";
import CreateOrganizationModal from "../../components/Organization/CreateOrganizationModal";
import { Tooltip } from "@mui/material";
import {
  calculateTotalPageCount,
  getTokenDetails,
  setStoredData,
} from "../../utils";
import ReactPaginate from "react-paginate";

const OFF_SET = 0;
const LIMIT = 12;

const ManageOrganization = () => {
  let history = useHistory();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();

  const [organizationData, setOrganizationData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchGetOrganizations(page);
  }, []);

  const fetchGetOrganizations = (page = 1) => {
    setLoading(true);
    organizationService
      .fetchOrganizations(page, LIMIT)
      .then((resp) => {
        if (resp) {
          setPage(page);
          setTotalCount(resp?.count);
          setOrganizationData(resp?.results);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleOrgClick = (index) => {
    const orgId = organizationData[index]?.id;
    setStoredData.setOrgDetails(orgId, organizationData[index]);
    history.push("/overview");
  };

  return (
    <>
      {loading && <Loader />}
      <div className="settings-container manage-org bg-[#ffffff] pt-[20px] pl-[20px] pb-[10px] pr[40px]">
        <h2 className="text-[28px] text-[#3A3A3A] font-lato xl:text-[20px]">
          Welcome to IntraConnects!
        </h2>
        <p className="font-lato text-[20px] text-[#3a3a3a] text-lthin mt-[20px] xl:text-[14px] xl:mt-[10px]">
          Select an organization below to configure your integrations, monitor
          message traffic, and manage access to the IntraConnects Dashboard.
          Just getting started? Check out our Developer Guide for more
          information on how to get up and running on the IntraConnects Network.
        </p>

        <Search
          name="relative mt-[11px] bg-[#f3efef] p-[1px] "
          page="organization"
          parentSetState={setOrganizationData}
        />
        <h3 className="font-lato text-[#3A3A3A] mt-[12px] mb-[30px] text-[20px] xl:text-[16px] xl:mt-[18px] xl:mb-[18px]">
          Organizations
        </h3>
        <div className="settings-org-container">
          <div className="flex flex-wrap">
            {organizationData?.length ? (
              organizationData.map((organization, index) => (
                <Fragment key={`organization-item-${index}`}>
                  <OrganizationItem
                    organization={organization}
                    onClick={() => handleOrgClick(index)}
                  />
                </Fragment>
              ))
            ) : (
              <DataNotAvailable data={"Organisation"} />
            )}

            {totalCount > LIMIT && (
              <div className="fixed bottom-[20px] right-[40px]">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={calculateTotalPageCount(totalCount, LIMIT)}
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={7}
                  onPageChange={(selectedItem) =>
                    fetchGetOrganizations(selectedItem?.selected + 1)
                  }
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            )}

            <Tooltip title="CREATE NEW ORGANIZATION" arrow placement="top">
              <div className="org-float-btn right-[80px] bottom-[80px]">
                <button onClick={() => setShowModal(true)}>
                  <img src={addOrg} />
                </button>
              </div>
            </Tooltip>

            <CreateOrganizationModal
              openCenterModal={showModal}
              closeCenterModal={setShowModal}
              getOrganisationAPICall={fetchGetOrganizations}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageOrganization;
