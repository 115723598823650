import React, { useEffect, useState } from "react";
import { validateName, validateURL } from "./WebhookValidations";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
} from "@mui/material";
import CustomButton from "../../components/core/CustomButton";
import CustomCenterModal from "../../components/core/CustomCenterModal";
import { webHookService } from "../../services/webhookMX";
import { WEBHOOK_VALUES } from "../../services/initialValues";

import SideBar from "../../components/Sidebar";
import { useHistory, useLocation } from "react-router-dom";
import { getOrganizationDetails, deepCopy } from "../../utils";
import { WEBHOOK } from "../../utils/routeConstants";

// update webhook settings
const UpdateWebHook = ({}) => {
  const history = useHistory();
  const location = useLocation();

  const [webHookValues, setWebHookValues] = useState(deepCopy(WEBHOOK_VALUES));

  const [isTokenModalVisible, setIsTokenModalVisible] = useState(false);
  const [updateValue, setUpdateValue] = useState(false);

  const orgId = getOrganizationDetails();

  useEffect(() => {
    let copiedWebHookValues = { ...webHookValues };

    copiedWebHookValues.webHookName.fieldValue = location.state.name;
    copiedWebHookValues.webHookURL.fieldValue = location.state.webhook_url;
    copiedWebHookValues.method.fieldValue = location.state.method;
    copiedWebHookValues.is_active = location.state.is_active;
    copiedWebHookValues.is_verified = location.state.is_verified;
    copiedWebHookValues.token = location.state.token;

    setWebHookValues(copiedWebHookValues);
  }, []);

  const updateWebHook = () => {
    let copiedWebHookValues = { ...webHookValues };

    const reqBody = {
      name: copiedWebHookValues.webHookName.fieldValue,
      webhook_url: copiedWebHookValues.webHookURL.fieldValue,
      method: copiedWebHookValues.method.fieldValue,
      is_active: copiedWebHookValues.is_active,
    };

    if (
      !copiedWebHookValues.webHookName.isError &&
      !copiedWebHookValues.webHookURL.isError
    ) {
      webHookService
        .updateWebHook(orgId, location.state.uuid, reqBody)
        .then((resp) => {
          if (resp) {
            history.push(WEBHOOK.DASHBOARD);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  function getUpdateWebhook() {
    if (
      !webHookValues.webHookName.isError &&
      !webHookValues.webHookURL.isError &&
      !webHookValues.method.isError
    ) {
      setUpdateValue(true);
    }
  }
  const webHookValuesChangeHandler = ({ target: { value, name } }) => {
    let copiedWebHookValues = { ...webHookValues };

    if (name === "name") {
      copiedWebHookValues.webHookName.fieldValue = value;

      validateName(value, copiedWebHookValues);
    }

    if (name === "webhook_url") {
      copiedWebHookValues.webHookURL.fieldValue = value;

      validateURL(value, copiedWebHookValues);
    }

    if (name === "method") {
      copiedWebHookValues.method.fieldValue = value;
      if (value !== "") {
        copiedWebHookValues.method.isError = false;
      } else {
        copiedWebHookValues.method.isError = true;
        copiedWebHookValues.method.errorMessage = "Please Select Method Type";

        //set message
      }
    }
    setWebHookValues(copiedWebHookValues);
  };

  const genrateNewToken = () => {
    webHookService
      .genrateNewToken(orgId, location.state.uuid)
      .then((res) => {
        setIsTokenModalVisible(false);
        setWebHookValues({ ...webHookValues, token: res.token });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkboxHandler = () => {
    setWebHookValues({ ...webHookValues, is_active: !webHookValues.is_active });
  };

  return (
    <div className="flex w-full">
      <SideBar />

      <div className="create-edit-apikey-container bg-[#EDF6F9] px-[50px] w-[82%]">
        <div className="text-[20px]  mt-[40px]">
          <span
            className="text-[#939393] cursor-pointer"
            onClick={() => history.push(WEBHOOK.DASHBOARD)}
          >
            Webhooks
          </span>
          <span>
            <ArrowForwardIosIcon fontSize="small" /> Update Webhook
          </span>
        </div>
        <div className="create-container flex flex-col mt-[30px]">
          <div className="flex w-full">
            <span className="w-[25%] pt-[18px] font-lato text-[20px] text-[#3a3a3a] xl:text-[16px]">
              Name
            </span>

            <TextField
              name="name"
              label=""
              className="w-[698px] textinput-field xl:h-[48px]"
              placeholder="Webhook Name"
              onChange={webHookValuesChangeHandler}
              value={webHookValues.webHookName.fieldValue}
              error={webHookValues.webHookName.isError}
              helperText={
                webHookValues.webHookName.isError &&
                webHookValues.webHookName.errorMessage
              }
            />
          </div>

          <div className="mt-[40px] flex w-full">
            <div className="flex w-[25%]">
              <span className="font-lato text-[20px] text-[#3a3a3a] pt-[12px]  xl:text-[16px] xl:mt-[10px]">
                Webhook URL
              </span>
            </div>
            <TextField
              name="webhook_url"
              label=""
              className="w-[698px] textinput-field"
              placeholder="Webhook URL"
              onChange={webHookValuesChangeHandler}
              value={webHookValues.webHookURL.fieldValue}
              error={webHookValues.webHookURL.isError}
              helperText={
                webHookValues.webHookURL.isError &&
                webHookValues.webHookURL.errorMessage
              }
            />
          </div>

          <div className="mt-[40px] flex w-full">
            <div className="w-[25%] flex">
              <span className="font-lato text-[20px] text-[#3a3a3a] xl:text-[16px] xl:mt-[10px]">
                Verified{" "}
              </span>
            </div>
            <div className="mt-[-0.5%] ml-[-0.6%] ">
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={webHookValues.is_verified ? true : false}
                disabled={true}
              />
            </div>
          </div>

          <div className="mt-[40px] flex w-full">
            <div className="w-[25%] flex">
              <span className="font-lato text-[20px]  xl:text-[16px] xl:mt-[10px]">
                Active
              </span>
            </div>

            <div className="mt-[-0.5%] ml-[-0.6%] ">
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={webHookValues.is_active ? true : false}
                onChange={checkboxHandler}
                disabled={!webHookValues?.is_verified}
              />
            </div>
          </div>

          <div className="mt-[40px] flex w-full">
            <div className="w-[25%] flex">
              <span className="font-lato text-[20px] text-[#3a3a3a] xl:text-[16px] xl:mt-[10px]">
                Method Type
              </span>
            </div>
            <FormControl>
              <RadioGroup
                row
                name="method"
                className="xl:text-[16px]"
                aria-labelledby="demo-row-radio-buttons-group-label"
                onChange={webHookValuesChangeHandler}
                value={webHookValues.method.fieldValue}
                required
              >
                <FormControlLabel value="get" control={<Radio />} label="GET" />
                <FormControlLabel
                  value="post"
                  control={<Radio />}
                  label="POST"
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="mt-[40px] flex w-full ">
            <div className="flex w-[25%]">
              <span className="font-lato text-[20px] text-[#3a3a3a] xl:text-[16px]">
                Token
              </span>
            </div>
            <span className="text-[20px] text-[#3a3a3a] font-lato xl:text-[16px] ">
              {webHookValues?.token}{" "}
            </span>
            <button
              className="ml-[30px] w-[15%] h-[36px] bg-[#CCCCCC] rounded-[5px] text-[20px] text-[#3a3a3a] font-lato xl:text-[16px] xl:w-[120px] xl:h-[28px]"
              onClick={() => setIsTokenModalVisible(true)}
            >
              Generate New Token
            </button>
          </div>

          <div className="flex justify-end mt-[42px] xl:mt-[12px]">
            <button
              className="w-[210px] h-[56px] bg-[#FFFFFF] border-[1px] border-black rounded-[10px] text-[20px] mr-[10px] xl:w-[120px] xl:h-[48px]"
              onClick={() => {
                history.push(WEBHOOK.DASHBOARD);
              }}
            >
              CANCEL
            </button>

            <CustomButton
              text="UPDATE"
              className="filled-custom-btn w-[215px] h-[56px] text-lato text-[20px] xl:w-[160px] xl:h-[48px]"
              onClick={getUpdateWebhook}
            />
          </div>
        </div>

        <CustomCenterModal
          saveButtonTitle={"UPDATE"}
          closeButtonTitle={"CANCEL"}
          openCenterModal={updateValue}
          onCloseCenterModal={() => setUpdateValue(false)}
          closeButtonHandler={() => setUpdateValue(false)}
          className=""
          saveButtonHandler={() => {
            {
              updateWebHook();
            }
          }}
        >
          <p className="text-[20px] font-lato ">
            Are you sure you want to update this Webhook?
          </p>
        </CustomCenterModal>
        <CustomCenterModal
          saveButtonTitle={"YES"}
          closeButtonTitle={"NO"}
          openCenterModal={isTokenModalVisible}
          onCloseCenterModal={() => setIsTokenModalVisible(false)}
          closeButtonHandler={() => setIsTokenModalVisible(false)}
          saveButtonHandler={genrateNewToken}
          className=""
        >
          <p className="text-[#003344] text-[20px] font-bold font-lato">
            Do you want to change your verification token?
          </p>
        </CustomCenterModal>
      </div>
    </div>
  );
};

export default UpdateWebHook;
