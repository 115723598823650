import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory, useLocation } from "react-router-dom";
import DropdownV2 from "../../components/core/DropdownV2";
import AppLayout from "../../components/layout/AppLayout";
import { getOrganizationDetails } from "../../utils";
import { CUSTOMER } from "../../utils/routeConstants";
import { webHookService } from "../../services/webhookMX";
import { organizationService } from "../../services/organizationMX";
import { toast } from "react-toastify";
import { toastId } from "../../utils";

function AddWebHookToCustomer() {
  const location = useLocation();
  const history = useHistory();
  const [customer, setCustomer] = useState("");
  const [webHooks, setWebHooks] = useState([]);
  const [selectedWebhook, setSelectedWebhook] = useState("");

  useEffect(() => {
    setCustomer(location.state);
    setSelectedWebhook(location.state.webhook);
    getWebHooks(1);
  }, []);

  const onChangeHandler = (event) => {
    setSelectedWebhook(event.target.value);
  };

  const LIMIT = 100,
    orgId = getOrganizationDetails();

  function getWebHooks(page) {
    webHookService
      .getWebHooksByOrgId(orgId, page, LIMIT)
      .then((res) => {
        if (res) {
          setWebHooks(res.results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onSavehandler = () => {
    let reqBody = {
      webhook: selectedWebhook?.uuid,
      email: customer.email,
      name: customer.name,
    };

    organizationService
      .patchConnectionstoCustomer(
        customer.uuid,
        getOrganizationDetails(),
        reqBody
      )
      .then((resp) => {
        history.push(CUSTOMER.DASHBOARD);
        if (resp?.webhook) {
          toast.success("Webhook Added Successfully!", {
            toastId,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <AppLayout>
      <section className="connection-container  bg-[#EDF6F9] p-[40px]">
        <div className="text-[20px]">
          <span
            className="text-[#939393] cursor-pointer"
            onClick={() => history.push(CUSTOMER.DASHBOARD)}
          >
            Customers
          </span>
          <span>
            <ArrowForwardIosIcon fontSize="small" /> Add Webhook To Customer
          </span>
        </div>
        <div className="bg-[#ffffff] pl-[50px] xl:pt-[25px] xl:pl-[25px]  overflow-auto mt-[2%]">
          <div className="flex items-center mt-[4%] mb-[2%] w-[full]">
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              Customer Name
            </span>
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              {customer?.name}
            </span>
          </div>
          <div className="flex items-center mt-[2%] mb-[2%] w-[full]">
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              Customer Email
            </span>
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              {customer?.email}
            </span>
          </div>
          <div className="flex items-center my-[2%]  w-[full]">
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              Customer ID
            </span>
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[40%]">
              {customer?.uuid}
            </span>
          </div>
          <div className="flex items-center my-[2%]  w-[full]">
            <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
              Select Webhook
            </span>
            <DropdownV2
              dropdownName="webhook"
              className="w-[698px] h-[56px] rounded-[10px] mr-[50px]"
              list={webHooks}
              onChange={onChangeHandler}
              selectedvalue={selectedWebhook?.name}
              label="Select Webhook"
            />
          </div>
        </div>
        <div className="mt-[20px]">
          <button
            className="w-[160px] h-[56px] text-[20px] font-lato rounded-[5px] bg-[#ffffff] border-[1px]"
            onClick={() => history.push(CUSTOMER.DASHBOARD)}
          >
            CANCEL
          </button>
          <button
            className="w-[160px] h-[56px] text-[20px] text-[#ffffff] font-lato rounded-[5px] bg-[#076993] ml-[20px]"
            onClick={onSavehandler}
          >
            SAVE
          </button>
        </div>
      </section>
    </AppLayout>
  );
}

export default AddWebHookToCustomer;
