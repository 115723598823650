import React, { useState } from "react";
import "../../assets/styles/common.scss";

import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from '@mui/lab/LoadingButton';

const CustomCenterModal = ({
  openCenterModal,
  onCloseCenterModal,
  className,
  modalClassName,
  modalHeaderTitle,
  saveButtonTitle,
  saveButtonHandler,
  closeButtonTitle,
  closeButtonHandler,
  children,
  saveBtnDisable,
  setShowButtons,
  showCloseIcon,
  loader
}) => {

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openCenterModal}
      onClose={onCloseCenterModal}
      className={"create-center-modal " + `${modalClassName} ?? ""`}
      closeAfterTransition
    >
      <div className={"RE-custom-modal" + `${className} ?? ""`}>
        <div className="flex justify-between">
          <p className="text-[20px] xl:text-[16px]">{modalHeaderTitle ?? ""}</p>
          {showCloseIcon && <IconButton onClick={closeButtonHandler}>
              <CloseIcon />
          </IconButton>}
        </div>

        {children}

       {!setShowButtons && <div className="modal-button-container">
          <button
            className="close font-lato text-[20px] text-[#3A3A3A] xl:text-[16px] lg:text-[10px]"
            onClick={closeButtonHandler}
          >
            {closeButtonTitle ?? "CANCEL"}
          </button>
          {/* <button
            className={"save font-lato ml-[20px] text-[20px] text-[#FFF] xl:text-[16px] lg:text-[10px]" + `${saveBtnDisable && "disabled-btn"}`}
            onClick={saveButtonHandler}
            disabled={saveBtnDisable || false}
          >
            {saveButtonTitle ?? "SAVE"}
          </button> */}

          <LoadingButton
            loading={loader}
            loadingPosition="center"
            className={"font-lato ml-[20px] text-[20px] text-[#FFF] xl:text-[16px] lg:text-[10px] " + `${!loader && "modal-save-btn "}` + `${saveBtnDisable && "disabled-btn"}`}
            onClick={saveButtonHandler}
            disabled={saveBtnDisable || false}
          >
            {saveButtonTitle ?? "SAVE"}
          </LoadingButton>
        </div>}
      </div>
    </Modal>
  );
};

export default CustomCenterModal;
