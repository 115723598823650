import Login from "../pages/user-auth/Login";
import SignUp from "../pages/user-auth/Signup";
import ForgotPassword from "../pages/user-auth/ForgotPassword";
import ResetPassword from "../pages/user-auth/ResetPassword";
import VerifyEmail from "../pages/user-auth/VerifyMail";
import Overview from "../pages/overview";
import Connection from "../pages/connection/ConnectionDashboard";
import Logs from "../pages/logPage/LogsDashboard";
import Settings from "../pages/settings";
import RequireAuth from "./RequireAuth";
import Dashboard from "../pages/dashboard";
import NotLoggedIn from "../components/NotLoggedIn";
import NotFound from "../components/NotFound";
import CreateCustomer from "../pages/customer/CreateCustomer";
import AddConnectionsToCustomer from "../pages/customer/AddConnectionsToCustomer";
import CreateConnection from "../pages/connection/CreateConnection";
import AppLayout from "../components/layout/AppLayout";
import UserLogOut from "./UserLogOut";
import WebHookDashboard from "../pages/webHook/WebHookDashboard";

import CustomerProvider from "../pages/customer/CustomerProvider";
import CustomerPatient from "../pages/customer/CustomerPatient";
import CustomerDashboard from "../pages/customer/CustomerDashboard";
import {
  CONNECTIONS,
  CUSTOMER,
  DASHBOARD,
  FORGOT_PASSWORD,
  HOME,
  LAYOUT,
  LOG,
  NOT_FOUND,
  NOT_LOGGED,
  OVERVIEW,
  RESET_PASSWORD,
  SETTINGS,
  SIGN_UP,
  VERIFY_EMAIL,
  WEBHOOK,
} from "./routeConstants";
import ConnectionUpdate from "../pages/connection/ConnectionUpdate";
import CustomerUpdate from "../pages/customer/CustomerUpdate";
import CreateWebHook from "../pages/webHook/CreateWebHook";
import UpdateWebHook from "../pages/webHook/UpdateWebHook";
import AddWebHookToCustomer from "../pages/customer/AddWebHookToCustomer";

export const appRoutes = [
  {
    path: HOME,
    exact: true,
    main: () => (
      <UserLogOut>
        <Login />
      </UserLogOut>
    ),
  },
  {
    path: SIGN_UP,
    exact: true,
    main: () => <SignUp />,
  },
  {
    path: FORGOT_PASSWORD,
    exact: true,
    main: () => <ForgotPassword />,
  },
  {
    path: RESET_PASSWORD,
    exact: true,
    main: () => <ResetPassword />,
  },
  {
    path: VERIFY_EMAIL,
    exact: true,
    main: () => <VerifyEmail />,
  },
  {
    path: DASHBOARD,
    exact: true,
    main: () => (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
  },
  {
    path: OVERVIEW,
    exact: true,
    main: () => (
      <RequireAuth>
        <Overview />
      </RequireAuth>
    ),
  },
  {
    path: CUSTOMER.DASHBOARD,
    exact: true,
    main: () => (
      <RequireAuth>
        <CustomerDashboard />
      </RequireAuth>
    ),
  },

  {
    path: CUSTOMER.PROVIDER,
    exact: true,
    main: () => (
      <RequireAuth>
        <CustomerProvider />
      </RequireAuth>
    ),
  },
  {
    path: CUSTOMER.PATIENT,
    exact: true,
    main: () => (
      <RequireAuth>
        <CustomerPatient />
      </RequireAuth>
    ),
  },
  {
    path: CUSTOMER.CREATE,
    exact: true,
    main: () => (
      <RequireAuth>
        <CreateCustomer />
      </RequireAuth>
    ),
  },
  {
    path: CUSTOMER.ADD_CONNECTION,
    exact: true,
    main: () => (
      <RequireAuth>
        <AddConnectionsToCustomer />
      </RequireAuth>
    ),
  },
  {
    path: CUSTOMER.ADD_WEBHOOK,
    exact: true,
    main: () => (
      <RequireAuth>
        <AddWebHookToCustomer />
      </RequireAuth>
    ),
  },
  {
    path: CUSTOMER.UPDATE,
    exact: true,
    main: () => (
      <RequireAuth>
        <CustomerUpdate />
      </RequireAuth>
    ),
  },
  {
    path: WEBHOOK.DASHBOARD,
    exact: true,
    main: () => (
      <RequireAuth>
        <WebHookDashboard />
      </RequireAuth>
    ),
  },
  {
    path: WEBHOOK.CREATE,
    exact: true,
    main: () => (
      <RequireAuth>
        <CreateWebHook />
      </RequireAuth>
    ),
  },
  {
    path: WEBHOOK.UPDATE,
    exact: true,
    main: () => (
      <RequireAuth>
        <UpdateWebHook />
      </RequireAuth>
    ),
  },
  {
    path: CONNECTIONS.DASHBOARD,
    exact: true,
    main: () => (
      <RequireAuth>
        <Connection />
      </RequireAuth>
    ),
  },
  {
    path: CONNECTIONS.CREATE,
    exact: true,
    main: () => (
      <RequireAuth>
        <CreateConnection />
      </RequireAuth>
    ),
  },
  {
    path: CONNECTIONS.UPDATE,
    exact: true,
    main: () => (
      <RequireAuth>
        <ConnectionUpdate />
      </RequireAuth>
    ),
  },
  {
    path: LOG,
    exact: true,
    main: () => (
      <RequireAuth>
        <Logs />
      </RequireAuth>
    ),
  },
  {
    path: SETTINGS.MANAGE_ORGANIZATION,
    exact: true,
    main: () => (
      <RequireAuth>
        <Settings activeTab={0} />
      </RequireAuth>
    ),
  },
  {
    path: SETTINGS.ORGANIZATION_PROFILE,
    exact: true,
    main: () => (
      <RequireAuth>
        <Settings activeTab={1} />
      </RequireAuth>
    ),
  },
  {
    path: SETTINGS.MANAGE_USERS,
    exact: true,
    main: () => (
      <RequireAuth>
        <Settings activeTab={2} />
      </RequireAuth>
    ),
  },
  {
    path: SETTINGS.USER_SETTINGS,
    exact: true,
    main: () => (
      <RequireAuth>
        <Settings activeTab={3} />
      </RequireAuth>
    ),
  },
  {
    path: NOT_LOGGED,
    exact: true,
    main: () => <NotLoggedIn />,
  },
  {
    path: LAYOUT,
    exact: true,
    main: () => (
      <RequireAuth>
        <AppLayout />
      </RequireAuth>
    ),
  },
  {
    path: NOT_FOUND,
    exact: false,
    main: () => <NotFound />,
  },
];
