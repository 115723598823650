import React, { forwardRef } from "react";
import "../../assets/styles/common.scss";
import Dropdown from "react-bootstrap/Dropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="text-decoration-none "
  >
    {children}
    {/* <span className="threedots" /> */}
    <MoreVertIcon/>
  </a>
));

const DotToggleButton = ({
  optionsData,
  onSelect,
  showToggle,
  dropdownSelect
}) => {
  return (
    <Dropdown 
      className="RE-3-dot-toggle" 
      onClick={() => dropdownSelect && dropdownSelect()}
      // autoClose='outside'
    >
      <Dropdown.Toggle as={CustomToggle} />
      {showToggle && <Dropdown.Menu size="lg" title="">
        {optionsData?.map((item, index) => (
          <Dropdown.Item 
            key={index}
            onClick={() => onSelect && onSelect()}
            className="flex"
          >
            {item ?? 'N/A'}
          </Dropdown.Item>))}
      </Dropdown.Menu>}
    </Dropdown>
  );
};

export default DotToggleButton;
