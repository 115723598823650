import FilterDropdown from "../../../components/core/FilterDropdown";
import {
  DATA_MODELS,
  EVENT_TYPES,
  SELECT_EVENT_TYPES,
} from "../constants/filter";

const DataModels = ({ filters, setFilters, setEventTypes }) => {
  // Data Model Filter Handler
  const dataModelFilterHandler = ({ target: { name, value } }) => {
    if (value) {
      setFilters({ ...filters, [name]: value });
      setEventTypes(SELECT_EVENT_TYPES[value]);
    } else {
      setEventTypes(EVENT_TYPES);
    }
  };
  return (
    <div className="logs-dropdown mt-[10px] xl:mt-[10px]">
      <span className="text-[20px] font-bold text-[#003344] xl:text-[16px]">
        Data Model
      </span>
      <FilterDropdown
        name="dataModel"
        className={"relative  bg-[#ffffff] h-[48px] "}
        placeholder="Any"
        list={DATA_MODELS}
        onChange={dataModelFilterHandler}
        selectedvalue={filters.dataModel}
      />
    </div>
  );
};
export default DataModels;
