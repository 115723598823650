import FilterDropdown from "../../../components/core/FilterDropdown";
import { useState, useEffect, useRef } from "react";
import { organizationService } from "../../../services/organizationMX";
import { getOrganizationDetails } from "../../../utils";

const Connections = ({
  filters,
  setFilters,
  customers,
  setCustomers,
  connections,
  setConnections,
}) => {
  const orgId = getOrganizationDetails();
  const RECORD_LIMIT = 100;
  const PAGE = 1;
  let initialCustomers = useRef(0);
  let mapFunCalled = useRef(0);
  const [connectionMappedWithCustomer, setConnectionMappedWithCustomer] =
    useState();

  useEffect(() => {
    getConnections();
  }, []);

  // GET Connection list
  const getConnections = () => {
    organizationService
      .fetchConnections(PAGE, orgId, RECORD_LIMIT)
      .then((resp) => {
        if (resp) {
          setConnections(resp?.results);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  //Filter Handler
  const connctionFilterHandler = ({ target: { name, value } }) => {
    setFilters({ ...filters, [name]: value });
    let mappedObj;
    if (value) {
      if (
        mapFunCalled.current === 0 &&
        connections.length &&
        customers.length
      ) {
        mappedObj = mapConnectionWithCustomer();
        initialCustomers.current = customers;
        if (mappedObj) {
          setConnectionMappedWithCustomer(mappedObj);

          if (mappedObj?.get(value).length) {
            setCustomers(mappedObj.get(value));
          } else setCustomers([]);
        }
      } else {
        if (connectionMappedWithCustomer?.get(value).length) {
          setCustomers(connectionMappedWithCustomer.get(value));
        } else setCustomers([]);
      }
    } else {
      setCustomers(initialCustomers.current);
    }
  };

  const mapConnectionWithCustomer = () => {
    const connectionWithCustomerMap = new Map([]);

    connections.map((connection, connectionIndex) => {
      const mappedConnection = [];
      customers.map((customer, cusotmerIndex) => {
        if (customer.connection.find((ele) => ele.uuid === connection.uuid)) {
          mappedConnection.push({
            name: customer.name,
            uuid: customer.uuid,
          });
        }
      });
      connectionWithCustomerMap.set(connection.uuid, mappedConnection);
    });
    mapFunCalled.current = 1;
    return connectionWithCustomerMap;
  };

  return (
    <div className="logs-dropdown flex flex-col justify-between">
      <span className="text-[20px] font-bold text-[#003344] xl:text-[16px]">
        Connection
      </span>
      <FilterDropdown
        name="connectionId"
        className="relative bg-[#ffffff] h-[48px] "
        placeholder="Any"
        list={connections}
        onChange={connctionFilterHandler}

        // loading={connectionLoading}
        // renderValue={filters?.connection}
      />
    </div>
  );
};
export default Connections;
