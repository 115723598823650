export const CREATE_NEW_CONNECTION_VALUES = {
  connection_name: "",
  ehr_name: "",
  app_type: "",
  client_id: "",
  client_secret: "",
  redirect_uri: "",
  scope: "",
  audiance: "",
  state: "",
  nonce: "",
  launch: "",
  site_id: "",
  client_id: "",
  access_token: "",
};

export const CREATE_NEW_CUSTOMER_VALUES = {
  name: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
  email: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
};

export const CREATE_NEW_API_KEY_VALUES = {
  name: "",
  organizationId: "",
  apiKey: "",
  sourceSecretCode: "",
  environment: "",
};

export const WEBHOOK_VALIDATIONS = {
  organizationName: {
    name: "",
    error: false,
    type: "text",
    organizationNameErrorMessage: "Organization Name Should Not be Empty.",
  },
  userName: {
    name: "",
    error: false,
    type: "text",
    fullNameErrorMessage: "User Name Should Not be Empty.",
    noWhiteSpaceError: false,
    noWhiteSpaceErrorMessage: "Extra Space Is Not Allowed.",
    acceptOnlyAlphabetsError: false,
    acceptOnlyAlphabetsErrorMessage:
      "Name Should Not Includes Any Symbols Or Numbers",
  },
  email: {
    name: "",
    error: false,
    type: "email",
    emailErrorMessage: "Enter Valid Email ID.",
  },

  phonenumber: {
    name: "",
    type: "number",
    error: false,
    PhoneNumberErrorMessage: "Enter Phone Number",
    phNumberLengthError: false,
    phNumberLengthErrorMessage: "Phone Number Should be 10 Digits.",
  },
  alertemail: {
    name: "",
    type: "email",
    error: false,
    errorMessage: "",
  },
  salesemail: {
    name: "",
    type: "email",
    error: false,
    errorMessage: "",
  },
  overview: {
    name: "",
    error: false,
    overviewErrorMessage: "",
  },
  twitter: {
    name: "",
    error: false,
    twitterErrorMessage: "Enter Valid Twitter ID",
    lengthErrorTwitter: false,
    lengthErrorMessageTwitter: "Length Should Not be More Than 15",
    emptyError: false,
    emptyErrorMessage: "Please Enter Twitter ID",
  },
  website: {
    name: "",
    error: false,
    webSiteErrorMessage: "Please Enter Valid URL",
    emptyURLError: false,
    emptyURLErrorMessage: "Please Enter URL",
    lengthError: false,
    lengthErrorMessage: "Length Should Not be More Than 1000",
    spaceInURL: false,
    spaceInURLMessage: "URL Should Not Contain Any Spaces",
  },
  org_type: {
    name: "",
    error: false,
    type: "text",
    envValueErrorMessage: "Enter Phone Number",
  },
  // API Key name
  apiKeyName: {
    name: "",
    error: false,
    nameErrorMessage: "Please Enter API Key Name.",
    noWhiteSpaceError: false,
    noWhiteSpaceErrorMessage: "Extra Space is Not Allowed.",
  },

  // webhook name
  webHookName: {
    name: "",
    error: false,
    nameErrorMessage: "Please Enter Webhook Name.",
    noWhiteSpaceError: false,
    noWhiteSpaceErrorMessage: "Name Should Not Contain Space.",
    noWhiteSpaceErrorBetween: false,
    noWhiteSpaceErrorBetweenMessage: "No Space is Allowed Between Words",
    acceptOnlyAlphaNumericUnderscoreError: false,
    alphaNumericUnderscoreErrorMessage:
      "Name Should Only contain Alphabets, Digits and Underscores",
    specialSymbolError: false,
    specialSymbolErrorMessage:
      "Name Should Not Contain Any Special Symbols Other Than Underscore",
    limitError: false,
    limitErrorMessage: "Length Should Not be More Than 60",
  },
  webHookURL: {
    name: "",
    error: false,
    urlErrorMessage: "Invalid URL.",
    emptyURLError: false,
    emptyURLErrorMessage: "Please Enter Webhook URL",
    urlLimitError: false,
    urlLimitErrorMessage: "URL Length Should Not be More Than 1000",
    spaceInURL: false,
    spaceInURLMessage: "URL Should Not Contain Any Spaces",
  },
  method: {
    name: "",
    error: false,
    emptyErrorMessage: "Please Select Method Type",
  },
};
export const UPDATE_WEB_HOOK_VALUES = {
  name: "",
  webhook_url: {
    name: "",
    error: false,
    type: "text",
    UrlErrorNotValid: false,
    UrlErrorNotValidMessage: "Enter Valid Url",
  },
  method: "",
};

/** ********************************* Manage users ************************************* */
export const CREATE_USER_DATA = {
  fullName: {
    name: "",
    error: false,
    type: "text",
    fullNameErrorMessage: "Full Name Should Not be Empty.",
    noWhiteSpaceError: false,
    noWhiteSpaceErrorMessage: "Extra Space Is Not Allowed.",
    acceptOnlyAlphabetsError: false,
    acceptOnlyAlphabetsErrorMessage:
      "Name Should Not Includes Any Symbols or Numbers",
  },
  email: {
    name: "",
    error: false,
    type: "email",
    emailErrorMessage: "Enter Valid Email ID.",
  },
  role: {
    name: "",
    error: false,
    roleErrorMessage: "Please Select Role.",
  },
};

export const MFA_DATA = {
  method: "",
  countryCode: "",
  phonenumber: "",
};

export const DELETE_USER_DATA = {
  email: "",
  organizationId: "",
};

export const LOGS_FILTERS = {
  connectionId: undefined,
  customerId: undefined,
  dataModel: undefined,
  eventType: undefined,
  // status: undefined,
  // type: undefined,
  fromTime: undefined, // 2022-09-02T06:57:17.247Z
  toTime: undefined, // 2022-09-02T06:57:17.247Z
};

export const VALIDATION_UPDATE_USER = {
  userName: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },

  phoneNumber: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
};

export const VALIDATIONS = {
  organizationName: {
    name: "",
    error: false,
    type: "text",
    organizationNameErrorMessage: "Organization Name Should Not be Empty.",
  },
  userName: {
    name: "",
    error: false,
    type: "text",
    fullNameErrorMessage: "User Name Should Not be Empty.",
    noWhiteSpaceError: false,
    noWhiteSpaceErrorMessage: "Extra Space Is Not Allowed.",
    acceptOnlyAlphabetsError: false,
    acceptOnlyAlphabetsErrorMessage:
      "Name Should Not Includes Any Symbols Or Numbers",
  },
  email: {
    name: "",
    error: false,
    type: "email",
    emailErrorMessage: "Enter Valid Email ID.",
  },

  phonenumber: {
    name: "",
    type: "number",
    error: false,
    PhoneNumberErrorMessage: "Enter Phone Number",
    phNumberLengthError: false,
    phNumberLengthErrorMessage: "Phone Number Should be 10 Digits.",
  },
  alertemail: {
    name: "",
    type: "email",
    error: false,
    errorMessage: "",
  },
  salesemail: {
    name: "",
    type: "email",
    error: false,
    errorMessage: "",
  },
  overview: {
    name: "",
    error: false,
    overviewErrorMessage: "",
  },
  twitter: {
    name: "",
    error: false,
    twitterErrorMessage: "Enter Valid Twitter ID",
    lengthErrorTwitter: false,
    lengthErrorMessageTwitter: "Length Should Not be More Than 15",
    emptyError: false,
    emptyErrorMessage: "Please Enter Twitter ID",
  },
  website: {
    name: "",
    error: false,
    webSiteErrorMessage: "Please Enter Valid URL",
    emptyURLError: false,
    emptyURLErrorMessage: "Please Enter URL",
    lengthError: false,
    lengthErrorMessage: "Length Should Not be More Than 1000",
    spaceInURL: false,
    spaceInURLMessage: "URL Should Not Contain Any Spaces",
  },
  org_type: {
    name: "",
    error: false,
    type: "text",
    envValueErrorMessage: "Enter Phone Number",
  },
  // API Key name
  apiKeyName: {
    name: "",
    error: false,
    nameErrorMessage: "Please Enter API Key Name.",
    noWhiteSpaceError: false,
    noWhiteSpaceErrorMessage: "Extra Space is Not Allowed.",
  },
};

export const WEBHOOK_VALUES = {
  webHookName: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
  webHookURL: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
  method: {
    fieldValue: "",
    isError: false,
    errorMessage: "Please Select Method Type",
  },
  is_active: false,
  is_verified: false,
  token: "",
};

export const USERS_UPDATE_PASSWORD_DATA = {
  newPassword: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
    minMaxError: false,
    charactersError: false,
  },
  confirmPassword: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
};
export const OrgData = {
  orgName: {
    fieldValue: "",
    isError: false,
    errorMessage: "Organization Name Should Not be Empty.",
  },

  phoneNumber: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
  alertEmail: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
  salesEmail: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
  overview: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
  twitter: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
  website: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },
  orgType: {
    fieldValue: "",
    isError: false,
    envValueErrorMessage: "Please Select Environment Type",
  },
  envValue: {
    fieldValue: "",
    isError: false,
    envValueErrorMessage: "Please Select Environment Type",
  },
  logo: {
    additionalProp1: "",
  },
};
export const SIGN_UP_VALUES = {
  userName: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },

  emailId: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
  },

  password: {
    fieldValue: "",
    isError: false,
    id: "password",
    emptyError: false,
    emptyErrorMessage: "Password Should Not be Empty",
    minMaxError: false,
    minMaxErrorMessage:
      "Password must be Longer than 8 Characters, and Shorter than 256 Characters",
    charactersError: false,
    charactersErrorMessage:
      "Password must Include these Categories: A-Z,a-z,0-9,and Special Characters(~!@#$%^&*/?)",
  },
  confirmPassword: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
    id: "confirmPassword",
  },
};

export const RESET_VALUES_VALIDATIONS = {
  password: {
    fieldValue: "",
    id: "password",
    emptyError: false,
    emptyErrorMessage: "Password Should Not be empty",
    isError: false,

    minMaxError: false,
    minMaxErrorMessage:
      "Password must be Longer than 8 Characters, and Shorter than 256 Characters",

    charactersError: false,
    charactersErrorMessage:
      "Password must Include these Categories: A-Z, a-z, 0-9, and Special Characters (~!@#$%^&*/?)",
  },
  confirmPassword: {
    fieldValue: "",
    isError: false,
    errorMessage: "",
    id: "confirmPassword",
  },
};
