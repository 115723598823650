import React from "react";
import '../../assets/styles/common.scss'

import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div id='loader-spin'></div>
  );
};

export default Loader;
