// import { detectWhiteSpaceRegex } from "../../utils";
import {
  noWhiteSpaceRegex,
  noWhiteSpaceRegexBetween,
  SignupUserNameRegex,
} from "../../utils";

const EMPTY_USERNAME_ERROR_MESSAGE = " User Name Should Not be Empty";
const LIMIT_ERROR_MESSAGE = "User Name Should be Less than 40 Characters";
const NO_WHITE_SPACE_ERROR_MESSAGE = "Space is Not Allowed";
const WHITE_SPACE_BETWEEN_ERROR_MESSAGE = "No White Space Between the Words";
const USERNAME_ERROR_MESSAGE =
  "UserName Should Contain Only Alphabets, Digits, _ , @ , - , + , .";

export const validateUserName = (value, validationState) => {
  if (value === "") {
    validationState.userName.isError = true;
    validationState.userName.errorMessage = EMPTY_USERNAME_ERROR_MESSAGE;
  } else {
    if (noWhiteSpaceRegex.test(value)) {
      validationState.userName.isError = true;
      validationState.userName.errorMessage = NO_WHITE_SPACE_ERROR_MESSAGE;
    } else if (noWhiteSpaceRegexBetween.test(value)) {
      validationState.userName.isError = true;
      validationState.userName.errorMessage = WHITE_SPACE_BETWEEN_ERROR_MESSAGE;
    } else if (!SignupUserNameRegex.test(value)) {
      validationState.userName.isError = true;
      validationState.userName.errorMessage = USERNAME_ERROR_MESSAGE;
    } else if (value.length > 40) {
      validationState.userName.isError = true;
      validationState.userName.errorMessage = LIMIT_ERROR_MESSAGE;
    } else {
      validationState.userName.isError = false;
    }
  }
};
