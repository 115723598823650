import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useState } from "react";
const DateRange = ({ filters, setFilters }) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  // Date Filter Handler
  const StartDateHandler = (date) => {
    let copiedFilters = { ...filters };

    if (date) {
      setSelectedStartDate(date);
      copiedFilters["StartDate"] = moment(date)?.format("YYYY-MM-DD");
      setFilters(copiedFilters);
    } else {
      copiedFilters["StartDate"] = "";
      setFilters(copiedFilters);
      setSelectedStartDate(null);
    }
  };

  const EndDateHandler = (date) => {
    let copiedFilters = { ...filters };

    if (date) {
      setSelectedEndDate(date);
      copiedFilters["EndDate"] = moment(date)?.format("YYYY-MM-DD");
      setFilters(copiedFilters);
    } else {
      copiedFilters["EndDate"] = "";
      setFilters(copiedFilters);
      setSelectedEndDate(null);
    }
  };

  return (
    <div className="w-[50%]">
      <div className="w-full">
        <span className="text-[20px] font-bold text-[#003344] xl:text-[16px]">
          Date Range
        </span>
        <div className="flex w-full log-textfield">
          {/* StartDate */}
          <DatePicker
            placeholderText="Start Date"
            className="filter-date-input  w-[99%]"
            selected={selectedStartDate}
            onChange={StartDateHandler}
            isClearable
            maxDate={selectedEndDate ? selectedEndDate : new Date()}
          />

          {/* EndDate */}
          <DatePicker
            placeholderText="End Date"
            className="filter-date-input w-[99%]"
            selected={selectedEndDate}
            onChange={EndDateHandler}
            isClearable
            minDate={selectedStartDate ? selectedStartDate : false}
            maxDate={new Date()}
          />
        </div>
      </div>
    </div>
  );
};
export default DateRange;
