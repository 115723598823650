import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { organizationService } from "../../services/organizationMX";
import Loader from "../../components/core/Loader";
import { getOrganizationDetails } from "../../utils";
import AppLayout from "../../components/layout/AppLayout";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactPaginate from "react-paginate";
import { calculateTotalPageCount } from "../../utils";
import { CUSTOMER } from "../../utils/routeConstants";

function CustomerProvider() {
  const [loading, setLoading] = useState(false);
  const [providerData, setProviderData] = useState(false);
  const [customerData, setCustomerData] = useState("");
  const [totalProviders, setTotalProviders] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const LIMIT = 11;
  const orgId = getOrganizationDetails();

  useEffect(() => {
    setCustomerData(location?.state);
    fetchProviderData();
  }, []);

  function fetchProviderData(page = 1) {
    setLoading(true);
    organizationService
      .fetchProviders(orgId, location?.state?.uuid, LIMIT, page)
      .then((resp) => {
        if (resp) {
          setProviderData(resp.results);
          setTotalProviders(resp.count);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <AppLayout>
      {loading && <Loader />}
      <section className="connection-container bg-[#EDF6F9] pl-[40px] pb-[40px] pr-[40px] pt-[20px] ">
        <div className=" ">
          <div className="text-[30px]">
            <span
              className="text-[#939393]  cursor-pointer"
              onClick={() => history.push(CUSTOMER.DASHBOARD)}
            >
              Customer
            </span>
            <span>
              <ArrowForwardIosIcon fontSize="small" /> Providers
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-between text-[20px]  ">
          <div>
            <span className=" ">Customer Name : {customerData?.name}</span>
          </div>
          <div>
            <span className=" ">Customer Email : {customerData?.email}</span>
          </div>
          <div>
            <span className=" ">Customer Id : {customerData?.uuid}</span>
          </div>
        </div>
        <div className="dataflow-tablecontainer mt-[1%] ">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
              <TableBody>
                <TableRow className=" text-[#3A3A3A] font-bold">
                  <TableCell className=" text-[#3A3A3A] font-bold text-[20px]  tracking-widest w-1/3 xl:text-[16px] lg:text-[12px]">
                    Id
                  </TableCell>

                  <TableCell className=" text-[#3A3A3A] font-bold text-[20px]  tracking-widest w-1/5 xl:text-[16px] lg:text-[12px]">
                    First Name
                  </TableCell>

                  <TableCell className=" text-[#3A3A3A] font-bold text-[20px] tracking-widest w-1/5  xl:text-[16px] lg:text-[12px]">
                    Status
                  </TableCell>

                  <TableCell className=" text-[#3A3A3A] font-bold text-[20px] tracking-widest w-1/5 xl:text-[16px] lg:text-[12px]">
                    Ehr Id
                  </TableCell>

                  <TableCell className=" text-[#3A3A3A] font-bold text-[20px] tracking-widest w-1/4 xl:text-[16px] lg:text-[12px]">
                    Gender
                  </TableCell>
                  <TableCell className=" text-[#3A3A3A] font-bold text-[20px] tracking-widest w-1/4 xl:text-[16px] lg:text-[12px]">
                    NPI
                  </TableCell>
                  <TableCell className=" text-[#3A3A3A] font-bold text-[20px] tracking-widest w-1/4   xl:text-[16px] lg:text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableBody>
                {providerData?.length ? (
                  providerData.map((_item, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="text-[20px] text-[#3A3A3A] tracking-widest xl:text-[12px] lg:text-[12px]">
                        {_item?.uuid || "-"}
                      </TableCell>
                      <TableCell className="text-[20px] text-[#3A3A3A] tracking-widest xl:text-[12px] lg:text-[12px]">
                        {_item?.firstname || "-"}
                      </TableCell>
                      <TableCell className="text-[20px] text-[#3A3A3A] tracking-widest xl:text-[12px] lg:text-[12px]">
                        {_item?.status || "-"}
                      </TableCell>
                      <TableCell className="text-[20px] text-[#3A3A3A] tracking-widest xl:text-[12px] lg:text-[12px]">
                        {_item.ehr_id || "-"}
                      </TableCell>
                      <TableCell className="text-[20px] text-[#3A3A3A] tracking-widest xl:text-[12px] lg:text-[12px]">
                        {_item.gender || "-"}
                      </TableCell>
                      <TableCell className="text-[20px] text-[#3A3A3A] tracking-widest xl:text-[12px] lg:text-[12px]">
                        {_item.npi || "-"}
                      </TableCell>
                      {/* <TableCell className=" text-[#3A3A3A] font-bold text-[20px] tracking-widest w-1/4   xl:text-[16px] lg:text-[12px]">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  <p>Data not available</p>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {totalProviders > LIMIT && (
          <div className="flex flex-row justify-end">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              // marginPagesDisplayed={4}
              // pageRangeDisplayed={7}
              pageCount={calculateTotalPageCount(totalProviders, LIMIT)}
              onPageChange={(selectedItem) =>
                fetchProviderData(selectedItem?.selected + 1)
              }
            />
          </div>
        )}
      </section>
    </AppLayout>
  );
}

export default CustomerProvider;
