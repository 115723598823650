import Customers from "./Filters/Customers";
import Connections from "./Filters/Connections";
import DataModels from "./Filters/DataModels";
import EventTypes from "./Filters/EventTypes";
import DateRange from "./Filters/DateRange";
import { EVENT_TYPES } from "./constants/filter";
import { useState } from "react";

const Filters = ({ filters, setFilters, filterHandler, setLoading }) => {
  const [eventTypes, setEventTypes] = useState(EVENT_TYPES);
  const [customers, setCustomers] = useState([]);
  const [connections, setConnections] = useState([]);

  return (
    <div className="filter-block w-full flex justify-between xl:pt-[0px] xl:px-[6px] xl:pb-[6px]">
      <div className="w-[33%]">
        <Connections
          filters={filters}
          setFilters={setFilters}
          customers={customers}
          setCustomers={setCustomers}
          connections={connections}
          setConnections={setConnections}
        />
        <DataModels
          filters={filters}
          setFilters={setFilters}
          setEventTypes={setEventTypes}
        />
      </div>

      <div className="w-[33%]">
        <Customers
          filterHandler={filterHandler}
          customers={customers}
          setCustomers={setCustomers}
          setLoading={setLoading}
        />
        <EventTypes
          eventTypes={eventTypes}
          filterHandler={filterHandler}
          filters={filters}
        />
      </div>
      <DateRange filters={filters} setFilters={setFilters} />
    </div>
  );
};
export default Filters;
