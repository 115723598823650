import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/common.scss";
import "../../assets/styles/components.scss";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import OrganizationItem from "../../components/Organization/OrganizationItem";
import DashboardHeader from "./DashboardHeader";
import { organizationService } from "../../services/organizationMX";
import addOrg from "../../assets/images/addOrg.png";
import DataNotAvailable from "../../components/core/DataNotAvailable";
import Footer from "../../components/core/Footer";
import Loader from "../../components/core/Loader";
import CreateOrganizationModal from "../../components/Organization/CreateOrganizationModal";
import {
  calculateTotalPageCount,
  getTokenDetails,
  setStoredData,
} from "../../utils";
import { OVERVIEW } from "../../utils/routeConstants";
import Search from "../../components/core/Search";

import ReactPaginate from "react-paginate";

const LIMIT = 9;

const Dashboard = () => {
  let history = useHistory();
  const token = getTokenDetails();

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [organizationData, setOrganizationData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchGetOrganizations(page);
  }, []);

  const fetchGetOrganizations = (pageNo = page) => {
    setLoading(true);
    organizationService
      .fetchOrganizations(pageNo, LIMIT, token)
      .then((resp) => {
        if (resp) {
          setPage(page);
          setTotalCount(resp?.count);
          setOrganizationData(resp?.results);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleOrgClick = (index) => {
    const orgId = organizationData[index]?.id;
    setStoredData.setOrgDetails(orgId, organizationData[index]);
    history.push(OVERVIEW);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="RE-dashboard-container">
        <DashboardHeader />

        <div className="organisation-details">
          <h2 className="text-[40px] text-[#003344] font-lato xl:text-[30px] lg:text-[24px]">
            Welcome to IntraConnects!
          </h2>
          <p className="text-[28px] font-lato text-[#3A3A3A] mt-[16px] xl:text-[20px] xl:mt-[8px] lg:text-[20px]">
            Select an organization below to configure your integrations, monitor
            message traffic, and manage access to the IntraConnects Dashboard.
          </p>
          <p className="text-[28px] font-lato text-[#3A3A3A] xl:text-[20px] lg:text-[20px]">
            Just getting started? Check out our Developer Guide for more
            information on how to get up and running on the IntraConnects
            Network.
          </p>
          <Search
            name="relative mt-[19px]"
            page="organizationDashboard"
            parentSetState={setOrganizationData}
          />

          <h3 className="text-[#3A3A3A] mt-[20px] mb-[20px] text-[20px] xl:text-[18px] xl:mt-[18px] lg:text-[18px]">
            Organizations
          </h3>

          <div className="flex flex-wrap organizations">
            {organizationData?.length ? (
              organizationData?.map((organization, index) => (
                <Fragment key={`organization-item-${index}`}>
                  <OrganizationItem
                    organization={organization}
                    onClick={() => handleOrgClick(index)}
                  />
                </Fragment>
              ))
            ) : (
              <DataNotAvailable data={"Organization"} />
            )}
          </div>

          {totalCount > LIMIT && (
            <div className="fixed bottom-[30px] right-[120px]">
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={calculateTotalPageCount(totalCount, LIMIT)}
                marginPagesDisplayed={4}
                pageRangeDisplayed={7}
                onPageChange={(selectedItem) =>
                  fetchGetOrganizations(selectedItem?.selected + 1)
                }
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          )}
        </div>
        <Footer className={"bottom-[5px]"} />

        <Tooltip title="CREATE NEW ORGANIZATION" arrow placement="top">
          <div className="org-float-btn">
            <button onClick={() => setShowModal(true)}>
              <img src={addOrg} />
            </button>
          </div>
        </Tooltip>

        <CreateOrganizationModal
          openCenterModal={showModal}
          closeCenterModal={setShowModal}
          getOrganisationAPICall={fetchGetOrganizations}
        />
      </div>
    </>
  );
};

export default Dashboard;
