import { toast } from "react-toastify";
import {
  GET_WEBHOOK_BY_ORGANISATION_ID,
  PATCH_WEBHOOK,
  POST_WEBHOOK,
  PATCH_WEBHOOK_TOKEN,
} from "./endpoints";
import { toastId } from "../utils";
import { getTokenDetails } from "../utils";

export const webHookService = {
  // getWebHooksByOrgId: (orgId, pageNo, limit) =>
  //   axios
  //     .get(GET_WEBHOOK_BY_ORGANISATION_ID.endpoint(orgId, pageNo, limit))
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  getWebHooksByOrgId: async (orgId, pageNo, limit) => {
    let response;
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(
        GET_WEBHOOK_BY_ORGANISATION_ID.endpoint(orgId, pageNo, limit),
        options
      );
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // getWebHookByWebHookId: (orgId, webHookId) =>
  //   axios
  //     .get(GET_WEBHOOK_BY_WEBHOOK_ID.endpoint(orgId, webHookId))
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  // getWebHookByWebHookId: async (orgId, webhookId) => {
  //   let response;

  //   let options = {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: "Bearer " + token,
  //       "Content-type": "application/json",
  //     },
  //   };

  //   try {
  //     response = await fetch(
  //       GET_WEBHOOK_BY_WEBHOOK_ID.endpoint(orgId, webhookId),
  //       options
  //     );
  //   } catch (error) {
  //     console.log("Error is:", error);
  //   }
  //   response = await response.json();
  //   return response;
  // },

  // deleteWebHook: (orgId, webHookId) =>
  //   axios
  //     .delete(DELETE_WEBHOOK.endpoint(orgId, webHookId))
  //     .then((resp) => {
  //       if (resp) {
  //         toast.success("Webhook Deleted Successfully", { toastId });
  //         return resp;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  // deleteWebHook: async (orgId, webhookId) => {
  //   let response;

  //   let options = {
  //     method: "DELETE",
  //     headers: {
  //       Accept: "",
  //       Authorization: "Bearer " + token,
  //     },
  //   };

  //   try {
  //     response = await fetch(
  //       DELETE_WEBHOOK.endpoint(orgId, webhookId),
  //       options
  //     );
  //     if (response.ok) {
  //       toast.success("Webhook Deleted Successfully", {
  //         toastId,
  //       });
  //     }
  //   } catch (error) {
  //     console.log("Error is:", error);
  //   }
  //   response = await response.json();
  //   return response;
  // },

  updateWebHook: async (orgId, webhookId, body) => {
    let response;

    let options = {
      method: "PATCH",
      headers: {
        Accept: "*/*",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      response = await fetch(PATCH_WEBHOOK.endpoint(orgId, webhookId), options);
      if (response.ok) {
        toast.success("Webhook Updated Successfully", {
          toastId,
        });
        response = await response.json();
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  createWebHook: async (orgId, body) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      response = await fetch(POST_WEBHOOK.endpoint(orgId), options);
      if (response.ok) {
        toast.success("Webhook Created successfully", {
          toastId,
        });
        response = await response.json();
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // genrateNewToken: (orgId, webHookId) =>
  //   axios
  //     .patch(PATCH_WEBHOOK_TOKEN.endpoint(orgId, webHookId))
  //     .then((res) => {
  //       if (res?.data) {
  //         return res.data;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  genrateNewToken: async (orgId, webHookId) => {
    let response;

    let options = {
      method: "PATCH",
      headers: {
        Accept: "*/*",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(
        PATCH_WEBHOOK_TOKEN.endpoint(orgId, webHookId),
        options
      );
      if (response.ok) {
        toast.success("New Token Generated successfully", {
          toastId,
        });
        response = await response.json();
      }
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },
};
