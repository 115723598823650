import React from "react";

const CustomButton = ({
  //variant,  // will use later for theming
  iconName,
  Icon,
  text,
  onClick,
  style,
  className,
  onKeyDown
}) => (
  <button
    style={style ?? {}}
    className={className ?? ""}
    onClick={onClick ?? null}
    onKeyDown={onKeyDown ?? null}
  >
    {/* {iconName ? <IconByName iconName={iconName}/> : (
      Icon ? <Icon/> : null
    )} */}
    {text ? <p>{text}</p> : null}
  </button>
);

export default CustomButton;
