import FilterDropdown from "../../../components/core/FilterDropdown";

const EventTypes = ({ eventTypes, filterHandler, filters }) => {
  return (
    <div className="logs-dropdown mt-[10px] xl:mt-[10px]">
      <span className="text-[20px] font-bold text-[#003344] xl:text-[16px]">
        Event Type
      </span>

      <FilterDropdown
        name="eventType"
        className=" bg-[#ffffff] h-[48px]"
        placeholder="Any"
        list={eventTypes}
        onChange={filterHandler}
        selectedvalue={filters.eventType}
      />
    </div>
  );
};
export default EventTypes;
