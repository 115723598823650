import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import pIcon from "../../assets/images/profilepic.png";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import CustomCenterModal from "../../components/core/CustomCenterModal";
import DropdownList from "../../components/core/DropdownList";
import DotToggleButton from "../../components/core/DotToggleButton";
import { userService } from "../../services/userMX";
import { organizationService } from "../../services/organizationMX";
import { toast } from "react-toastify";
import { CREATE_USER_DATA, DELETE_USER_DATA } from "../../services/initialValues";
import Loader from "../../components/core/Loader";
import DataNotAvailable from "../../components/core/DataNotAvailable";
import { emailRegX, getEnvironmentDetails, getOrganizationDetails, getTokenDetails, noWhiteSpaceRegex, onlyAlphabetsRegex, calculateTotalPageCount } from "../../utils";
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';

const MULTI_FACTOR_AUTH = ["ENABLE", "DISABLE"];
const ORG_OWNER = ["YES", "NO"];
const PHI_ACCESS = ["YES", "NO"];
const ROLE = [
  "Development",
  "Stage",
  "Production",
];
const OFF_SET = 0
const LIMIT = 10

const optionsData = ["Edit"];
const ManageUsers = () => {
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(1)

  const [environment, setEnvironment] = useState(getEnvironmentDetails())
  const [token, setToken] = useState(getTokenDetails())
  const [organizationId, setOrganizationId] = useState(getOrganizationDetails())

  const [showModal, setShowModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [createUsersData, setCreateUsersData] = useState(CREATE_USER_DATA);
  const [allUserDataList, setAllUserDataList] = useState([]);
  const [invitationPendingUserData, setInvitationPendingUserData] = useState([]);
  const [toggleIndex, setToggleIndex] = useState(-1);
  const [manageUserIdx, setManageUserIdx] = useState(-1);
  const [invitatedUserIdx, setInvitatedUserIdx] = useState(-1)
  const [isViewInvitationsOpen , setIsViewInvitationsOpen] = useState(false)

  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  useEffect(() => {
    // fetchAllUsersData(page);
    // fetchInvitationPendingUsersData();
  }, []);

  // Get all users data API call
  const fetchAllUsersData = page => {
    setLoading(true)
    organizationService
      .getUsersData(page, token)
        .then(resp => {
           
          if (resp) {

            setPage(page)
            setTotalCount(resp?.count)
            setAllUserDataList(resp?.results);

          }

         
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
  };

  // Get all invitation pending users data API call
  const fetchInvitationPendingUsersData = () => {
    setLoading(true)
    userService
    .getInvitationPendingUsersData(organizationId, token)
    .then(resp => {
      if(resp?.value) {
        setInvitationPendingUserData(resp.value)
      }
    })
    .catch((err) => console.log(err))
    .finally(() => setLoading(false));
  }

  // POST create user data API call
  const createUsers = () => {
    let copiedusersDataValues = { ...createUsersData };
    let body = {
      fullName: createUsersData.fullName.name,
      email: createUsersData.email.name,
      role: createUsersData.role.name,
      organizationId: organizationId,
      environment: environment,
    };

    if(copiedusersDataValues.role.name === ""){
      copiedusersDataValues.role.error = true;
      setCreateUsersData(copiedusersDataValues)
    }
    if(copiedusersDataValues.email.name === ""){
      copiedusersDataValues.email.error = true;
      setCreateUsersData(copiedusersDataValues)
    }
    if(copiedusersDataValues.fullName.name === ""){
      copiedusersDataValues.fullName.error = true;
      setCreateUsersData(copiedusersDataValues)
    }
    setModalLoading(true)
    userService
      .createUsersData(body, token)
      .then(resp => {
        if(resp){
          console.log("resp from user", resp);
          setShowModal(false);
          toast.success("User created successfully!");
          fetchAllUsersData(page);
          fetchInvitationPendingUsersData()
          // setCreateUsersData(CREATE_USER_DATA)
          resetAll()
        }
        else if (resp === 400) {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setModalLoading(false));
  };

  // delete api to remove invited user
  const fetchDeleteInvitedUser = () => {
    let body ={
      email: invitationPendingUserData[invitatedUserIdx].user?.email,
      organizationId: organizationId
    }
    userService
    .fetchDeleteInvitedUser(body, token)
    .then(resp => {
      if(resp){
        toast.success("Invited User Deleted Successfully!")
        fetchInvitationPendingUsersData()
        setShowDeleteUserModal(false)
      }
    })
    .catch((err) => console.log(err))
    .finally(() => {});
  }

  const resetAll = () => {
    setCreateUsersData({
      fullName: {
        name: "",
        error: false,
        type: "text",
        fullNameErrorMessage: "Full name should not be empty.",
        noWhiteSpaceError: false,
        noWhiteSpaceErrorMessage: "Extra space is not allowed.",
        acceptOnlyAlphabetsError: false,
        acceptOnlyAlphabetsErrorMessage: "Name should not includes any symbols or numbers" ,
      },
      email: {
        name: "",
        error: false,
        type: "email",
        emailErrorMessage: "Enter valid email Id.",
      },
      role: {
        name: "",
        error: false,
        roleErrorMessage: "Please select role."
      },
    })
  }

  const fetchUpdateUsersData = () => {
    let body = {
      // fullName: allUserDataList[manageUserIdx].user.fullName,
      // email: allUserDataList[manageUserIdx].user.email,
      Role: allUserDataList[manageUserIdx].user.Role,
      // IsMultiFactorAuthentication: allUserDataList[manageUserIdx].user.IsMultiFactorAuthentication,
      IsOrganizationOwner: allUserDataList[manageUserIdx]?.user?.IsOrganizationOwner === "YES" ? true : false,
      IsPHIAccess: allUserDataList[manageUserIdx].user.IsPHIAccess === "YES" ? true : false,
    }

    setModalLoading(true)
     userService
      .patchUpdateUsersInformation(allUserDataList[manageUserIdx].id, organizationId, body, token)
      .then(resp => {
        if(resp){
          setShowEditUserModal(false)
          fetchAllUsersData(page)
          toast.success(resp.value || "User updated successfully!")
        }
      })
      .catch(err => console.log(err))
      .finally(() =>  setModalLoading(false));
  }

  const onChangeHandler = ({ target: { value, name } }) => {
    let copiedusersDataValues = { ...createUsersData };
    copiedusersDataValues[name].name = value;
    
    if(name === "fullName"){
      if(noWhiteSpaceRegex.test(value)){
        copiedusersDataValues.fullName.noWhiteSpaceError = true
      }
      else {
        copiedusersDataValues.fullName.noWhiteSpaceError = false
      }

      if(onlyAlphabetsRegex.test(value)){
        copiedusersDataValues.fullName.aceeptOnlyAlphabetsError = false
      } else {
        copiedusersDataValues.fullName.aceeptOnlyAlphabetsError = true
      }
    }

    if (name === "email") {
      if (emailRegX.test(value)) {
        copiedusersDataValues.email.error = false;
      } else {
        copiedusersDataValues.email.error = true;
      }
    }

    if(name === "role") {
      if(value !== "") {
        copiedusersDataValues.role.error = false
      } else {
        copiedusersDataValues.role.error = true
      }
    }
    setCreateUsersData(copiedusersDataValues);
  };

  const onChangeUpdateUserHandler = ({ target: { value, name } }) => {
    let copiedManageUserValues = [ ...allUserDataList ];
    copiedManageUserValues[manageUserIdx]['user'][name] = value;
    setAllUserDataList(copiedManageUserValues)
  };
  return (
  <>
    {loading && <Loader/>}

    <div className="settings-container manageusers-container bg-[#ffffff] pt-[20px] pb-[10px] h-[calc(100vh_-_160px)]">
      <div className="flex flex-row justify-between pr-[20px] pl-[20px]">
        <h1 className="text-[30px] font-lato text-[#003344] xl:text-[20px]">
          Manage Users
        </h1>
        <button
          className="text-[16px] bg-[#076993] font-bold text-[#ffffff] p-[5px] w-[176px] h-[48px] rounded-[5px] xl:h-[36px] xl:w-[148px] xl:text-[12px]"
          onClick={() => setShowModal(true)}
        >
          ADD NEW USER
        </button>
        <CustomCenterModal
          saveButtonTitle={"INVITE"}
          // closeButtonTitle={""}
          openCenterModal={showModal}
          onCloseCenterModal={() => {setShowModal(false), resetAll()}}
          closeButtonHandler={() => {
            // setCreateUsersData(CREATE_USER_DATA),
            resetAll()
            setShowModal(false)}
          }
          className=""
          // saveButtonHandler={createUsers}
          loader={modalLoading}
          // saveBtnDisable={createOrgName === "" ? true : false} // true or false
        >
          <div className="">
            <p className="text-[#003344] text-[20px] font-bold font-lato">
              Add New User
            </p>
            <TextField
              name="fullName"
              label="Name"
              className="textinput-field w-full mt-[42px]"
              onChange={onChangeHandler}
              value={createUsersData.fullName.name ?? ""}
              error={createUsersData.fullName.error || createUsersData.fullName.noWhiteSpaceError || createUsersData.fullName.aceeptOnlyAlphabetsError}
              helperText={
                createUsersData["fullName"].error && createUsersData["fullName"].fullNameErrorMessage ||
                createUsersData["fullName"].noWhiteSpaceError && createUsersData["fullName"].noWhiteSpaceErrorMessage || 
                createUsersData["fullName"].aceeptOnlyAlphabetsError && createUsersData["fullName"].acceptOnlyAlphabetsErrorMessage
              }
            />

            <TextField
              name="email"
              label="Email Address"
              className="textinput-field w-full mt-[42px]"
              onChange={onChangeHandler}
              value={createUsersData.email.name ?? ""}
              error={createUsersData.email.error}
              helperText={createUsersData["email"].error && createUsersData["email"].emailErrorMessage}
            />

            <DropdownList
              name="role"
              dropdownName="role"
              list={ROLE}
              placeholder="ROLE"
              selectedvalue={createUsersData?.role?.name ?? ""}
              className="mt-[42px] h-[56px] rounded-[10px] w-[698px] xl:w-[558px] xl:h-[48px]"
              onChange={onChangeHandler}
              error={createUsersData.role.error}
              helperText={createUsersData.role.error && createUsersData["role"].roleErrorMessage}
            />
          </div>
        </CustomCenterModal>
      </div>
      <div className=" pt-[20px] pr-[20px]  xl:pt-[10px]">
        <Accordion className="MuiAccordion-root" >
          <AccordionSummary
            className="MuiAccordionSummary-root"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            // onClick={() => setIsViewInvitationsOpen(!isViewInvitationsOpen)}
          >
            <Typography className="MuiAccordionSummary-root text-[16px] font-lbold text-[#3A3A3A]">
              View Invitations
            </Typography>
          </AccordionSummary>
          {/* <AccordionDetails className="">
            {invitationPendingUserData.length ? invitationPendingUserData.map((_item, index) => (
              <div className="flex justify-between mt-[10px] mb-[10px]" key={index}>
                <div className="">
                  <p className="text-[20px] font-lthin text-[#3A3A3A] xl:text-[14px]">{_item?.user?.email || 'N/A'}</p>
                </div>
                <div className="flex">
                  <p className="pt-[3px] text-[20px] font-lthin text-[#3A3A3A] xl:text-[14px]">{_item?.verificationStatus || 'N/A'}</p>
                  <img src={deleteIcon} className="ml-[20px] xl:h-[20px] cursor-pointer" 
                    onClick={() => {setShowDeleteUserModal(true), setInvitatedUserIdx(index)}}
                  />
                </div>
              </div>
            )) : <p>No Pending Invitations</p>}
          </AccordionDetails> */}
        </Accordion>
      </div>

      <div className={"clear-right table-container " + `${isViewInvitationsOpen && 'view-invitation-active'}`}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                 <TableCell className="text-[#3A3A3A] font-lato font-bold  text-[20px] tracking-widest w-1/12 xl:text-[14px]">
                  ID
                </TableCell>
                <TableCell className="text-[#3A3A3A] font-lato font-bold  text-[20px] tracking-widest w-1/9 xl:text-[14px]">
                  Image
                </TableCell>
                <TableCell className="text-[#3A3A3A] font-lato font-bold  text-[20px] tracking-widest w-1/9 xl:text-[14px]">
                  Name
                </TableCell>
                <TableCell className="text-[#3A3A3A] font-lato font-bold  text-[20px] tracking-widest w-1/9 xl:text-[14px]">
                  Roles
                </TableCell>
                <TableCell className="text-[#3A3A3A] font-lato font-bold  text-[20px] tracking-widest w-1/9 xl:text-[14px]">
                  Email Id
                </TableCell>
                <TableCell className="text-[#3A3A3A] font-lato font-bold  text-[20px] tracking-widest w-1/9 xl:text-[14px]">
                  Multi-Factor Auth
                </TableCell>
                <TableCell className="text-[#3A3A3A] font-lato font-bold  text-[20px] tracking-widest w-1/9 xl:text-[14px]">
                  Organization Owner
                </TableCell>
                <TableCell className="text-[#3A3A3A] font-lato font-bold  text-[20px] tracking-widest w-1/9 xl:text-[14px]">
                  PHI Access
                </TableCell>
                <TableCell className="text-[#3A3A3A] font-lato font-bold  text-[20px] tracking-widest w-1/12 xl:text-[14px]"></TableCell>
              </TableRow>
            </TableHead>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => setToggleIndex(!toggleIndex)}
            >
              <TableBody>
                {allUserDataList.length !== 0 ? (
                  allUserDataList.map((_user, index) => (
                  <TableRow key={index}>
                    {/* <TableCell>{row.profilepic}</TableCell> */}
                    <TableCell className="text-[16px] font-lato text-[#3A3A3A] tracking-widest xl:text-[12px]">
                        {_user?.id ?_user?.id:"-"} 
                    </TableCell>
                    <TableCell className="text-[16px] font-lato text-[#3A3A3A] tracking-widest xl:text-[12px]">
                      <div className="flex justify-start items-center">
                        <figure className="w-[40px] h-[40px] shadow-[0px_3px_6px_#00000029] rounded-[5px]">
                          <img src={_user.userImageUrl ? _user?.userImageUrl : pIcon}
                            className="h-full w-full"
                          ></img>
                        </figure>
                       

                      </div>
                    </TableCell>
                    <TableCell className="text-[16px] font-lato text-[#3A3A3A] tracking-widest xl:text-[12px]">
                      {_user?.name ?_user?.name:""} 
                    </TableCell>
                    <TableCell className="text-[16px] font-lato text-[#3A3A3A] tracking-widest xl:text-[12px]">
                      {_user?.Role ? user?.Role : "-"}
                      
                    </TableCell>
                    <TableCell className="text-[16px] font-lato text-[#3A3A3A] tracking-widest xl:text-[12px]">
                      {_user?.email ? _user?.email : "-"}
                    </TableCell>
                    <TableCell className="text-[16px] font-lato text-[#3A3A3A] tracking-widest xl:text-[12px]">
                      {_user.IsMultiFactorAuthentication
                        ? "Enabled"
                        : "Not Enabled"}
                    </TableCell>
                    <TableCell className="text-[16px] font-lato text-[#3A3A3A] tracking-widest xl:text-[12px]">
                      {_user.user.IsOrganizationOwner ? "Yes" : "No"}
                    </TableCell>
                    <TableCell className="text-[16px] font-lato text-[#3A3A3A] tracking-widest xl:text-[12px]">
                      {_user.IsPHIAccess ? "Yes" : "No"}
                    </TableCell>
                      <TableCell
                        className="text-[16px] font-lato text-[#3A3A3A] tracking-widest xl:text-[12px]"
                        // onClick={() => {setToggleIndex(index);}}
                      >
                        <DotToggleButton
                          optionsData={optionsData}
                          showToggle={ toggleIndex === index ? true : false }
                          onSelect={() => {
                            setShowEditUserModal(true); 
                            setManageUserIdx(index);
                          }}
                        />
                      </TableCell>
                  </TableRow>
                ))) : <DataNotAvailable data={"User"}/>}
              </TableBody>
            </ClickAwayListener>
          </Table>
        </TableContainer>

        {totalCount > LIMIT && <div className="fixed bottom-[30px] right-[40px]">
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={calculateTotalPageCount(totalCount, LIMIT)}
            marginPagesDisplayed={4}
            pageRangeDisplayed={7}
            onPageChange={selectedItem => fetchAllUsersData(selectedItem?.selected + 1)}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>}

        <CustomCenterModal
          openCenterModal={showEditUserModal}
          onCloseCenterModal={() => setShowEditUserModal(false)}
          closeButtonHandler={() => setShowEditUserModal(false)}
          className=""
          saveButtonHandler={fetchUpdateUsersData}
          loader={modalLoading}
          saveBtnDisable={createUsersData === "" ? true  : false}
        >
          <p className="text-[#003344] text-[20px] font-bold font-lato xl:text-[14px]">
            Edit {allUserDataList[manageUserIdx]?.user?.fullName ?? 'N/A'} User
          </p>
          <div className="flex w-full">
            <div className="w-[20%] flex flex-col justify-around mt-[20px] xl:mt-[20px]">
              <span className="mt-[20px] items-center">Name</span>
              <span className="mt-[20px] items-center">Email</span>
              <span className="mt-[30px] items-center">Role</span>
              <span className="mt-[30px] items-center">Organization Owner</span>
              <span className="mt-[30px] items-center">PHI Access</span>
            </div>

            <div className="w-[80%]">
              <TextField
                name="fullName"
                // label="Name"
                // placeholder="Name"
                className="textinput-field w-full mt-[42px]"
                // onChange={onChangeUpdateUserHandler}
                value={allUserDataList[manageUserIdx]?.user?.fullName ?? 'N/A'}
              />

              <TextField
                name="email"
                // label="Email Address"
                placeholder="testuser@thinkitive.com"
                className="textinput-field w-full mt-[42px] xl:h-[48px]"
                // onChange={onChangeUpdateUserHandler}
                value={allUserDataList[manageUserIdx]?.user?.email ?? 'N/A'}
              />

              <DropdownList
                dropdownName="Role"
                list={ROLE}
                placeholder="ROLE"
                // onChange={(e) => setRoleList(e.target.value)}
                onChange={onChangeUpdateUserHandler}
                selectedvalue={allUserDataList[manageUserIdx]?.user?.Role ?? 'N/A'}
                className="mt-[42px] h-[56px] rounded-[10px] w-[558px] xl:w-[450px] xl:h-[48px]"
                // label="Role"
                />

              <DropdownList
                dropdownName="IsOrganizationOwner"
                list={ORG_OWNER}
                onChange={onChangeUpdateUserHandler}
                selectedvalue={allUserDataList[manageUserIdx]?.user?.IsOrganizationOwner}
                className="mt-[42px] h-[56px] rounded-[10px]  w-[558px]  xl:w-[450px] xl:h-[48px]"
                // label="Organization owner"
              />

              <DropdownList
                dropdownName="IsPHIAccess"
                list={PHI_ACCESS}
                onChange={onChangeUpdateUserHandler}
                selectedvalue={allUserDataList[manageUserIdx]?.user?.IsPHIAccess}
                className="mt-[42px] h-[56px] rounded-[10px]  w-[558px] xl:w-[450px] xl:h-[48px]"
              />
            </div>
          </div>
        </CustomCenterModal>

        <CustomCenterModal 
          saveButtonTitle={"YES"}
          closeButtonTitle={"NO"}
          openCenterModal={showDeleteUserModal}
          onCloseCenterModal={() => setShowDeleteUserModal(false)}
          closeButtonHandler={() => setShowDeleteUserModal(false)}
          className=""
          saveButtonHandler={fetchDeleteInvitedUser}
          loader={modalLoading}
        >
          <p className="text-[20px] font-lato font-bold">Are you sure you want to delete Invitation of {invitationPendingUserData[invitatedUserIdx]?.user?.email}?</p>
        </CustomCenterModal>
      </div>
    </div>
  </>
  );
};

export default ManageUsers;
