import React, { useEffect, useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import UserAuthHome from ".";
import { FormHelperText } from "@mui/material";
import CustomButton from "../../components/core/CustomButton";
import { useHistory } from "react-router-dom";
import { authService } from "../../services/authMX";
import { toast } from "react-toastify";
import Loader from "../../components/core/Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Footer from "../../components/core/Footer";
import { deepCopy } from "../../utils";
import { validateEmail } from "../../utils/ValidatationFunctions";
import {
  validateConfirmPassword,
  validatePassword,
  validateUsername,
} from "./Validations";
import { SIGN_UP_VALUES } from "../../services/initialValues";

const SignUp = () => {
  let history = useHistory();

  const [signUpValues, setSignUpValues] = useState(deepCopy(SIGN_UP_VALUES));
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    toast.dismiss();
  }, []);

  const signUpValueshandler = (event) => {
    const { name, value } = event.target;
    var copiedSignupValues = { ...signUpValues };
    copiedSignupValues[name].fieldValue = value;

    if (name === "emailId") {
      validateEmail("emailId", value, copiedSignupValues);
    }

    if (copiedSignupValues[name].id === "password") {
      validatePassword(value, copiedSignupValues);
    }
    if (copiedSignupValues[name].id === "confirmPassword") {
      validateConfirmPassword(value, copiedSignupValues);
    }

    if (name === "userName") {
      validateUsername(value, copiedSignupValues);
    }

    setSignUpValues(copiedSignupValues);
  };

  const handleClickShowPassword = () => {
    if (!signUpValues.password.fieldValue) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const handleClickShowConfirmPassword = () => {
    if (!signUpValues.confirmPassword.fieldValue) {
      setShowConfirmPassword(true);
    } else {
      setShowConfirmPassword(false);
    }
  };

  const fetchSignupAPI = () => {
    var copiedSignupValues = { ...signUpValues };

    if (copiedSignupValues.userName.fieldValue === "") {
      copiedSignupValues.userName.isError = true;
      copiedSignupValues.userName.errorMessage =
        "User Name Should Not be Empty";

      setSignUpValues(copiedSignupValues);
    }
    if (copiedSignupValues.emailId.fieldValue === "") {
      copiedSignupValues.emailId.isError = true;
      copiedSignupValues.emailId.errorMessage = "Email Should Not be Empty";
      setSignUpValues(copiedSignupValues);
    }

    if (copiedSignupValues.confirmPassword.fieldValue === "") {
      copiedSignupValues.confirmPassword.isError = true;
      copiedSignupValues.confirmPassword.errorMessage =
        "Confirm Password Should Not be Empty";
      setSignUpValues(copiedSignupValues);
    }

    if (copiedSignupValues.password.fieldValue === "") {
      copiedSignupValues.password.isError = true;
      setSignUpValues(copiedSignupValues);
    }

    if (
      !copiedSignupValues.userName.isError &&
      !copiedSignupValues.emailId.isError &&
      !copiedSignupValues.password.isError &&
      copiedSignupValues.password.charactersError &&
      copiedSignupValues.password.minMaxError &&
      !copiedSignupValues.confirmPassword.isError
    ) {
      setLoading(true);

      authService
        .fetchSignupData({
          username: signUpValues.userName?.fieldValue,
          email: signUpValues.emailId?.fieldValue,
          password1: signUpValues.password?.fieldValue,
          password2: signUpValues.confirmPassword?.fieldValue,
        })
        .then((resp) => {
          if (resp?.detail) {
            toast.success("Registerd Successfully!");
            history.push("/");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  const handleEnterkeyPress = (event) => {
    if (event.key === "Enter") {
      fetchSignupAPI();
    }
  };

  return (
    <div>
      {loading && <Loader />}

      <UserAuthHome>
        <div className="auth-block  2xl:mt-[40px] mb-[40px] xl:mt-[20px] lg:mt-[15px]">
          <h1 className="text-[50px] font-lato font-bold ml-[10px] xl:text-[40px] lg:text-[30px]">
            Sign Up
          </h1>
          <p className="text-[30px] font-normal mt-[12px] ml-[10px] xl:text-[20px] xl:mt-[8px] lg:text-[18px]">
            Already have an Account?{" "}
            <span
              className="text-cyan-600 cursor-pointer xl:text-[20px] xl:text-cyan-600 lg:text-[18px] xl:text-cyan-600"
              onClick={() => history.push("/")}
            >
              Sign In
            </span>
          </p>
          <div className="flex flex-col mt-[40px] ml-[10px] xl:mt-[28px] lg:mt-[15px]">
            <TextField
              name="userName"
              error={signUpValues.userName.isError}
              className="textinput-field"
              // id="outlined-basic"
              label="User Name"
              variant="outlined"
              type="text"
              onChange={signUpValueshandler}
              value={signUpValues.userName.fieldValue.trim() ?? ""}
              helperText={
                signUpValues.userName.isError &&
                signUpValues.userName.errorMessage
              }
            />

            <TextField
              name="emailId"
              className="textinput-field mt-[24px] xl:mt-[24px] lg:mt-[15px]"
              // id="outlined-basic"
              label="Email Id"
              variant="outlined"
              type="text"
              onChange={signUpValueshandler}
              value={signUpValues.emailId.fieldValue.trim() ?? ""}
              error={signUpValues.emailId.isError}
              helperText={
                signUpValues["emailId"].isError &&
                signUpValues["emailId"].errorMessage
              }
            />

            <FormControl
              sx={{ mt: 3 }}
              variant="outlined"
              className="textinput-field 2xl:mt-[24px] xl:mt-[24px] lg:mt-[15px]"
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                className={`${
                  signUpValues.password.emptyError ||
                  signUpValues.password.isError
                    ? "text-[#d32f2f]"
                    : ""
                }`}
              >
                Password
              </InputLabel>
              <OutlinedInput
                name="password"
                id="outlined-adornment-password1"
                type={showPassword ? "text" : "password"}
                value={signUpValues.password.fieldValue ?? ""}
                error={
                  signUpValues.password.emptyError ||
                  signUpValues.password.isError
                }
                helperText={signUpValues.password.emptyErrorMessage}
                onChange={signUpValueshandler}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={() => setShowPassword(true)}
                      edge="end"
                      className="p-0 mr-1"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {signUpValues.password.emptyError && (
                <FormHelperText error>
                  {signUpValues.password.emptyErrorMessage}
                </FormHelperText>
              )}
            </FormControl>

            <div className=" mt-[8px] text-[14px] lg:mt-[6px] lg:text-[12px] xl:text-[14px]">
              <p
                className={`${
                  signUpValues.password.charactersError
                    ? "text-[#4BB543]"
                    : " text-[#d32f2f]"
                }`}
              >
                {signUpValues.password.charactersError ? (
                  <DoneIcon />
                ) : (
                  <CloseIcon />
                )}
                {signUpValues.password.charactersErrorMessage}
              </p>

              <p
                className={`${
                  signUpValues.password.minMaxError
                    ? "text-[#4BB543]"
                    : " text-[#d32f2f]"
                }`}
              >
                {signUpValues.password.minMaxError ? (
                  <DoneIcon />
                ) : (
                  <CloseIcon />
                )}
                {signUpValues.password.minMaxErrorMessage}
              </p>
            </div>

            <FormControl
              sx={{ mt: 4 }}
              variant="outlined"
              className="textinput-field lg:mt-[8px] xl:mt-[10px]"
            >
              <InputLabel
                // sx={{marginLeft:0.5}}

                htmlFor="outlined-adornment-password"
                className={`${
                  signUpValues.confirmPassword.isError ? "text-[#d32f2f]" : ""
                }`}
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                name="confirmPassword"
                id="outlined-adornment-password2"
                type={showConfirmPassword ? "text" : "password"}
                error={signUpValues.confirmPassword.isError}
                value={signUpValues.confirmPassword.fieldValue}
                onChange={signUpValueshandler}
                onKeyDown={handleEnterkeyPress}
                onPaste={(event) => event.preventDefault()}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={() => setShowConfirmPassword(true)}
                      edge="end"
                      className="p-0 mr-1"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            {signUpValues.confirmPassword.isError && (
              <div>
                <p className="pw-error-msg">
                  {signUpValues.confirmPassword.errorMessage}
                </p>
              </div>
            )}

            {/* <div className="mt-[8px]">
              <p className={`${signUpValues.confirmPassword.error ? "text-[#00FF00]" : " text-[#d32f2f]"}`}>
                {signUpValues.confirmPassword.error ? (
                  <DoneIcon />
                ) : (
                  <CloseIcon />
                )}
                {signUpValues.confirmPassword.confirmPasswordErrorMessage}
              </p>
            </div> */}

            <div className="mt-[30px] xl:mt-[22px] lg:mt-[18px]">
              <CustomButton
                iconName="reply-right"
                text="Sign Up"
                className="filled-custom-btn text-[28px] w-full"
                onClick={fetchSignupAPI}
              />
            </div>
          </div>
        </div>
        <Footer
        // className={'2xl:bottom-[5px]'}
        />
      </UserAuthHome>
    </div>
  );
};

export default SignUp;
