export const ATHENA = [
  {
    type: "text",
    fieldName: "practice_id",
    label: "Practice ID",
    isReq: true,
    isError: false,
    errorMessage: "",
  },
];

export const NEXTGEN = [
  {
    type: "text",
    fieldName: "practice_id",
    label: "Practice ID",
    isReq: true,
    isError: false,
    errorMessage: "",
  },
  {
    type: "text",
    fieldName: "enterprise_id",
    label: "Enterprise ID",
    isReq: true,
    isError: false,
    errorMessage: "",
  },
];

export const ELATION_EMR = [
  {
    type: "text",
    fieldName: "practice_id",
    label: "Practice ID",
    isReq: true,
    isError: false,
    errorMessage: "",
  },
];
