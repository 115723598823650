export const ELATION_EMR_SYSTEM = [
  {
    type: "text",
    fieldName: "connection_name",
    label: "Name*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_id",
    label: "Client Id*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_secret",
    label: "Client Secret*",
    error: false,
    isReq: true,
  },
];
