import React, { useEffect, useState } from "react";
import "../../assets/styles/settings.scss";
import MultiSelectDropdown from "../../components/core/MultiSelectDropdown";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import uploadImg from "../../assets/images/img_upload.svg";
import CustomButton from "../../components/core/CustomButton";
import CustomCenterModal from "../../components/core/CustomCenterModal";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { organizationService } from "../../services/organizationMX";
import Loader from "../../components/core/Loader";
import { useHistory } from "react-router-dom";
import { OVERVIEW } from "../../utils/routeConstants";
import {
  getOrganizationDetails,
  imageType,
  ORG_CATEGORIES_LIST,
  covertPhNoFormat,
  formatNumber,
  reConvertPhoneNumber,
} from "../../utils";
import { OrgData } from "../../services/initialValues";
import { convertBase64 } from "../../utils/imageUtility";
import DropdownList from "../../components/core/DropdownList";
import {
  validateEmail,
  validatePhoneNumber,
  ValidateTwitterID,
  validateWebsiteURL,
} from "../../utils/ValidatationFunctions";
import { deepCopy, phoneNumberRegex } from "../../utils";
const envArray = ["sandbox", "stage", "production"];

const MODAL_STATES = {
  deleteOrgModal: false,
  showPhotoUploadModal: false,
  modalLoading: false,
};

const OrganizationProfile = () => {
  let history = useHistory();

  const organizationId = getOrganizationDetails();
  //Need to Remove showPhotoUploadModal State and Use Common modal_state instead
  const [showPhotoUploadModal, setshowPhotoUploadModal] = useState(false);

  const [modalState, setModalState] = useState({ ...MODAL_STATES });
  const [selectedOrgImage, setSelectedOrgImage] = useState(null);
  const [categoriesMultiSelectData, setCategoriesMultiSelectData] = useState(
    []
  );

  const [loading, setLoading] = useState(false);

  const [isImageValid, setIsImageValid] = useState(false);
  const [isImageSizeIsValid, setIsImageSizeIsValid] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const [orgData, setOrgData] = useState(deepCopy(OrgData));

  useEffect(() => {
    fetchOrganizationsByID();
  }, []);

  // GET org profile details
  const fetchOrganizationsByID = () => {
    setLoading(true);
    let copiedOrgData = { ...orgData };
    organizationService
      .getOrganizationsByID(organizationId)
      .then((resp) => {
        if (resp) {
          copiedOrgData.orgName.fieldValue = resp.name;
          copiedOrgData.phoneNumber.fieldValue = reConvertPhoneNumber(
            resp.phonenumber
          );
          copiedOrgData.alertEmail.fieldValue = resp.alertemail;
          copiedOrgData.salesEmail.fieldValue = resp.salesemail;
          copiedOrgData.twitter.fieldValue = resp.twitter;
          copiedOrgData.website.fieldValue = resp.website;
          copiedOrgData.orgType.fieldValue = resp.org_type;
          copiedOrgData.overview.fieldValue = resp.overview;
          copiedOrgData.logo = resp.logo;

          if (Array.isArray(resp?.category?.additionalProp1)) {
            setCategoriesMultiSelectData(resp?.category?.additionalProp1 || []);
          }
          localStorage.setItem("orgItem", JSON.stringify(resp));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  // Update org details
  const UpdateOrganizationData = () => {
    let copiedOrgData = { ...orgData };

    setModalState({ ...modalState, modalLoading: true });

    let reqBody = {
      name: orgData.orgName.fieldValue,
      alertemail: orgData?.alertEmail?.fieldValue,
      salesemail: orgData?.salesEmail?.fieldValue,
      phonenumber: covertPhNoFormat(orgData?.phoneNumber?.fieldValue),
      twitter: orgData?.twitter?.fieldValue,
      logo: orgData?.logo,
      website: orgData?.website?.fieldValue,
      overview: orgData?.overview?.fieldValue,
      org_type: orgData?.orgType?.fieldValue,
      category: {
        additionalProp1: categoriesMultiSelectData,
      },
    };
    if (
      !copiedOrgData.orgName.isError &&
      !copiedOrgData.alertEmail.isError &&
      !copiedOrgData.salesEmail.isError &&
      !copiedOrgData.phoneNumber.isError &&
      !copiedOrgData.twitter.isError &&
      !copiedOrgData.website.isError
    ) {
      setModalState({ ...modalState, modalLoading: true });
      organizationService
        .patchUpdateOrganizationInfoById(organizationId, reqBody)
        .then((resp) => {
          if (resp) {
            localStorage.setItem("orgItem", JSON.stringify(resp));
            history.push(OVERVIEW);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setModalState({ ...modalState, modalLoading: false }));
    }
  };

  // Update org profile image
  const updateProfileImage = async () => {
    const imgBase64 = await convertBase64(selectedOrgImage);

    const reqBody = {
      logo: {
        additionalProp1: imgBase64,
      },
    };
    setModalState({ ...modalState, modalLoading: true });
    organizationService
      .patchUpdateOrganizationInfoById(organizationId, reqBody)
      .then((resp) => {
        if (resp) {
          setshowPhotoUploadModal(false);
          setOrgData({ ...orgData, logo: resp.logo });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setModalState({ ...modalState, modalLoading: false }));
  };

  const onCategoryChangeHandler = (event) => {
    const {
      target: { value },
    } = event;
    setCategoriesMultiSelectData(
      typeof value === "string" ? value.split(",") : value
    );
  };

  // DELETE API to update org profile image
  const deleteOrganization = () => {
    setModalState({ ...modalState, modalLoading: true });
    organizationService
      .deleteOrganizationById(organizationId)
      .then((resp) => {
        history.push("/dashboard");
        sessionStorage.removeItem("orgItem");
        sessionStorage.removeItem("orgId");
      })
      .catch((err) => console.log(err))
      .finally(() => setModalState({ ...modalState, modalLoading: false }));
  };

  const orgInfoHandler = ({ target: { value, name } }) => {
    let copiedOrgData = { ...orgData };

    if (name === "name") {
      copiedOrgData.orgName.fieldValue = value;
      if (value === "") {
        copiedOrgData.orgName.isError = true;
        copiedOrgData.orgName.errorMessage =
          "Organization Name Should Not be Empty.";
      } else {
        copiedOrgData.orgName.isError = false;
      }
    }

    if (name === "alertemail") {
      copiedOrgData.alertEmail.fieldValue = value;
      validateEmail("alertEmail", value, copiedOrgData);
    }

    if (name === "salesemail") {
      copiedOrgData.salesEmail.fieldValue = value;
      validateEmail("salesEmail", value, copiedOrgData);
    }

    if (name === "phoneNumber") {
      if (value.length <= 14 && phoneNumberRegex.test(value)) {
        copiedOrgData.phoneNumber.fieldValue = formatNumber(value);
        setOrgData(copiedOrgData);
      }
      validatePhoneNumber(value, copiedOrgData);
    }

    if (name === "twitter") {
      copiedOrgData.twitter.fieldValue = value;
      ValidateTwitterID(value, copiedOrgData);
    }

    if (name === "website") {
      copiedOrgData.website.fieldValue = value;
      validateWebsiteURL(value, copiedOrgData);
    }
    if (name === "overview") {
      copiedOrgData.overview.fieldValue = value;
    }

    if (name === "org_type") {
      copiedOrgData.orgType.fieldValue = value;
    }
    setOrgData(copiedOrgData);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="orgcontainer">
        <div className="orgprofile-container ">
          <div className=" bg-[#ffffff] pt-[20px] pl-[20px] pb-[41px] shadow-[0px_3px_6px_#00000029] xl:pb-[20px]">
            <h1 className="font-lato text-[20px] text-[#3A3A3A] mb-[40px] xl:text-[16px] lg:text-[12px]">
              Organization Info
            </h1>
            <div
              className="w-[167px] h-[167px] bg-[#F2F2F2] flex flex-col items-center justify-center shadow-[0px_3px_6px_#00000029] cursor-pointer xl:h-[150px] xl: p-[10px]"
              onClick={() =>
                // setModalState({ ...modalState, showPhotoUploadModal: true })
                setshowPhotoUploadModal(true)
              }
            >
              <figure className="w-[150px] h-[140px]">
                <img
                  src={
                    !orgData?.logo?.additionalProp1
                      ? uploadImg
                      : orgData?.logo?.additionalProp1
                  }
                  className="w-full h-full"
                ></img>
              </figure>
              <span className="text-[16px] font-lato text-[#3a3a3a] cursor-pointer">
                {!orgData?.logo?.additionalProp1 && "Click to upload logo"}
              </span>
            </div>

            <div className="mt-[43px] xl:mr-[50px] xl:mt-[20px]">
              <div className="flex items-center">
                <span className="text-[16px] font-lato text-[#3A3A3A] w-[30%] xl:text-[14px]">
                  Name <span className="text-[#D20404]">&#42;</span>
                </span>
                <TextField
                  name="name"
                  className="textinput-field w-[60%]"
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  onChange={orgInfoHandler}
                  value={orgData?.orgName?.fieldValue ?? ""}
                  error={orgData?.orgName?.isError}
                  helperText={
                    orgData["orgName"]?.isError &&
                    orgData["orgName"]?.errorMessage
                  }
                  placeholder="Organization Name"
                  required
                />
              </div>

              <div className="flex items-center  mt-[40px]">
                <span className="text-[16px] font-lato text-[#3A3A3A] w-[30%] xl:text-[14px]">
                  Alert Email <span className="text-[#D20404]">&#42;</span>
                </span>
                <TextField
                  name="alertemail"
                  className="textinput-field w-[60%] text-[#A7A1A1] "
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  onChange={orgInfoHandler}
                  value={orgData?.alertEmail?.fieldValue ?? ""}
                  error={orgData.alertEmail.isError}
                  helperText={
                    orgData["alertEmail"].isError &&
                    orgData["alertEmail"].errorMessage
                  }
                  placeholder="Enter email address"
                  required
                />
              </div>

              <div className="flex items-center  mt-[40px]">
                <span className="text-[16px] font-lato text-[#3A3A3A] w-[30%] xl:text-[14px]">
                  Overview
                </span>

                <TextareaAutosize
                  name="overview"
                  aria-label="empty textarea"
                  placeholder="Enter your overview"
                  className="w-[60%] border-[1px] py-[16.5px] px-[12px] text-[#3a3a3a]  textarea h-[169px]"
                  minRows={4}
                  style={{
                    width: 200,
                    borderRadius: 10,
                    fontFamily: "lato",
                    fontSize: 20,
                    padding: 1,
                    color: "black",
                  }}
                  onChange={orgInfoHandler}
                  value={orgData?.overview?.fieldValue ?? ""}
                />
              </div>

              <div className="flex items-center mt-[42px]">
                <span className="text-[16px] font-lato text-[#3A3A3A] w-[30%] xl:text-[14px]">
                  Sales Email <span className="text-[#D20404]">&#42;</span>
                </span>
                <TextField
                  name="salesemail"
                  className="textinput-field w-[60%] text-[#A7A1A1] "
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  onChange={orgInfoHandler}
                  value={orgData?.salesEmail?.fieldValue ?? ""}
                  error={orgData.salesEmail.isError}
                  helperText={
                    orgData["salesEmail"].isError &&
                    orgData["salesEmail"].errorMessage
                  }
                  placeholder="Enter email address"
                  required
                />
              </div>

              <div className="flex items-center mt-[20px]">
                <span className="text-[16px] font-lato text-[#3A3A3A] w-[30%] xl:text-[14px]">
                  Phone <span className="text-[#D20404]">&#42;</span>
                </span>
                <TextField
                  name="phoneNumber"
                  className="textinput-field w-[60%]"
                  variant="outlined"
                  label="Phone"
                  type="text"
                  onChange={orgInfoHandler}
                  value={orgData?.phoneNumber?.fieldValue ?? ""}
                  error={orgData?.phoneNumber?.isError}
                  helperText={
                    orgData["phoneNumber"]?.isError &&
                    orgData["phoneNumber"]?.errorMessage
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="flex items-center mt-[20px]">
                <span className="text-[16px] font-lato text-[#3A3A3A] w-[30%] xl:text-[14px]">
                  Website{" "}
                </span>
                <TextField
                  name="website"
                  className="textinput-field w-[60%] text-[#A7A1A1] "
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  onChange={orgInfoHandler}
                  value={orgData?.website?.fieldValue ?? ""}
                  error={orgData?.website?.isError}
                  helperText={
                    orgData["website"]?.isError &&
                    orgData["website"]?.errorMessage
                  }
                  placeholder="Website URL"
                  required
                />
              </div>
              <div className="flex items-center mt-[20px]">
                <span className="text-[16px] font-lato text-[#3A3A3A] w-[30%] xl:text-[14px]">
                  Twitter
                </span>
                <TextField
                  name="twitter"
                  className="textinput-field w-[60%] text-[#A7A1A1] "
                  id="outlined-basic"
                  //  label="Name"
                  variant="outlined"
                  type="text"
                  onChange={orgInfoHandler}
                  value={orgData?.twitter?.fieldValue ?? ""}
                  error={orgData["twitter"]?.isError}
                  helperText={
                    orgData["twitter"]?.isError &&
                    orgData["twitter"]?.errorMessage
                  }
                  placeholder="Enter twitter handle"
                  required
                />
              </div>

              <div className="flex items-center mt-[40px] ">
                <span className="text-[16px] font-lato text-[#3A3A3A] w-[30%] xl:text-[14px] ">
                  Env type <span className="text-[#D20404]">&#42;</span>
                </span>

                <DropdownList
                  dropdownName="org_type"
                  className="organization-media rounded-[10px]"
                  list={envArray}
                  onChange={orgInfoHandler}
                  selectedvalue={orgData?.orgType?.fieldValue || ""}
                  labelId="Select env"
                  helperText={
                    orgData["orgType"]?.isError &&
                    orgData["orgType"]?.envValueErrorMessage
                  }
                  error={orgData["orgType"]?.isError}
                />
              </div>
              <div className="flex items-center mt-[40px] ">
                <span className="text-[16px] font-lato text-[#3A3A3A] w-[30%] xl:text-[14px]">
                  Categories
                </span>

                <MultiSelectDropdown
                  dropdownName="select-category"
                  className="organization-media rounded-[10px]"
                  list={ORG_CATEGORIES_LIST}
                  onChange={onCategoryChangeHandler}
                  selectedvalue={categoriesMultiSelectData}
                />
              </div>
            </div>

            <div className="w-[90%] xl:w-[85%]">
              <div className="flex justify-end mt-[20px]">
                <CustomButton
                  iconName="reply-right"
                  text="SAVE"
                  className="filled-custom-btn rounded-[5px] text-[20px] font-lato w-[165px] h-[56px] xl:w-[120px] xl:h-[42px] lg:w-[100px] lg:h-[38px]"
                  onClick={UpdateOrganizationData}
                />
              </div>
            </div>
          </div>

          <div className="mt-[30px] bg-[#ffffff] shadow-[0px_3px_6px_#00000029] pl-[20px] pt-[20px] pb-[20px]">
            <h1 className="text-[20px] font-lato text-[#3a3a3a] xl:text-[16px]">
              Settings
            </h1>
            <div className="flex justify-between mt-[20px] items-center">
              <span className="text-[16px] font-lato text-[#3A3A3A] xl:text-[14px]">
                Delete Organization
              </span>
              <CustomButton
                iconName="reply-right"
                text="DELETE"
                className="filled-custom-btn rounded-[5px] text-[20px] font-lato w-[165px] h-[56px] mr-[20px] xl:w-[120px] xl:h-[42px] lg:w-[100px] lg:h-[38px]"
                onClick={() =>
                  setModalState({ ...modalState, deleteOrgModal: true })
                }
              />
            </div>
          </div>
        </div>

        <CustomCenterModal
          saveButtonTitle={"DELETE"}
          openCenterModal={modalState.deleteOrgModal}
          onCloseCenterModal={() => {
            setModalState({ ...modalState, deleteOrgModal: false });
          }}
          closeButtonHandler={() => {
            setModalState({ ...modalState, deleteOrgModal: false });
          }}
          className=""
          saveButtonHandler={deleteOrganization}
          loader={modalState.modalLoading}
        >
          <div className="">
            <p className="text-[#003344] text-[20px] font-bold font-lato">
              Delete Organization
            </p>
            <p className="mt-[19px] text-lato text-[20px] text-[3a3a3a]">
              Are you sure you want to delete this organization? This action
              cannot be undone.
            </p>
            <p className="mt-[20px] text-lato text-[20px] text-[3a3a3a]">
              Deleting an organization will cause the following to occur:
            </p>

            <ul className="list-disc ml-[30px] mt-[11px]">
              <li className="text-lato text-[20px] text-[3a3a3a]">
                All users will be removed from the organization.
              </li>
              <li className="text-lato text-[20px] text-[3a3a3a]">
                All connections to and from this organization will be deleted.
              </li>
            </ul>
          </div>
        </CustomCenterModal>

        <CustomCenterModal
          // setShowButtons={true}
          // saveButtonTitle={"YES"}
          // closeButtonTitle={"NO"}
          openCenterModal={showPhotoUploadModal}
          // onCloseCenterModal={() => {
          //   setShowUploadImgModal(false), setIsImageSizeIsValid(false);
          // }}
          closeButtonHandler={() => {
            setshowPhotoUploadModal(false),
              // setModalState({ ...modalState, showPhotoUploadModal: false });
              setSelectedOrgImage(null),
              setIsImageValid(false),
              setIsImageSizeIsValid(false);
          }}
          className=""
          saveButtonHandler={updateProfileImage}
          saveBtnDisable={
            !isImageValid && !isImageSizeIsValid && isImageSelected === true
              ? false
              : true
          }
          loader={modalState.modalLoading}
        >
          <div className="">
            <p className="text-[#003344] text-[20px] font-bold font-lato">
              Upload Logo
            </p>
            <p className="mt-[19px] text-lato text-[20px] text-[3a3a3a]">
              Select an image to upload as your organization's icon (500 KB max)
            </p>
            <p className="mt-[20px] text-lato text-[20px] text-[3a3a3a]">
              After uploading, your icon will:
            </p>

            <ul className="list-disc ml-[30px] mt-[11px]">
              <li className="text-lato text-[20px] text-[3a3a3a]">
                Be automatically resized
              </li>
              <li className="text-lato text-[20px] text-[3a3a3a]">
                Have a white background applied it necessary
              </li>
            </ul>

            <TextField
              type="file"
              className="cursor-pointer mt-[33px] "
              onChange={(event) => {
                if (window.FileReader) {
                  const imgtype = event.target.files[0]?.name;
                  const imageTypeCheck = imageType.includes(
                    imgtype.substring(imgtype.lastIndexOf(".") + 1)
                  );

                  if (imageTypeCheck) {
                    setIsImageValid(false);
                    setIsImageSelected(true);
                    if (event.target.files[0].size > 500000) {
                      setIsImageSizeIsValid(true);
                    } else {
                      setIsImageSizeIsValid(false);
                      setSelectedOrgImage(event.target.files[0]);
                    }
                  } else {
                    setIsImageValid(true);
                  }
                }
              }}
              error={isImageValid || isImageSizeIsValid}
              helperText={
                (isImageValid && "please select valid image") ||
                (isImageSizeIsValid && "file should be less than 500kb")
              }
            />
          </div>
        </CustomCenterModal>
      </div>
    </>
  );
};

export default OrganizationProfile;
