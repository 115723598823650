import React, { useEffect, useState } from "react";
import { webHookService } from "../../services/webhookMX";
import { calculateTotalPageCount, getOrganizationDetails } from "../../utils";
import AppLayout from "../../components/layout/AppLayout";
import { Tooltip } from "@mui/material";
import addOrg from "../../assets/images/addOrg.png";
import { WEBHOOK } from "../../utils/routeConstants";
import { useHistory } from "react-router-dom";
import DataNotAvailable from "../../components/core/DataNotAvailable";
import DashBoardOrganizations from "../../components/Organization/OrganizationItem";
import ReactPaginate from "react-paginate";
import Search from "../../components/core/Search";
import Loader from "../../components/core/Loader";

function WebHookDashboard() {
  const [webHooks, setWebHooks] = useState();
  const [totalWebHook, setTotalWebHook] = useState();
  const [loading, setLoading] = useState(false);
  const orgId = getOrganizationDetails();
  const LIMIT = 12;
  const history = useHistory();

  useEffect(() => {
    getWebHooks(1);
  }, []);

  const getWebHooks = (page) => {
    setLoading(true);
    webHookService
      .getWebHooksByOrgId(orgId, page, LIMIT)
      .then((res) => {
        if (res) {
          setWebHooks(res.results);
          setTotalWebHook(res.count);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // function deleteWebHook(webHookId) {
  //   webHookService
  //     .deleteWebHook(orgId, webHookId)
  //     .then((res) => {
  //       if (res) getWebHooks(1);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  return (
    <AppLayout>
      {loading && <Loader />}
      <section className="connection-container bg-[#EDF6F9] pl-[40px] pt-[10px] ">
        <div>
          <div className="flex flex-row   ">
            <h2 className="text-[40px] mt-[1%] font-lato text-[#003344] xl:text-[30px] lg:text-[30px]">
              Webhooks
            </h2>
            <Search
              top="right-[145px]"
              right="top-[35px]"
              page="webhook"
              parentSetState={setWebHooks}
            />
            <Tooltip title="Create New WebhooK" arrow placement="top">
              <div
                className="create-btn"
                onClick={() => history.push(WEBHOOK.CREATE)}
              >
                <button>
                  <img src={addOrg} />
                </button>
              </div>
            </Tooltip>
          </div>
          <div className="connection-list-container">
            <div className="flex flex-wrap mt-[2%]">
              {webHooks?.length ? (
                webHooks.map((item, index) => (
                  <DashBoardOrganizations
                    key={index}
                    webHook={item}
                    // deleteWebHook={deleteWebHook}
                    updateWebHook={WEBHOOK.UPDATE}
                  />
                ))
              ) : (
                <DataNotAvailable data={"Webhooks"} className="" />
              )}
            </div>
          </div>
        </div>
        {totalWebHook > LIMIT && (
          <div className="flex flex-row justify-end mr-[3%]">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              // marginPagesDisplayed={4}
              // pageRangeDisplayed={7}
              pageCount={calculateTotalPageCount(totalWebHook, LIMIT)}
              onPageChange={(selectedItem) =>
                getWebHooks(selectedItem?.selected + 1)
              }
            />
          </div>
        )}
      </section>
    </AppLayout>
  );
}

export default WebHookDashboard;
