import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LogoIcon from "../../assets/images/blueLogo.svg";

import { authService } from "../../services/authMX";
import Loader from "../../components/core/Loader";
import BackgroundImage from "../../assets/images/authBackgroundImg.jpg";
import { getInnerHeightForImage } from "../../utils";
import { HOME } from "../../utils/routeConstants";

const VerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      fetchVerifyEmailAPI(token);
    }
  }, []);

  const fetchVerifyEmailAPI = (token) => {
    setLoading(false);
    authService
      .fetchVerifyEmailData({ key: token })
      .then((resp) => {
        if (resp) {
          setTimeout(() => {
            history.push(HOME);
          }, "3000");

          setLoading(true);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {loading && <Loader />}
      <div className="RE-container">
        <div className="RE-user-auth-container flex flex-row justify-between">
          <div className="auth-section basis-1/2">
            <img
              src={LogoIcon}
              className="w-[70%] h-[20%] xl:w-[280.5px] xl:h-[96px] lg:h-[80px] lg:w-[250px]"
            />

            <div className="auth-block  my-[20px] xl:my-[20px] lg:my-[20px]">
              <h1 className="text-[50px] font-lato font-bold ml-[10px] lg:text-[30px] xl:text-[40px]">
                Verifying...
              </h1>
              <p className="text-[30px] font-lato mt-[11px] ml-[10px] lg:text-[18px] xl:text-[20px]">
                Sign in here to get started ?{" "}
                <span
                  className="text-cyan-600 cursor-pointer font-lato text-[30px] lg:text-[18px] lg:text-cyan-600 xl:text-[20px]"
                  onClick={() => history.push(HOME)}
                >
                  Sign In
                </span>
              </p>
            </div>
          </div>
          <div className="bgImg">
            <img
              src={BackgroundImage}
              style={{ height: getInnerHeightForImage() }}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
