import axios from "axios";
import { toast } from "react-toastify";
import { toastId } from "../utils";
import {
  POST_LOGIN,
  POST_REGISTER,
  POST_FORGOT,
  POST_RESET,
  POST_VERIFYEMAIL,
  POST_REFRESH_TOKEN,
  PUT_CHANGE_USERS_PASSWORD,
  GET_CURRENT_USER,
  PUT_UPDATE_USERS_DATA,
} from "./endpoints";
import { getTokenDetails } from "../utils";

export const authService = {
  // login
  // fetchLoginData: (data) =>
  //   axios
  //     .post(POST_LOGIN.endpoint, data)
  //     .then((resp) => resp)
  //     .catch((err) => {
  //       console.log(err);
  //     }),
  fetchLoginData: async (data) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(POST_LOGIN.endpoint, options);
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }

    return response;
  },

  // signup
  // fetchSignupData: (data) =>
  //   axios
  //     .post(POST_REGISTER.endpoint, data)
  //     .then((resp) => resp)
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  fetchSignupData: async (data) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(POST_REGISTER.endpoint, options);
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg[0]][0]);
    }
    return response;
  },

  // forgot password
  // fetchForgotPasswordData: (data) =>
  //   axios
  //     .post(POST_FORGOT.endpoint, data)
  //     .then((resp) => {
  //       toast.success("Password reset e-mail has been sent", {
  //         toastId,
  //       });
  //       return resp;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  fetchForgotPasswordData: async (data) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(POST_FORGOT.endpoint, options);
      toast.success("Password reset e-mail has been sent", {
        toastId,
      });
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // reset password
  // fetchResetPasswordData: (data) =>
  //   axios
  //     .post(POST_RESET.endpoint, data)
  //     .then((resp) => {
  //       if (resp?.data) {
  //         toast.success("Password reset successfully, please login!", {
  //           toastId,
  //         });
  //         return resp.data;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  fetchResetPasswordData: async (data) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(POST_RESET.endpoint, options);
      toast.success("Password reset successfully, please login!", {
        toastId,
      });
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Verify Email
  // fetchVerifyEmailData: (data) =>
  //   axios
  //     .post(POST_VERIFYEMAIL.endpoint, data)
  //     .then((resp) => {
  //       if (resp) {
  //         toast.success("Email verified successfully, Please login!", {
  //           toastId,
  //         });
  //       }
  //       return resp;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  fetchVerifyEmailData: async (data) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(POST_VERIFYEMAIL.endpoint, options);
      toast.success("Email verified successfully, Please login!", {
        toastId,
      });
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Refresh Token
  // fetchRefreshTokenData: (data) =>
  //   axios
  //     .post(POST_REFRESH_TOKEN.endpoint, data)
  //     .then((resp) => resp)
  //     .catch((err) => {
  //       console.log(err);
  //     }),

  fetchRefreshTokenData: async (data) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(POST_REFRESH_TOKEN.endpoint, options);
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Change Password
  // putUpdateUsersPassword: (data, token) =>
  //   axios
  //     .post(PUT_CHANGE_USERS_PASSWORD.endpoint, data)
  //     .then((resp) => {
  //       if (
  //         (resp?.data?.detail === "New password has been saved.", { toastId })
  //       )
  //         toast.success("Password changed successfully");
  //       return resp;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     }),
  putUpdateUsersPassword: async (data) => {
    let response;

    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(PUT_CHANGE_USERS_PASSWORD.endpoint, options);
      response = await response.json();
      if ((response?.detail === "New password has been saved.", { toastId }))
        toast.success("Password changed successfully");
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Get Current User Data
  // fetchCurrentUsersData: () =>
  //   axios
  //     .get(GET_CURRENT_USER.endpoint)
  //     .then((resp) => resp)
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),
  fetchCurrentUsersData: async () => {
    let response;
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(GET_CURRENT_USER.endpoint, options);
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },

  // Update Current User Data
  // putUpdateUsersData: (data) =>
  //   axios
  //     .put(PUT_UPDATE_USERS_DATA.endpoint, data)
  //     .then((resp) => {
  //       if (resp?.data) toast.success("User updated Successfully", { toastId });
  //       return resp;
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     }),

  putUpdateUsersData: async (data) => {
    let response;

    let options = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      response = await fetch(PUT_UPDATE_USERS_DATA.endpoint, options);
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },
};
