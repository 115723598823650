import { TextField } from "@mui/material";
// import { Multiselect } from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { organizationService } from "../../services/organizationMX";
import { OrgData } from "../../services/initialValues";
import { ORG_CATEGORIES_LIST, covertPhNoFormat, deepCopy } from "../../utils";
import CustomCenterModal from "../core/CustomCenterModal";
import MultiSelectDropdown from "../core/MultiSelectDropdown";
import DropdownList from "../core/DropdownList";
import InputAdornment from "@mui/material/InputAdornment";

import {
  validateEmail,
  validatePhoneNumber,
} from "../../utils/ValidatationFunctions";

import { formatNumber, phoneNumberRegex } from "../../utils";

const envArray = ["sandbox", "stage", "production"];
const CreateOrganizationModal = ({
  openCenterModal,
  closeCenterModal,
  getOrganisationAPICall,
}) => {
  const [categoriesMultiSelectData, setCategoriesMultiSelectData] = useState(
    []
  );

  useEffect(() => {
    resetAll();
  }, []);

  const [createOrgData, setCreateOrgData] = useState(deepCopy(OrgData));

  const [isCreatOrgDataIsValid, setIsCreateOrgDataIsValid] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const CreateOrganization = () => {
    let reqBody = {
      name: createOrgData.orgName.fieldValue,
      alertemail: createOrgData.alertEmail.fieldValue,
      salesemail: createOrgData.salesEmail.fieldValue,
      phonenumber: covertPhNoFormat(createOrgData.phoneNumber.fieldValue),
      org_type: createOrgData.envValue.fieldValue,
      category: {
        additionalProp1: categoriesMultiSelectData,
      },
    };

    setModalLoading(true);
    organizationService
      .createOrganizations(reqBody)
      .then((resp) => {
        if (resp) {
          closeCenterModal(false);
          getOrganisationAPICall();
          resetAll();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setModalLoading(false));
  };

  const onCreateOrgDataChangeHandler = (event) => {
    const { name, value } = event.target;

    var copiedCreateDataValues = deepCopy(createOrgData);

    if (name === "alertEmail") {
      validateEmail("alertEmail", value, copiedCreateDataValues);

      copiedCreateDataValues.alertEmail.fieldValue = value;
    }

    if (name === "salesEmail") {
      validateEmail("salesEmail", value, copiedCreateDataValues);

      copiedCreateDataValues.salesEmail.fieldValue = value;
    }

    if (name === "orgName") {
      if (value !== "") {
        copiedCreateDataValues.orgName.isError = false;
      } else {
        copiedCreateDataValues.orgName.isError = true;
      }

      copiedCreateDataValues.orgName.fieldValue = value;
    }

    if (name === "phoneNumber") {
      validatePhoneNumber(value, copiedCreateDataValues);

      if (value.length <= 14 && phoneNumberRegex.test(value)) {
        copiedCreateDataValues.phoneNumber.fieldValue = formatNumber(value);
        setCreateOrgData(copiedCreateDataValues);
      }
    }

    if (name === "envValue") {
      if (value !== "") {
        copiedCreateDataValues.envValue.isError = false;
      } else {
        copiedCreateDataValues.envValue.isError = true;
      }
      copiedCreateDataValues.envValue.fieldValue = value;
    }

    setCreateOrgData(copiedCreateDataValues);

    if (
      copiedCreateDataValues.orgName.fieldValue !== "" &&
      copiedCreateDataValues.phoneNumber.fieldValue !== "" &&
      copiedCreateDataValues.alertEmail.fieldValue !== "" &&
      copiedCreateDataValues.salesEmail.fieldValue !== "" &&
      copiedCreateDataValues.envValue.fieldValue !== ""
    ) {
      if (
        !copiedCreateDataValues.orgName.isError &&
        !copiedCreateDataValues.salesEmail.isError &&
        !copiedCreateDataValues.alertEmail.isError &&
        !copiedCreateDataValues.phoneNumber.isError &&
        !copiedCreateDataValues.envValue.isError
      ) {
        setIsCreateOrgDataIsValid(true);
      } else {
        setIsCreateOrgDataIsValid(false);
      }
    } else {
      setIsCreateOrgDataIsValid(false);
    }
  };

  const onCategoryChangeHandler = (event) => {
    const {
      target: { value },
    } = event;
    setCategoriesMultiSelectData(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const resetAll = () => {
    setIsCreateOrgDataIsValid(false);
    setCategoriesMultiSelectData([]);
    let copiedCreateData = { ...createOrgData };

    copiedCreateData.orgName.fieldValue = "";
    copiedCreateData.orgName.isError = false;
    copiedCreateData.alertEmail.fieldValue = "";
    copiedCreateData.alertEmail.isError = false;
    copiedCreateData.salesEmail.fieldValue = "";
    copiedCreateData.salesEmail.isError = false;
    copiedCreateData.phoneNumber.fieldValue = "";
    copiedCreateData.phoneNumber.isError = false;
    copiedCreateData.envValue.fieldValue = "";
  };
  const closeModalHandler = () => {
    closeCenterModal(false);
    setIsCreateOrgDataIsValid(false);
    setCategoriesMultiSelectData([]);
    resetAll();
  };

  return (
    <CustomCenterModal
      openCenterModal={openCenterModal}
      onCloseCenterModal={closeModalHandler}
      closeButtonHandler={closeModalHandler}
      className=""
      saveButtonHandler={CreateOrganization}
      saveBtnDisable={!isCreatOrgDataIsValid === true ? true : false}
      loader={modalLoading}
    >
      <div className="">
        <p className="text-[#003344] text-[20px] font-bold font-lato">
          Create Organization
        </p>
        <TextField
          type="text"
          name="orgName"
          className="textinput-field w-full mt-[20px]"
          autoComplete="off"
          onChange={onCreateOrgDataChangeHandler}
          value={createOrgData?.orgName?.fieldValue ?? ""}
          label="Name"
          error={createOrgData?.orgName?.isError}
          helperText={
            createOrgData["orgName"]?.isError &&
            createOrgData["orgName"]?.errorMessage
          }
        />

        <TextField
          type="email"
          name="alertEmail"
          className="textinput-field w-full mt-[20px]"
          autoComplete="off"
          onChange={onCreateOrgDataChangeHandler}
          value={createOrgData?.alertEmail?.fieldValue ?? ""}
          label="Alert Email"
          error={createOrgData?.alertEmail?.isError}
          helperText={
            createOrgData["alertEmail"]?.isError &&
            createOrgData["alertEmail"]?.errorMessage
          }
        />
        <TextField
          type="email"
          name="salesEmail"
          className="textinput-field w-full mt-[20px]"
          autoComplete="off"
          onChange={onCreateOrgDataChangeHandler}
          value={createOrgData?.salesEmail?.fieldValue ?? ""}
          label="Sales Email"
          error={createOrgData?.salesEmail?.isError}
          helperText={
            createOrgData["salesEmail"]?.isError &&
            createOrgData["salesEmail"]?.errorMessage
          }
        />

        <TextField
          name="phoneNumber"
          label="Phone"
          className="textinput-field w-full mt-[20px]"
          variant="outlined"
          type="text"
          onChange={onCreateOrgDataChangeHandler}
          value={createOrgData?.phoneNumber?.fieldValue ?? ""}
          error={createOrgData?.phoneNumber?.isError}
          helperText={
            createOrgData["phoneNumber"]?.isError &&
            createOrgData["phoneNumber"]?.errorMessage
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+1</InputAdornment>
            ),
          }}
        />
        <div className=" mt-[20px] w-[full] h-[56px] max-h-[56px]">
          <DropdownList
            dropdownName="envValue"
            className="textinput-field w-[698px] h-[56px] rounded-[10px] mr-[50px]"
            list={envArray}
            onChange={onCreateOrgDataChangeHandler}
            selectedvalue={createOrgData?.envValue?.fieldValue || ""}
            label="Select env"
            labelId="Select env"
            helperText={
              createOrgData["envValue"]?.isError &&
              createOrgData["envValue"]?.envValueErrorMessage
            }
            error={createOrgData["envValue"]?.isError}
          />
        </div>
        <div
          id="multiselect-div"
          className="org-pro-categories-multiselect-block mt-[20px] w-full h-[56px] max-h-[56px]"
        >
          <MultiSelectDropdown
            dropdownName="select-category"
            className="textinput-field w-[698px] h-[56px] rounded-[10px] mr-[50px]"
            list={ORG_CATEGORIES_LIST}
            label="Select Category"
            onChange={onCategoryChangeHandler}
            selectedvalue={categoriesMultiSelectData}
          />
        </div>
      </div>
    </CustomCenterModal>
  );
};

export default CreateOrganizationModal;
