export const OPEN_EMR_SYSTEM = [
  {
    type: "text",
    fieldName: "connection_name",
    label: "Name*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_id",
    label: "Client Id*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "scope",
    label: "Scope*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "access_token",
    label: "Access Token*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "refresh_token",
    label: "Refresh Token*",
    error: false,
    isReq: true,
  },
];
