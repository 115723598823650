import { getTokenDetails, logOutHandler } from ".";
import { HOME } from "./routeConstants";

function UserLogOut({ children }) {
  const token = getTokenDetails();

  if (token && window.location.pathname === HOME) logOutHandler();

  return children;
}

export default UserLogOut;
