import LogoIcon from "../../assets/images/blueLogo.svg";

import "../../assets/styles/dashboard.scss";
import "../../assets/styles/common.scss";
import { Button } from "@mui/material";
import { logOutHandler } from "../../utils";
import { useHistory } from "react-router-dom";
import SignoutIcon from "../../assets/images/signout_icon.svg";
import { useState } from "react";
import CustomCenterModal from "../../components/core/CustomCenterModal";

const DashboardHeader = () => {
  const [showSignoutModal, setShowSignoutModal] = useState(false);
  let history = useHistory();

  return (
    <>
      {" "}
      <div className="dashboard-logo-container flex justify-between">
        <img src={LogoIcon} className="dashboard-logo" />

        <button
          className=" bg-[#076993] w-[166px] mt-[3px] h-[48px] text-[20px] font-bold hover: text-[#ffffff] rounded 
          xl:w-[120px] xl:h-[32px] lg:w-[100px] lg:h-[30px]"
          onClick={() => setShowSignoutModal(true)}
        >
          <img
            src={SignoutIcon}
            className="inline cursor-pointer"
            onClick={() => setShowSignoutModal(true)}
          />
          Sign Out
        </button>
      </div>
      <CustomCenterModal
        saveButtonTitle={"YES"}
        closeButtonTitle={"NO"}
        openCenterModal={showSignoutModal}
        onCloseCenterModal={() => setShowSignoutModal(false)}
        closeButtonHandler={() => setShowSignoutModal(false)}
        className=""
        saveButtonHandler={() => {
          logOutHandler();
          history.push("/");
        }}
      >
        <p className="text-[20px] font-lato font-bold">
          Are you sure you want to logout?
        </p>
      </CustomCenterModal>
    </>
  );
};

export default DashboardHeader;
