import axios from "axios";
import { toast } from "react-toastify";
import {
  GET_LOG_DETAILS_BY_LOG_ID,
  POST_LIST_ALL_LOGS_DATA,
  GET_LOG_DETAILS_BY_ORG_ID,
} from "./endpoints";
import { getTokenDetails, toastId } from "../utils";
import { getHeaders } from ".";

export const logsService = {
  // GET logs details by parent ID
  // getListDetailsByParentLogId: (parentLogId, token) =>
  //   axios
  //     .get(GET_LOG_DETAILS_BY_LOG_ID.endpoint(parentLogId), {
  //       headers: getHeaders(token),
  //     })
  //     .then((resp) => {
  //       if (resp.status === GET_LOG_DETAILS_BY_LOG_ID.successStatusCode) {
  //         return resp.data;
  //       }
  //       if (resp.status === 400) {
  //         return resp.status;
  //       }
  //       alert(`Error in API = ${resp.status}`);
  //       return null;
  //     })
  //     .catch((error) => {
  //       if (error?.response?.data?.title && !toast.isActive(toastId)) {
  //         toast.error(error?.response?.data?.title, { toastId: "errorToast" });
  //       }
  //     }),

  // GET logs details by Organization ID
  // getLogsByOrgId: (
  //   { dataModel, eventType, connectionId, customerId, StartDate, EndDate },
  //   orgId,
  //   page,
  //   limit
  // ) =>
  //   axios
  //     .get(
  //       GET_LOG_DETAILS_BY_ORG_ID.endpoint(
  //         dataModel,
  //         eventType,
  //         connectionId,
  //         customerId,
  //         StartDate,
  //         EndDate,
  //         orgId,
  //         page,
  //         limit
  //       )
  //     )
  //     .then((resp) => {
  //       if (resp?.data) return resp.data;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     }),

  getLogsByOrgId: async (
    { dataModel, eventType, connectionId, customerId, StartDate, EndDate },
    orgId,
    page,
    limit
  ) => {
    let response;

    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getTokenDetails(),
      },
    };

    try {
      response = await fetch(
        GET_LOG_DETAILS_BY_ORG_ID.endpoint(
          dataModel,
          eventType,
          connectionId,
          customerId,
          StartDate,
          EndDate,
          orgId,
          page,
          limit
        ),
        options
      );
      response = await response.json();
    } catch (error) {
      let err = await error.json();
      console.log("Error is:", err);
      const errorMsg = Object.keys(err);
      toast.error(err[errorMsg][0]);
    }
    return response;
  },
};
