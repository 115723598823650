import React, { useEffect, useState } from "react";
import { IconButton, OutlinedInput, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import UserAuthHome from ".";
import CustomButton from "../../components/core/CustomButton";
import ToggleCheckbox from "../../components/core/ToggleCheckbox";
import { authService } from "../../services/authMX";
import Loader from "../../components/core/Loader";
import FormControl from "@mui/material/FormControl";
import { validateEmail } from "../../utils/ValidatationFunctions";
import {
  ImportContactsOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import Footer from "../../components/core/Footer";

const LOGIN_VALIDATION_VALUES = {
  email: {
    name: "",
    isError: false,
    errorMessage: "",
  },
  password: {
    emptyError: false,
    emptyErrorMessage: "Password Should Not be Empty.",
    minMaxError: false,
    minMaxErrorMessage:
      "Password Must be Longer than 8 Characters, and Shorter than 256 Characters.",
  },
};

const Login = () => {
  let history = useHistory();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginValidations, setLoginValidations] = useState(
    JSON.parse(JSON.stringify(LOGIN_VALIDATION_VALUES))
  );
  const [isRememberMe, setIsRememberMe] = useState(false);

  const userNameHandler = ({ target: { value } }) => {
    let copiedLoginValidations = { ...loginValidations };
    setUserName(value);

    validateEmail("email", value, copiedLoginValidations);

    setLoginValidations(copiedLoginValidations);
    setUserName(value);
  };

  const passwordHandler = ({ target: { value } }) => {
    let copiedLoginValidations = { ...loginValidations };
    setPassword(value);

    if (value === "") {
      copiedLoginValidations.password.emptyError = true;
    } else {
      copiedLoginValidations.password.emptyError = false;
    }

    if (value !== "") {
      setIsPasswordValid(false);
    }

    // if (minMaxRegex.test(value)) {
    //   copiedLoginValidations.password.minMaxError = true
    //   setIsPasswordValid(true)
    // } else {
    //   copiedLoginValidations.password.minMaxError = false
    // }

    // if (copiedLoginValidations.password.emptyError) {
    //   setIsPasswordValid(false)
    // }

    setLoginValidations(copiedLoginValidations);
    setPassword(value);
  };

  const handleClickShowPassword = () => {
    if (!password) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setShowPassword(true);
  };

  const handleEnterkeyPress = (event) => {
    if (event.key === "Enter") {
      fetchLoginAPI();
    }
  };

  const fetchLoginAPI = () => {
    let copiedLoginValidations = { ...loginValidations };

    if (userName === "") {
      copiedLoginValidations.email.isError = true;
      copiedLoginValidations.email.errorMessage = "Please Enter Email";
      setLoginValidations(copiedLoginValidations);
    }

    if (password === "") {
      setIsPasswordValid(true);
      return;
    }

    if (userName !== "" || password !== "") {
      if (
        !loginValidations.email.isError &&
        !isPasswordValid &&
        !loginValidations.password.emptyError
      ) {
        setLoading(true);
        authService
          .fetchLoginData({
            email: userName,
            password: password,
          })
          .then((resp) => {
            if (resp?.user) {
              localStorage.setItem("rememberMe", isRememberMe);
              if (isRememberMe) {
                localStorage.setItem("token", resp.access_token);
                localStorage.setItem("refreshToken", resp.refresh_token);
              } else {
                sessionStorage.setItem("token", resp.access_token);
                sessionStorage.setItem("refreshToken", resp.refresh_token);
              }

              history.push("/dashboard");
              resetAll();
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => setLoading(false));
      }
    } else {
      setIsUsernameValid(true);
    }
  };

  const resetAll = () => {
    setUserName("");
    setPassword("");
  };

  return (
    <>
      {loading && <Loader />}

      <UserAuthHome>
        <div className="auth-block  my-[20px] xl:my-[20px] lg:my-[20px]">
          <h1 className="text-[50px] font-lato font-bold ml-[10px] lg:text-[30px] xl:text-[40px]">
            Sign In
          </h1>
          <p className="text-[30px] font-lato mt-[11px] ml-[10px] lg:text-[18px] xl:text-[20px]">
            Don't have an Account?{" "}
            <span
              className="text-cyan-600 cursor-pointer font-lato text-[30px] lg:text-[18px] lg:text-cyan-600 xl:text-[20px]"
              onClick={() => history.push("/sign-up")}
            >
              Sign Up
            </span>
          </p>
          <div className="flex flex-col mt-[64px] ml-[10px] xl:mt-[0] lg:mt-[10px]">
            <TextField
              className="textinput-field"
              name="email"
              type="email"
              // id="outlined-basic"
              label="Email ID"
              variant="outlined"
              onChange={userNameHandler}
              // helperText={isUsernameValid && "Enter valid email Id."}
              // autoComplete="off"
              value={userName}
              // value={login}
              error={loginValidations.email.isError}
              helperText={
                loginValidations["email"].isError &&
                loginValidations["email"].errorMessage
              }
            />

            <FormControl
              sx={{ mt: 4, mb: 4 }}
              variant="outlined"
              className={
                "textinput-field " +
                `${!isPasswordValid && "mt-[32px]"} ` +
                `${isPasswordValid && "2xl:mb-0"} `
              }
            >
              <InputLabel
                sx={{ marginLeft: 0.5 }}
                htmlFor="outlined-adornment-password"
                className={`${
                  isPasswordValid || loginValidations.password.emptyError
                    ? "text-[#d32f2f]"
                    : ""
                }`}
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                // size="small"
                className=""
                type={showPassword ? "text" : "password"}
                value={password}
                error={isPasswordValid || loginValidations.password.emptyError}
                required
                onKeyDown={handleEnterkeyPress}
                onChange={passwordHandler}
                helperText={isPasswordValid && "Enter Valid Password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className="p-0 mr-1"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
          {(isPasswordValid && (
            <div>
              <p className="pw-error-msg1 pl-[11px] ">Please Enter Password</p>
            </div>
          )) ||
            (loginValidations.password.emptyError && (
              <div>
                <p className="pw-error-msg1  mt-[-1.9rem] pl-[11px]">
                  Password Should Not be Empty
                </p>
              </div>
            ))}

          <div className="mt-[24px] ml-[10px]">
            <div className="flex mb-[40px] items-center xl:flex xl:mb-[28px] lg:flex lg:mb-[24px]">
              <ToggleCheckbox
                isEnabled={isRememberMe}
                onChangeHandler={() => setIsRememberMe(!isRememberMe)}
              />
              <span className="2xl:ml-[20px] text-[20px] font-lato  xl:text-[18px] ml-[20px] lg:text-[16px] ml-[20px]">
                Remember Me
              </span>
              <span
                className="2xl:ml-auto text-[20px] font-lato cursor-pointer xl:ml-auto text-[18px] lg:text-[16px] ml-auto"
                onClick={() => history.push("/forgot-password")}
              >
                Forgot Password?
              </span>
            </div>

            <CustomButton
              iconName="reply-right"
              text="Sign In"
              className="filled-custom-btn text-[28px] w-full"
              onClick={fetchLoginAPI}
            />
          </div>
        </div>
        <Footer className={"bottom-[5px]"} />
      </UserAuthHome>
    </>
  );
};

export default Login;
