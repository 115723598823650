import React, { useEffect, useState } from "react";
import DashBoardOrganizations from "../../components/Organization/OrganizationItem";
import addOrg from "../../assets/images/addOrg.png";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { organizationService } from "../../services/organizationMX";
import DataNotAvailable from "../../components/core/DataNotAvailable";
import Loader from "../../components/core/Loader";
import AppLayout from "../../components/layout/AppLayout";
import ReactPaginate from "react-paginate";
import {
  calculateTotalPageCount,
  getOrganizationDetails,
  getStoredData,
  setStoredData,
} from "../../utils";
import { CUSTOMER } from "../../utils/routeConstants";
import CustomCenterModal from "../../components/core/CustomCenterModal";
import Search from "../../components/core/Search";

function CustomerDashboard() {
  useEffect(() => {
    getCustomerData(1);
    getConnectionsData(1);
  }, []);

  const LIMIT = 15;
  const CONNECTIONS_LIMIT = 100;

  const history = useHistory();
  const [customerData, setCustomerData] = useState();
  const [loading, setLoading] = useState(false);
  const [totalCostumers, setTotalCostumers] = useState();
  const [connectionData, setConnectionData] = useState([]);
  const [deleteCustomer, setDeleteCustomer] = useState(false);
  const [deleteCusotmerId, setDeleteCustomerId] = useState("");

  const getCustomerData = (page = 1) => {
    let orgId = getStoredData.getOrgId();
    setLoading(true);
    organizationService
      .fetchCustomers(orgId, page, LIMIT)
      .then((resp) => {
        if (resp) {
          setStoredData.setCustomerCount(resp.count);
          setTotalCostumers(resp?.count);
          setCustomerData(resp?.results);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const getConnectionsData = (page) => {
    const orgId = getStoredData.getOrgId();
    organizationService
      .fetchConnections(page, orgId, CONNECTIONS_LIMIT)
      .then((resp) => {
        setConnectionData(resp?.results);
      })
      .catch((err) => console.log(err));
  };

  function storeDeleteCustomerId(id) {
    setDeleteCustomer(true);
    setDeleteCustomerId(id);
  }

  const deleteCusotmer = () => {
    const orgId = getOrganizationDetails();
    setLoading(true);
    organizationService
      .deleteCustomer(orgId, deleteCusotmerId)
      .then((res) => {
        setDeleteCustomer(false);
        getCustomerData();
        setDeleteCustomerId("");

        setCustomerData(customerData);
      })
      .catch((err) => console.log("err: ", err))
      .finally(() => setLoading(false));
  };

  return (
    <AppLayout>
      {loading && <Loader />}

      <section className="connection-container bg-[#EDF6F9] pl-[40px] pt-[10px]">
        <div>
          <div className="flex">
            <h2 className="text-[40px] mt-[1%] font-lato text-[#003344] xl:text-[30px] lg:text-[30px] ">
              Customers
            </h2>

            <Search
              top="right-[145px]"
              right="top-[35px]"
              page="customer"
              parentSetState={setCustomerData}
            />
            <Tooltip title="CREATE NEW CUSTOMER" arrow placement="top">
              <div
                className="create-btn "
                onClick={() => history.push(CUSTOMER.CREATE)}
              >
                <button>
                  <img src={addOrg} />
                </button>
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="connection-list-container">
          <div className="flex flex-wrap mt-[2%] ">
            {customerData?.length ? (
              customerData.map((item, index) => (
                <DashBoardOrganizations
                  key={index}
                  customer={item}
                  deleteCusotmer={storeDeleteCustomerId}
                  updateCustomerRoute={CUSTOMER.UPDATE}
                  addWebHook={CUSTOMER.ADD_WEBHOOK}
                  onClick={() =>
                    history.push({
                      pathname: CUSTOMER.ADD_CONNECTION,
                      state: {
                        customer: item,
                        connection: connectionData,
                      },
                    })
                  }
                />
              ))
            ) : (
              <DataNotAvailable data={"Customers"} className="" />
            )}
          </div>
        </div>
        {totalCostumers > LIMIT && (
          <div className="flex flex-row justify-end mr-[3%]">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              // marginPagesDisplayed={4}
              // pageRangeDisplayed={7}
              pageCount={calculateTotalPageCount(totalCostumers, LIMIT)}
              onPageChange={(selectedItem) =>
                getCustomerData(selectedItem?.selected + 1)
              }
            />
          </div>
        )}
      </section>
      <CustomCenterModal
        saveButtonTitle={"DELETE"}
        openCenterModal={deleteCustomer}
        onCloseCenterModal={storeDeleteCustomerId}
        closeButtonHandler={() => {
          setDeleteCustomer(false);
        }}
        className=""
        saveButtonHandler={deleteCusotmer}
        // loader={modalLoading}  // loader={modalLoading}
      >
        <div className="">
          <p className="mt-[19px] text-lato text-[20px] text-[3a3a3a]">
            Are you sure you want to delete this customer?
          </p>
        </div>
      </CustomCenterModal>
    </AppLayout>
  );
}

export default CustomerDashboard;
