import React, { useState, useEffect } from "react";
import "../../assets/styles/common.scss";
import "../../assets/styles/dashboard.scss";
import "../../assets/styles/connection.scss";
import Search from "../../components/core/Search";
import { Tooltip } from "@mui/material";
import { organizationService } from "../../services/organizationMX";
import DashBoardOrganizations from "../../components/Organization/OrganizationItem";
import addOrg from "../../assets/images/addOrg.png";
import DataNotAvailable from "../../components/core/DataNotAvailable";
import { useHistory } from "react-router-dom";
import AppLayout from "../../components/layout/AppLayout";
import ReactPaginate from "react-paginate";
import {
  calculateTotalPageCount,
  getOrganizationDetails,
  getStoredData,
  setStoredData,
} from "../../utils";
import { CONNECTIONS } from "../../utils/routeConstants";
import Loader from "../../components/core/Loader";
import CustomCenterModal from "../../components/core/CustomCenterModal";

let totalConnections;
const Connection = () => {
  const [connectionData, setConnectionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteConnectionData, setDeleteConnectionData] = useState(false);
  const [deleteConnectionId, setDeleteConnectionId] = useState("");

  const LIMIT = 12;

  const history = useHistory();
  useEffect(() => {
    getConnectionsData();
  }, []);

  const getConnectionsData = (page = 1) => {
    let orgId = getStoredData.getOrgId();
    setIsLoading(true);
    organizationService
      .fetchConnections(page, orgId, LIMIT)
      .then((resp) => {
        if (resp) {
          totalConnections = resp?.count;
          setStoredData.setConnectionCount(resp?.count);
          setConnectionData(resp?.results);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  function storeDeleteConnectionId(id) {
    setDeleteConnectionData(true);
    setDeleteConnectionId(id);
  }

  const deleteConnection = () => {
    const orgId = getOrganizationDetails();
    setIsLoading(true);

    organizationService
      .deleteConnection(orgId, deleteConnectionId)
      .then((res) => {
        getConnectionsData();
        setDeleteConnectionData(false);

        setConnectionData(connectionData);
      })
      .catch((err) => {
        console.log("err: ", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AppLayout>
      {isLoading && <Loader />}
      <section className="connection-container bg-[#EDF6F9] pl-[40px] pt-[10px] ">
        <div>
          <div className="flex flex-row">
            <h2 className="text-[40px] mt-[1%] font-lato text-[#003344] xl:text-[30px] lg:text-[30px]">
              Connections
            </h2>

            <Search
              top="right-[145px]"
              right="top-[35px]"
              page="connection"
              parentSetState={setConnectionData}
            />
            <Tooltip title="CREATE NEW CONNECTION" arrow placement="top">
              <div
                className="create-btn "
                onClick={() => history.push(CONNECTIONS.CREATE)}
              >
                <button>
                  <img src={addOrg} />
                </button>
              </div>
            </Tooltip>
          </div>

          <div className="connection-list-container">
            <div className="flex flex-wrap mt-[2%]">
              {connectionData?.length ? (
                connectionData.map((item, index) => (
                  <DashBoardOrganizations
                    key={index}
                    connection={item}
                    deleteConnection={storeDeleteConnectionId}
                    updateConnectionRoute={CONNECTIONS.UPDATE}
                  />
                ))
              ) : (
                <DataNotAvailable data={"Connections"} className="" />
              )}
            </div>
          </div>
        </div>
        {totalConnections > LIMIT && (
          <div className="flex flex-row justify-end mr-[3%]">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              // marginPagesDisplayed={4}
              // pageRangeDisplayed={7}
              pageCount={calculateTotalPageCount(totalConnections, LIMIT)}
              onPageChange={(selectedItem) =>
                getConnectionsData(selectedItem?.selected + 1)
              }
            />
          </div>
        )}
      </section>
      <CustomCenterModal
        saveButtonTitle={"DELETE"}
        openCenterModal={deleteConnectionData}
        onCloseCenterModal={storeDeleteConnectionId}
        closeButtonHandler={() => {
          setDeleteConnectionData(false);
        }}
        className=""
        saveButtonHandler={deleteConnection}
      >
        <div className="">
          <p className="mt-[19px] text-lato text-[20px] text-[3a3a3a]">
            Are you sure you want to delete this connection?
          </p>
        </div>
      </CustomCenterModal>
    </AppLayout>
  );
};

export default Connection;
