import React, { useState } from "react";
import "../../assets/styles/common.scss"

import APIKeyTabs from "../../components/APIKey/APIKeyTabs";
import ManageOrganization from "./ManageOrganization";
import ManageUsers from "./ManageUsers";
import OrganizationProfile from "./OrganizationProfile";
import UserSettings from "./UserSettings";
import SideBar from "../../components/Sidebar";
import DropdownList from "../../components/core/DropdownList";
import { ENVIRONMENT_LIST, SETTINGS_TABS } from "../../utils";
import DropdownV2 from "../../components/core/DropdownV2";
import Footer from "../../components/core/Footer";

const SETTINGS_PAGES = [
  <ManageOrganization />,
  <OrganizationProfile />,
  <ManageUsers />,
  <UserSettings />,
];

const Settings = ({
  activeTab
}) => {
  return (
    <div className="flex w-full bg-[#EDF6F9]">
      <SideBar />

      <div className="settings-container px-[40px] pt-[40px] flex-1 w-[82%] xl:pt-[20px] xl:px-[30px]">
        <div className="clear-right">
          <APIKeyTabs
            tabs={SETTINGS_TABS}
            pages={SETTINGS_PAGES}
            activeTab={activeTab && activeTab}
          />
        </div>
      </div>
      <Footer className={"bottom-[5px]"}/>
    </div>
  );
};

export default Settings;
