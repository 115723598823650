import { bgColorBtnSelector } from "../../utils";
import moment from "moment-timezone";

const LogItem = ({
  name,
  date,
  eventType,
  status,
  clickLogItem,
  activeLogItem,
}) => {
  let momentDate = moment(date).format("DD-MM-YYYY");
  let momentTime = moment(date).format("hh:mm A");

  return (
    <div
      className={
        `logItem-container ` + `${activeLogItem ? "active-log-item" : ""}`
      }
      onClick={() => clickLogItem(true)}
    >
      <div className="item-details1">
        <h2 className="text-[20px] font-lato text-[#3A3A3A] xl:text-[16px]">
          {name || "N/A"}
        </h2>
        <div>
          <span className="text-[20px] font-lato text-[#3A3A3A] xl:text-[16px]">
            {momentDate || "-"}
          </span>
          <span className="text-[20px] ml-[10px] font-lato text-[#3A3A3A] xl:text-[16px]">
            {momentTime || "-"}
          </span>
        </div>
      </div>
      <div className="item-details2 mt-[10px]">
        <span className="text-[#939393] text-[20px] font-lato xl:text-[16px]">
          {eventType || "-"}
        </span>
        {/* <div className="type-status">
          <span
            style={bgColorBtnSelector(status)}
            className="text-[18px] ml-[20px] font-lato rounded-[5px] py-[3px] px-[9px] xl:text-[16px]"
          >
            {status || "-"}
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default LogItem;
