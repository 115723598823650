//src/pages/overview/index.jsx

import React, { useState, useEffect } from "react";
import "../../assets/styles/overview.scss";

import { useHistory } from "react-router-dom";

import DropdownList from "../../components/core/DropdownList";
import Footer from "../../components/core/Footer";
import listIcon from "../../assets/images/list-icon.svg";
import SideBar from "../../components/Sidebar";
import DropdownV2 from "../../components/core/DropdownV2";
import {
  ENVIRONMENT_LIST,
  RE_DOCS_URL,
  getOrganizationDetails,
  getStoredData,
  setStoredData,
} from "../../utils";
import { CUSTOMER, LOG, WEBHOOK } from "../../utils/routeConstants";
import { webHookService } from "../../services/webhookMX";
import { organizationService } from "../../services/organizationMX";
import Loader from "../../components/core/Loader";

const Overview = () => {
  let history = useHistory();
  const TOTAL_STATES = {
    setTotalCustomer: 0,
    setTotalConnection: 0,
    setTotalWebHook: 0,
  };
  const LIMIT = 15;
  const CONNECTIONS_LIMIT = 100;
  const orgId = getOrganizationDetails();
  const [totalState, setTotalState] = useState({ ...TOTAL_STATES });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCustomerData(1);
    getConnectionsData(1);
    getWebHooks(1);
  }, []);

  const getWebHooks = (page = 1) => {
    setLoading(true);
    webHookService
      .getWebHooksByOrgId(orgId, page, LIMIT)
      .then((res) => {
        if (res) {
          setTotalState({ ...totalState, setTotalWebhook: res.count });
          // sessionStorage.setItem("webhookCount", res.count);
          // setStoredData.setWebhookCount(res.count);
          setStoredData.setWebhookCount(res?.count);
        }
      })

      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const getCustomerData = (page = 1) => {
    // let orgId = localStorage.getItem("orgId");
    let orgId = getStoredData.getOrgId();
    setLoading(true);
    organizationService
      .fetchCustomers(orgId, page, LIMIT)
      .then((resp) => {
        if (resp) {
          setTotalState({ ...totalState, setTotalCustomer: resp.count });
          setStoredData.setCustomerCount(resp?.count);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  const getConnectionsData = (page) => {
    const orgId = getStoredData.getOrgId();
    setLoading(true);
    organizationService
      .fetchConnections(page, orgId, CONNECTIONS_LIMIT)
      .then((resp) => {
        setTotalState({ ...totalState, setTotalConnection: resp.count });
        setStoredData.setConnectionCount(resp?.count);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {loading && <Loader />}
      <div className="flex w-full">
        <SideBar />
        <section className="bg-[#EDF6F9] pt-[60px] pl-[40px] pr-[40px] font-medium h-screen flex-1 w-[88%]">
          <div className="flex flex-col justify-between">
            <div className="flex justify-between">
              <h1 className="text-[#003344] font-lato text-[40px] xl:text-[30px] lg:text-[24px]">
                Overview
              </h1>
            </div>

            <h2 className="mt-[20px] font-lato text-[28px] text-[#3a3a3a] xl:text-[20px] lg:text-[18px]">
              Welcome to IntraConnects Dashboard!
            </h2>
            {/* <p className=" mt-[58px] text-[#3a3a3a] text-[20px] font-lato xl:text-[18px] xl:mt-[20px] lg:text-[16px] lg:mt-[32px]">
            You can use this site to configure, manage and troubleshoot your
            integrations.
          </p> */}

            <div className="flex justify-between items-center mt-[7%] xl:mt-[8px] lg:mt-[4px]">
              <div className="flex">
                <span className="text-[#3a3a3a] text-[20px] ml-[20px] font-lato xl:text-[18px] lg:text-[16px] ">
                  You can use this site to configure, manage and troubleshoot
                  your integrations.
                </span>
              </div>
              <button
                className="filled-custom-btn bg-[#076993] w-[166px] h-[48px] font-bold text-[20px] hover: text-[#ffffff] rounded xl:w-[120px] xl:h-[32px] lg:w-[100px] lg:h-[30px]"
                onClick={() => history.push("/connections")}
              >
                Connections
              </button>
            </div>

            <div className="flex justify-between items-center mt-[20px] xl:mt-[8px] lg:mt-[4px]">
              <div className="flex">
                <img src={listIcon} className=""></img>
                <span className="text-[#3a3a3a] text-[20px] ml-[20px] font-lato xl:text-[18px] lg:text-[16px] ">
                  View what types of data you are able to exchange where via
                  IntraConnects
                </span>
              </div>
              <button
                className="bg-[#076993] w-[166px] h-[48px] text-[20px] font-bold hover: text-[#ffffff] rounded xl:w-[120px] xl:h-[32px] lg:w-[100px] lg:h-[30px]"
                onClick={() => history.push(CUSTOMER.DASHBOARD)}
              >
                Customers
              </button>
            </div>

            <div className="flex justify-between items-center mt-[20px] xl:mt-[20px] lg:mt-[14px]">
              <div className="flex">
                <img src={listIcon} className=""></img>
                <span className="text-[#3a3a3a] text-[20px] ml-[20px] font-lato xl:text-[18px] lg:text-[16px] ">
                  Configure your integration with IntraConnects
                </span>
              </div>

              <button
                className="bg-[#076993] w-[166px] h-[48px] text-[20px] font-bold hover: text-[#ffffff] rounded xl:w-[120px] xl:h-[32px] lg:w-[100px] lg:h-[30px]"
                onClick={() => history.push(WEBHOOK.DASHBOARD)}
              >
                Webhook
              </button>
            </div>

            <div className="flex justify-between mt-[20px] items-center xl:mt-[8px] lg:mt-[4px]">
              <div className="flex">
                <img src={listIcon} className=""></img>
                <span className="text-[#3a3a3a] text-[20px] ml-[20px] font-lato xl:text-[18px] lg:text-[16px] ">
                  View individual request logs of data exchanged via
                  IntraConnects
                </span>
              </div>
              <button
                className="bg-[#076993] w-[166px] h-[48px] text-[20px] font-bold hover: text-[#ffffff] rounded xl:w-[120px] xl:h-[32px] lg:w-[100px] lg:h-[30px]"
                onClick={() => history.push(LOG)}
              >
                Logs
              </button>
            </div>

            <div className="information">
              <p className=" text-[#3a3a3a] font-lato text-[20px] xl:text-[18px] lg:text-[16px]">
                To learn more, check out our{" "}
                <a
                  href="https://docs.medarch.com/"
                  target="_blank"
                  className="text-[#076993] underline"
                >
                  Developer Guide{" "}
                </a>
                or{" "}
                <a
                  href="https://docs.medarch.com/docs/category/api-documentation/"
                  target="_blank"
                  className="text-[#076993] underline"
                >
                  API Reference
                </a>
                .
              </p>
            </div>

            <Footer className={"bottom-[5px]"} />
          </div>
        </section>
      </div>
    </>
  );
};

export default Overview;
