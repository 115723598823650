import uploadImg from "../../assets/images/img_upload.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { VALIDATION_UPDATE_USER } from "../../services/initialValues";
import CustomButton from "../../components/core/CustomButton";
import { getTokenDetails, deepCopy } from "../../utils";
import { authService } from "../../services/authMX";
import InputAdornment from "@mui/material/InputAdornment";
import { validateUserName } from "./UserProfileValidation";
import { formatNumber, phoneNumberRegex } from "../../utils";
import { validatePhoneNumber } from "../../utils/ValidatationFunctions";
import { toast } from "react-toastify";

const UserProfile = () => {
  const [modalLoading, setModalLoading] = useState(false);
  const token = getTokenDetails();
  const [orgImage, setOrgImage] = useState(null);
  const [usersInfo, setUsersInfo] = useState(deepCopy(VALIDATION_UPDATE_USER));
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchCurrentUsersData();
  }, []);

  const fetchCurrentUsersData = () => {
    setLoading(true);
    authService
      .fetchCurrentUsersData()
      .then((resp) => {
        if (resp) {
          let copiedUserInfo = { ...usersInfo };
          copiedUserInfo.userName.fieldValue = resp.username;
          setUsersInfo(copiedUserInfo);

          setOrgImage(resp?.userImageUrl);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const onUsersInfoDataHandler = ({ target: { value, name } }) => {
    let copiedUsersInfoValues = { ...usersInfo };

    if (name === "username") {
      copiedUsersInfoValues.userName.fieldValue = value;

      validateUserName(value, copiedUsersInfoValues);
    }

    if (name === "phoneNumber") {
      validatePhoneNumber(value, copiedUsersInfoValues);

      if (value.length <= 14 && phoneNumberRegex.test(value)) {
        copiedUsersInfoValues.phoneNumber.fieldValue = formatNumber(value);
        setUsersInfo(copiedUsersInfoValues);
      }
    }
    setUsersInfo(copiedUsersInfoValues);
  };
  const SaveUpdatedData = () => {
    let body = {
      username: usersInfo.userName.fieldValue,
      PhoneNumber: usersInfo.phoneNumber.fieldValue,
    };

    if (!usersInfo.userName.isError && !usersInfo.phoneNumber.isError) {
      setModalLoading(true);
      authService
        .putUpdateUsersData(body, token)
        .then((resp) => {
          if (resp) {
            toast.success("Succesfully Saved!");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setModalLoading(false));
    }
  };

  return (
    <>
      <h1 className="font-lato text-[20px] text-[#3a3a3a] xl:text-[16px]">
        Profile
      </h1>
      <div className="flex items-center w-full">
        <div className="w-[20%]">
          <div className="w-[167px] h-[167px] bg-[#F2F2F2] flex flex-col items-center justify-center shadow-[0px_3px_6px_#00000029] cursor-pointer xl:p-[10px]">
            <figure className="w-[150px] h-[140px]">
              <img
                src={!orgImage ? uploadImg : orgImage}
                className="w-full h-full"
              ></img>
            </figure>
            <span className="font-lato text-[12px] xl:text-[10px]">
              {!orgImage && "Click to upload profile photo"}
            </span>
          </div>
        </div>
        <div className="flex justify-between w-[70%]">
          <div className="w-[20%] mt-[30px] flex flex-col xl:w-[15%] xl:mt-[50px]">
            <span className="font-lato text-[20px] text-[#3a3a3a] ">
              User Name
            </span>
            <span className="text-[20px] font-lato text-[#3A3A3A] mt-[59px] xl:mt-[60px]">
              Phone
            </span>
          </div>

          <div className="w-[90%] mt-[20px] xl:w-full xl:ml-[10px]">
            <TextField
              name="username"
              label=""
              className="textinput-field w-[698px] "
              id="outlined-basic-user-name"
              variant="outlined"
              type="text"
              onChange={onUsersInfoDataHandler}
              value={usersInfo.userName.fieldValue}
              error={usersInfo.userName.isError}
              helperText={
                usersInfo.userName.isError && usersInfo.userName.errorMessage
              }
              placeholder="Enter Name"
            />

            <TextField
              name="phoneNumber"
              label="Phone"
              className="textinput-field w-full mt-[35px] w-[698px]"
              variant="outlined"
              type="text"
              onChange={onUsersInfoDataHandler}
              value={usersInfo?.phoneNumber?.fieldValue ?? ""}
              error={usersInfo?.phoneNumber?.isError}
              helperText={
                usersInfo.phoneNumber.isError &&
                usersInfo.phoneNumber.errorMessage
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+1</InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end items-end mt-[20px] w-[82%]  xl: w-[98%]">
        {!modalLoading ? (
          <CustomButton
            iconName="reply-right"
            text="SAVE"
            className="filled-custom-btn rounded-[5px] text-[20px] font-lato w-[165px] h-[56px] xl:w-[120px] xl:h-[38px] xl:text-[16px]"
            onClick={SaveUpdatedData}
          />
        ) : (
          <LoadingButton
            loading={modalLoading}
            loadingPosition="center"
            variant="outlined"
            className={
              "rounded-[5px] text-[20px] font-lato w-[165px] h-[56px] text-[#fff]"
            }
          />
        )}
      </div>
    </>
  );
};

export default UserProfile;
