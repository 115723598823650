import {
  emailRegX,
  webSiteRegex,
  noWhiteSpaceRegex,
  twitterIdRegex,
  PhoneRegeX,
} from "./index";

//Messages for Email Validations
const EMPTY_EMAIL_ERROR_MESSAGE = "Email Should Not be Empty";
const INVALID_EMAIL_ERROR_MESSAGE = "Enter Valid Email";
const EMAIL_LENGTH_ERROR_MESSAGE =
  "Email Should Not be More Than 70 Characters";

//Messages for Website Validations
const INVALID_WEBSITE_URL_ERROR_MESSAGE = "Please Enter Valid URL";
const WEBSITE_URL_LENGTH_MESSAGE = "Length Should Not be More Than 1000";
const SPACE_IN_URL_MESSAGE = "URL Should Not Contain Any Spaces";

//Message for Twitter Validation
const INVALID_TWITTER_ID_MESSAGE = "Enter Valid Twitter ID";
const TWIITER_LENGTH_ERROR_MESSAGE = "Length Should Not be More Than 15";

//Messages for Phone Validation
const EMPTY_PHONE_NUMBER_MESSAGE = "Enter Phone Number";
const INVALID_PHONE_NUMBER_NUMBER = "Please Enter a Valid Phone Number";
const LIMIT_ERROR_MESSAGE = "Phone Number Should Not be More Than 10 Digits";
const CHARACTER_ERRROR_MESSAGE = "Phone Number Should Only Contain Digits";

export const validateEmail = (email, value, validationState) => {
  if (value === "") {
    validationState[email].isError = true;
    validationState[email].errorMessage = EMPTY_EMAIL_ERROR_MESSAGE;
  } else {
    if (!emailRegX.test(value)) {
      validationState[email].errorMessage = INVALID_EMAIL_ERROR_MESSAGE;
      validationState[email].isError = true;
    } else if (value.length > 70) {
      validationState[email].isError = true;
      validationState[email].errorMessage = EMAIL_LENGTH_ERROR_MESSAGE;
    } else {
      validationState[email].isError = false;
    }
  }
};

export const validateWebsiteURL = (value, validationState) => {
  if (value.length > 0) {
    if (noWhiteSpaceRegex.test(value)) {
      validationState.website.isError = true;
      validationState.website.errorMessage = SPACE_IN_URL_MESSAGE;
    } else if (!webSiteRegex.test(value)) {
      validationState.website.isError = true;
      validationState.website.errorMessage = INVALID_WEBSITE_URL_ERROR_MESSAGE;
    } else if (value.length > 1000) {
      validationState.website.isError = true;
      validationState.website.errorMessage = WEBSITE_URL_LENGTH_MESSAGE;
    } else {
      validationState.website.isError = false;
    }
  } else {
    validationState.website.isError = false;
  }
};

export const ValidateTwitterID = (value, validationState) => {
  if (value.length > 0) {
    if (!twitterIdRegex.test(value)) {
      validationState.twitter.isError = true;
      validationState.twitter.errorMessage = INVALID_TWITTER_ID_MESSAGE;
    } else if (value.length > 15) {
      validationState.twitter.isError = true;
      validationState.twitter.errorMessage = TWIITER_LENGTH_ERROR_MESSAGE;
    } else {
      validationState.twitter.isError = false;
    }
  } else {
    validationState.twitter.isError = false;
  }
};

export const validatePhoneNumber = (value, validationState) => {
  if (value.length <= 14) {
    if (!PhoneRegeX.test(value)) {
      validationState.phoneNumber.isError = true;
      validationState.phoneNumber.errorMessage = INVALID_PHONE_NUMBER_NUMBER;
    } else {
      validationState.phoneNumber.isError = false;
    }
  }
};
