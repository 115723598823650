import React from "react";
import { useHistory } from "react-router-dom";
import UserAuthHome from "../../pages/user-auth";

const NotFound = () => {
  return (
    <UserAuthHome>
      <div className="not-found-container">
        <h3 className="not-found-text">
          This page does not belong to IntraConnects.
        </h3>
      </div>
    </UserAuthHome>
  );
};

export default NotFound;
