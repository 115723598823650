// export const emailRegX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // old regex for mail commented because giving error.
export const emailRegX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.\w{2,3})+$/;

// export const urlPattrn =
//   /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const passwordRegX = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$/;

// export const passwordCustomRegX = /^(?=.*?[A-Za-z])(?=.*?[0-9]).*[!@#\$%\^&\*]/;

export const passwordCustomRegX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?/~!@#\$%\^&\*])/;

export const minMaxRegex = /^.{8,256}$/;

// export const noWhiteSpaceRegex = /\s.*^\s\s+|\s+$/g;
// export const noWhiteSpaceRegex = /^\s|\s\s/;
export const noWhiteSpaceRegex = /^\s|\s/;

export const detectWhiteSpaceRegex = /\s/;

export const noWhiteSpaceRegexBetween = /^[-a-zA-Z]+[\s]([-a-zA-Z]+)$/;

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const onlyAlphabetsRegex = /^[a-zA-Z ]*$/;

export const onlyAlphaNumericUnderscoreRegex = /^[a-zA-Z0-9_]*$/;

export const SignupUserNameRegex = /^[a-zA-Z0-9_+.@/-]*$/;

export const specialSymbolsRegex = /[’`~!#+-@.*$%=,^&(){}[\]|;:”<>?\\]/g;

export const phoneNumberLengthLimit = /^\d{11}$/;

// export const phoneNumberRegex = /[^0-9]/g

export const phoneNumberRegex = /^[0-9)(-\s]*$/;

export const PhoneRegeX = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

export const twitterIdRegex = /^@[A-Za-z0-9_]*$/;

export const nameRegex = /^[a-zA-Z0-9]*$/;
// export const webSiteRegex =
//   /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;

export const webSiteRegex =
  /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,}\.[a-zA-Z0-9()]{1,}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/;

export const ipRegex =
  /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

export const logOutHandler = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const getInnerHeight = () => window.innerHeight;

export const getInnerHeightForImage = () => window.innerHeight;

export const bgColorBtnSelector = (_type) => {
  switch (_type?.toLowerCase()) {
    case "succeeded":
      return { background: "#D7ECC9" };

    case "failed":
      return { background: "#FFBDBD" };

    default:
      return { background: "#FFBDBD" };
  }
};

export const ENVIRONMENT_LIST = ["Development", "Stage", "Production"];

export const API_KEY_TABS = [
  { name: "API Keys", route: "/api-key/apikeys" },
  { name: "Webhooks", route: "/api-key/webhooks" },
];

export const SETTINGS_TABS = [
  { name: "Manage Organization", route: "/settings/manage-organisation" },
  { name: "Organization Profile", route: "/settings/organisation-profile" },
  { name: "Manage Users", route: "/settings/manage-user" },
  { name: "User Settings", route: "/settings/user-settings" },
];

export const toastId = "errorToast";

export const RE_DOCS_URL = "https://docs.resolveengine.com/";

export const ORG_CATEGORIES_LIST = [
  "Patient engagement",
  "Scheduling",
  "Therapeutic Devices",
  "Alerts/ Safety/ Support/ Optiminzation",
  "Referral Management",
];

export const REAPIWebhook =
  "This is a URL you have set up to receive messages from IntraConnects. It should accept POST requests and validate theverification token you supply below.";
export const verificationTokenText =
  "IntraConnects sends this token in the verification - token field of the HTTP request header. It is included with every request IntraConnects makes to your endpoint. It is your way to ensure the communication comes from IntraConnects. It should be kept secret. You can change this value at any time.";
export const verficationMethodText =
  "The method by which you would like to verify your endpoint with IntraConnects. We recommend using POST for stronger security.";

export const getEnvironmentDetails = () =>
  sessionStorage.getItem("environment") ?? "Development";

export const getTokenDetails = () => {
  const isRememberMe = localStorage.getItem("rememberMe");
  let token;
  if (isRememberMe === "true") {
    token = localStorage.getItem("token");
  } else if (isRememberMe === "false") {
    token = sessionStorage.getItem("token");
  } else {
    return "";
  }
  return token;
};

export const saveAccessToken = (accessToken) => {
  const isRememberMe = localStorage.getItem("rememberMe");
  if (isRememberMe === "true") {
    localStorage.setItem("token", accessToken);
  } else {
    sessionStorage.setItem("token", accessToken);
  }
};

export const getRefreshTokenDetails = () => {
  const isRememberMe = localStorage.getItem("rememberMe");
  const token =
    isRememberMe === "true"
      ? localStorage.getItem("refreshToken")
      : sessionStorage.getItem("refreshToken");

  return token;
};

export const getOrganizationDetails = () => getStoredData.getOrgId() ?? "";

export const imageType = ["jpg", "jpeg", "png", "svg+xml", "bmp", "gif", "JPG"];

export const calculateTotalPageCount = (count, limit) =>
  Math.ceil(count / limit);

export const getRememberMe = () => localStorage.getItem("rememberMe") ?? "";

export const covertPhNoFormat = (phNo) => {
  if (phNo.length === 10) {
    return `+1${phNo}`;
  } else if (phNo.length === 12) {
    return phNo;
  } else if (phNo.length === 14) {
    return "+1" + phNo.slice(1, 4) + phNo.slice(6, 9) + phNo.slice(10, 14);
  } else {
    return (
      phNo.slice(0, 2) +
      phNo.slice(4, 7) +
      phNo.slice(9, 12) +
      phNo.slice(13, 17)
    );
  }
};
// export const covertPhNoFormat = (phNo) => {
//   if (phNo.length === 10) {
//     return `+1${phNo}`;
//   } else if (phNo.length === 12) {
//     return phNo;
//   } else {
//     return (
//       phNo.slice(0, 2) +
//       phNo.slice(4, 7) +
//       phNo.slice(9, 12) +
//       phNo.slice(13, 17)
//     );
//   }
// };

export const reConvertPhoneNumber = (num) => {
  let number;
  if (num.length === 12) {
    number =
      "(" + num.slice(2, 5) + ") " + num.slice(5, 8) + "-" + num.slice(8, 12);
  } else if (num.length === 10) {
    number = num;
  }

  return number;
};

export const statusSelector = (statusCode) => {
  switch (statusCode) {
    case 200:
      return "succeeded";

    case 201:
      return "succeeded";

    case 204:
      return "succeeded";

    default:
      return "failed";
  }
};

export function deepCopy(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  const clonedObj = Object.create(Object.getPrototypeOf(obj));
  for (let prop in obj) {
    clonedObj[prop] = deepCopy(obj[prop]);
  }

  return clonedObj;
}

export const formatNumber = (num) => {
  let number;
  switch (num.length) {
    case 1:
      //Add () if Moving forward
      if (num.slice(0) === "(") return (number = "");
      else number = "(" + num + ")";
      return number;

    case 2:
      number = "";
      return number;

    case 3:
      number = "(" + num.slice(1, -1) + ")";
      return number;

    case 4:
      if (num.slice(-2, -1) === ")") {
        number = "(" + num.slice(1, 2) + num.slice(-1) + ")";
        return number;
      } else {
        number = "(" + num.slice(1, 3) + ")";
        return number;
      }

    case 5:
      if (num.slice(-1) === ")") return (number = num);
      else {
        number = "(" + num.slice(1, 3) + num.slice(-1) + ")";
        return number;
      }

    case 6:
      //Adding a space if moving forward
      number = num;
      if (num.slice(-1) === " ") return (number = num);
      else if (phoneNumberRegex.test(num))
        return (number = "(" + num.slice(1, 4) + ")" + " " + num.slice(-1));
      return number;

    case 7:
      number = num;
      return number;

    case 8:
      number = num;
      return number;

    case 9:
      number = num;
      return number;

    case 10:
      if (num.slice(-1) === "-") return (number = num);
      else {
        number =
          "(" +
          num.slice(1, 4) +
          ")" +
          " " +
          num.slice(6, 9) +
          "-" +
          num.slice(-1);
        return number;
      }

    case 11:
      number = num;
      return number;

    case 12:
      number = num;
      return number;

    case 13:
      number = num;
      return number;

    case 14:
      number = num;
      return number;
    default:
      return num;
  }
};
export const getStoredData = {
  // if rememberMe is true then get the data from local storage else get the data from session storage
  getOrgId: () => {
    let orgId;
    let isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      orgId = localStorage.getItem("orgId");
    } else {
      orgId = sessionStorage.getItem("orgId");
    }
    return orgId;
  },
  getOrgItem: () => {
    let orgItem;
    let isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      orgItem = localStorage.getItem("orgItem");
    } else {
      orgItem = sessionStorage.getItem("orgItem");
    }
    return orgItem;
  },
  getConnectionCount: () => {
    let ConnectionCount;
    let isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      ConnectionCount = localStorage.getItem("connectionCount");
    } else {
      ConnectionCount = sessionStorage.getItem("connectionCount");
    }
    return ConnectionCount;
  },
  getWebhookCount: () => {
    let webhookCount;
    let isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      webhookCount = localStorage.getItem("webhookCount");
    } else {
      webhookCount = sessionStorage.getItem("webhookCount");
    }
    return webhookCount;
  },
  getCustomerCount: () => {
    let CustomerCount;
    let isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      CustomerCount = localStorage.getItem("customerCount");
    } else {
      CustomerCount = sessionStorage.getItem("customerCount");
    }
    return CustomerCount;
  },
};
export const setStoredData = {
  // if rememberMe is true then store the data in local storage else store the data in session storage
  setOrgDetails: (orgId, organizationData) => {
    let isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      localStorage.setItem("orgId", orgId);
      localStorage.setItem("orgItem", JSON.stringify(organizationData));
    } else {
      sessionStorage.setItem("orgId", orgId);
      sessionStorage.setItem("orgItem", JSON.stringify(organizationData));
    }
  },
  setConnectionCount: (connectionCount) => {
    let isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      localStorage.setItem("connectionCount", connectionCount);
    } else {
      sessionStorage.setItem("connectionCount", connectionCount);
    }
  },
  setWebhookCount: (webhookCount) => {
    let isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      localStorage.setItem("webhookCount", webhookCount);
    } else {
      sessionStorage.setItem("webhookCount", webhookCount);
    }
  },
  setCustomerCount: (customerCount) => {
    let isRememberMe = localStorage.getItem("rememberMe");
    if (isRememberMe === "true") {
      localStorage.setItem("customerCount", customerCount);
    } else {
      sessionStorage.setItem("customerCount", customerCount);
    }
  },
};
