import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { authService } from "../../services/authMX";
import { useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import CustomButton from "../../components/core/CustomButton";
import { getTokenDetails, deepCopy, logOutHandler } from "../../utils";
import { USERS_UPDATE_PASSWORD_DATA } from "../../services/initialValues";
import {
  validateConfirmPassword,
  validateNewPassword,
} from "./PasswordValidation";

const UpdatePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [usersPassword, setUsersPassword] = useState(
    deepCopy(USERS_UPDATE_PASSWORD_DATA)
  );
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const token = getTokenDetails();

  let history = useHistory();

  const fetchUpdateUsersPassword = () => {
    var copiedPasswordValues = { ...usersPassword };
    let body = {
      new_password1: usersPassword.newPassword.fieldValue,
      new_password2: usersPassword.confirmPassword.fieldValue,
    };

    if (copiedPasswordValues.newPassword.fieldValue === "") {
      copiedPasswordValues.newPassword.isError = true;
      setUsersPassword(copiedPasswordValues);
    }

    if (copiedPasswordValues.confirmPassword.fieldValue === "") {
      copiedPasswordValues.confirmPassword.isError = true;
      copiedPasswordValues.confirmPassword.errorMessage =
        "Confirm Password Should Not be Empty";

      setUsersPassword(copiedPasswordValues);
    }

    setUsersPassword(copiedPasswordValues);

    if (
      copiedPasswordValues.newPassword.fieldValue !== "" ||
      copiedPasswordValues.confirmPassword.fieldValue !== ""
    ) {
      if (
        !copiedPasswordValues.newPassword.isError &&
        !copiedPasswordValues.confirmPassword.isError &&
        copiedPasswordValues.newPassword.minMaxError &&
        copiedPasswordValues.newPassword.charactersError
      ) {
        authService
          .putUpdateUsersPassword(body)
          .then((resp) => {
            if (resp?.detail === "New password has been saved.") {
              logOutHandler();
              history.push("/");
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleClickShowPassword = () => {
    if (!usersPassword.newPassword) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const handleClickShowConfirmPassword = () => {
    if (!usersPassword.confirmPassword) {
      setShowConfirmPassword(true);
    } else {
      setShowConfirmPassword(false);
    }
  };

  const confirmPasswordHandler = ({ target: { value, name } }) => {
    let copiedUsersPasswordValues = { ...usersPassword };
    if (name === "confirmPassword") {
      copiedUsersPasswordValues.confirmPassword.fieldValue = value;

      validateConfirmPassword(value, copiedUsersPasswordValues);
    }

    setUsersPassword(copiedUsersPasswordValues);
  };

  const changePasswordHandler = ({ target: { value, name } }) => {
    let copiedUsersPasswordValues = { ...usersPassword };

    if (name === "newPassword") {
      copiedUsersPasswordValues.newPassword.fieldValue = value;

      validateNewPassword(value, copiedUsersPasswordValues);
    }

    setUsersPassword(copiedUsersPasswordValues);
  };

  return (
    <>
      <h1 className="text-[20px] font-lato text-[#3a3a3a] xl:text-[16px]">
        Change Password
      </h1>
      <div className="">
        <div className="flex items-center w-full">
          <span className="items-center font-lato text-[20px] text-[#3a3a3a] mb-[63px] w-[20%] xl:w-[30%]">
            New Password
          </span>
          <div className="flex flex-col w-[50%] xl:w-[60%]">
            <FormControl
              sx={{ mt: 3 }}
              variant="outlined"
              className="textinput-field w-[full]"
            >
              <OutlinedInput
                name="newPassword"
                placeholder="Enter new password"
                id="outlined-new-password"
                type={showPassword ? "text" : "password"}
                error={usersPassword.newPassword.isError}
                onChange={changePasswordHandler}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={() => setShowPassword(true)}
                      edge="end"
                      className="p-0 mr-1"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {usersPassword.newPassword.isError && (
              <div>
                <p className="text-[#d32f2f]">
                  {usersPassword.newPassword.errorMessage}
                </p>
              </div>
            )}

            <div className="mt-[8px]">
              <p
                className={`${
                  usersPassword.newPassword.charactersError
                    ? "text-[#4BB543]"
                    : " text-[#d32f2f]"
                }`}
              >
                {usersPassword.newPassword.charactersError ? (
                  <DoneIcon />
                ) : (
                  <CloseIcon />
                )}

                {
                  "Password must Include these Categories: A-Z,a-z,0-9,and Special Characters(~!@#$%^&*/?)."
                }
              </p>
              <p
                className={`${
                  usersPassword.newPassword.minMaxError
                    ? "text-[#4BB543]"
                    : " text-[#d32f2f]"
                }`}
              >
                {usersPassword.newPassword.minMaxError ? (
                  <DoneIcon />
                ) : (
                  <CloseIcon />
                )}

                {
                  "Password must be Longer than 8 Characters, and Shorter than 256 Characters."
                }
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center w-full">
          <span className="items-center font-lato text-[20px] mt-[20px] text-[#3a3a3a] w-[20%] xl:w-[30%]">
            Confirm Password
          </span>
          <FormControl
            sx={{ mt: 3 }}
            variant="outlined"
            className="textinput-field w-[50%] xl:w-[60%]"
          >
            <OutlinedInput
              name="confirmPassword"
              placeholder="Enter Confirm password"
              id="outlined-confirm-password"
              type={showConfirmPassword ? "text" : "password"}
              error={usersPassword.confirmPassword.isError}
              onChange={confirmPasswordHandler}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={() => setShowConfirmPassword(true)}
                    edge="end"
                    className="p-0 mr-1"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

        {usersPassword.confirmPassword.isError && (
          <div className="mt-[8px] ml-[285px]">
            <p className="text-[#d32f2f]">
              {usersPassword.confirmPassword.errorMessage}
            </p>
          </div>
        )}
      </div>
      <div className="w-[73%] flex justify-end xl:w-[94%]">
        <CustomButton
          iconName="reply-right"
          text="UPDATE PASSWORD"
          className="filled-custom-btn rounded-[5px] font-lato text-[20px] w-[270px] mr-[40px]  mt-[20px] xl:w-[200px] xl:h-[38px] xl:text-[16px]"
          onClick={fetchUpdateUsersPassword}
        />
      </div>
    </>
  );
};

export default UpdatePassword;
