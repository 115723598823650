import axios from "axios";
import { toast } from "react-toastify";
import {
  getRefreshTokenDetails,
  getTokenDetails,
  toastId,
  saveAccessToken,
} from "../utils";
import { authService } from "./authMX";
import { POST_REFRESH_TOKEN } from "./endpoints";

export const getHeaders = (token) => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
});

// export const getHeadersImg = (token) => {
//   return {
//     "content-type": "multipart/form-data",
//     Authorization: `Bearer ${token}`,
//   };
// };

// Request Interceptor
// axios.interceptors.request.use(
//   (req) => {
//     const token = getTokenDetails();

//     if (token) {
//       req.headers.Authorization = `Bearer ${token}`;
//       req.headers["Content-Type"] = "application/json";
//       req.headers.Accept = "application/json";
//     }
//     return req;
//   },
//   (err) => {
//     console.log("request interceptor error:  ", err);
//     return Promise.reject(err);
//   }
// );
// const constantMock = window.fetch;
// window.fetch = function () {
//   console.log("Fetch interceptor called");
//   (req) => {
//     const token = getTokenDetails();

//     if (token) {
//       req.headers.Authorization = `Bearer ${token}`;
//       req.headers["Content-Type"] = "application/json";
//       req.headers.Accept = "application/json";
//     }
//     return req;
//   },
//     (err) => {
//       console.log("request interceptor error:  ", err);
//       return Promise.reject(err);
//     };
//   return constantMock.apply(this, arguments);
// };

// Response Interceptor
// axios.interceptors.response.use(
//   (res) => res,
//   (err) => {
//     const originalRequest = err.config;
//     if (
//       err.response.status === 401 &&
//       originalRequest.url === POST_REFRESH_TOKEN.endpoint
//     ) {
//       localStorage.clear();
//       sessionStorage.clear();
//       return Promise.reject(err);
//     } else if (err.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const reqBody = {
//         refresh: getRefreshTokenDetails(),
//       };

//       return authService
//         .fetchRefreshTokenData(reqBody)
//         .then((res) => {
//           if (res?.status === 200) {
//             saveAccessToken(res.data.access);
//             return axios(originalRequest);
//           }
//         })
//         .catch((err) => {
//           console.log("err is response interceptor:  ", err);
//           return Promise.reject(err);
//         });
//     } else if (err.response.status === 500) {
//       toast.error("Server Error!", {
//         toastId,
//       });
//       return Promise.reject(err);
//     } else if (err.response.status === 404) {
//       toast.error("API Not Found", {
//         toastId,
//       });
//       return Promise.reject(err);
//     } else {
//       const errorMsg = Object.keys(err.response.data);
//       toast.error(
//         Array.isArray(err.response.data[errorMsg[0]])
//           ? err.response.data[errorMsg[0]][0]
//           : err.response.data[errorMsg[0]],
//         { toastId }
//       );
//       return Promise.reject(err);
//     }
//   }
// );

//store the original fetch
const originalFetch = window.fetch;

window.responseInterceptor = async (response) => {
  const originalRequest = response;

  if (response.status === 400) {
    return Promise.reject(response);
  } else if (response.status >= 200 && response.status <= 299) {
    return originalRequest;
  } else if (
    response.status === 401 &&
    originalRequest.url === POST_REFRESH_TOKEN.endpoint
  ) {
    localStorage.clear();
    sessionStorage.clear();
    return Promise.reject(response);
  } else if (response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    let newres = await refreshToken(originalRequest);
    return newres;
  } else if (response.status === 500) {
    toast.error("Server Error!", {
      toastId,
    });

    return Promise.reject(response);
  } else if (response.status === 404) {
    toast.error("API Not Found", {
      toastId,
    });
    return Promise.reject(response);
  }
};

window.fetch = async (...args) => {
  // request interceptor
  // pass the args to request interceptor
  // args = requestInterceptor(args);

  // pass the updated args to fetch
  let response = await originalFetch(...args);

  // response interceptor
  // pass the response to response interceptor

  response = responseInterceptor(response);

  // return the updated response
  return response;
};

const refreshToken = async (originalRequest) => {
  const reqBody = {
    refresh: getRefreshTokenDetails(),
  };

  let res = await authService.fetchRefreshTokenData(reqBody);
  try {
    if (res) {
      let updatedRes;
      saveAccessToken(res.access);
      updatedRes = failedRequest(originalRequest.url);
      return updatedRes;
    }
  } catch (err) {
    console.log("err is response interceptor:  ", err);
    return Promise.reject(err);
  }
};

const failedRequest = async (newURL) => {
  let options = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + getTokenDetails(),
    },
  };
  let newresponse = await fetch(newURL, options);
  return newresponse;
};
