import "../../assets/styles/common.scss";
import React, { useState } from "react";
import { organizationService } from "../../services/organizationMX";
import { getStoredData } from "../../utils";

const Search = ({ top, right, name, page, parentSetState }) => {
  const [searchvalue, setSearchValue] = useState("");

  const searchValueHandler = ({ target: { value, name } }) => {
    if (name === "searchBar") {
      setSearchValue(value);
    }
  };

  const fetchValue = (pageName) => {
    let orgId = getStoredData.getOrgId();

    if (pageName == "webhook") {
      organizationService
        .searchWebHook(orgId, searchvalue)
        .then((res) => {
          if (res) {
            parentSetState(res?.results);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (pageName == "customer") {
      organizationService
        .searchCustomer(orgId, searchvalue, pageName)
        .then((res) => {
          if (res) {
            parentSetState(res?.results);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (pageName == "connection") {
      organizationService
        .searchConnection(orgId, searchvalue)
        .then((res) => {
          if (res) {
            parentSetState(res?.results);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (pageName == "organizationDashboard") {
      organizationService
        .searchOrganizationDashboard(searchvalue)
        .then((res) => {
          if (res) {
            parentSetState(res?.results);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      organizationService
        .searchOrganization(searchvalue)
        .then((res) => {
          if (res) {
            parentSetState(res?.results);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  let temp = `create-search ${top} ${name} ${right}`;
  return (
    <div className={temp}>
      <input
        type="text"
        name="searchBar"
        placeholder="Search.."
        className="create-txt"
        value={searchvalue}
        onChange={searchValueHandler}
      />

      <button className="create-btnsearch" onClick={() => fetchValue(page)}>
        Submit
      </button>
    </div>
  );
};

export default Search;
