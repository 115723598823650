import { ATHENA_SYSTEM, ATHENA_PROVIDER, ATHENA_PATIENT } from "./athena";
import { CERNER_SYSTEM, CERNER_PROVIDER, CERNER_PATIENT } from "./cerner";
import { EPIC_SYSTEM, EPIC_PROVIDER, EPIC_PATIENT } from "./epic";
import { NEXTGEN_PATIENT, NEXTGEN_SYSTEM } from "./nextgen";
import { ELATION_EMR_SYSTEM } from "./eleationEMR";
import { OPEN_EMR_SYSTEM } from "./openEMR";

export default function selectConstants(ehr_name, app_type) {
  let value = `${ehr_name}_${app_type}`;
  let formFieldArray = [];
  switch (value) {
    case "athena_system":
      formFieldArray = ATHENA_SYSTEM;
      break;
    case "athena_provider":
      formFieldArray = ATHENA_PROVIDER;
      break;
    case "athena_patient":
      formFieldArray = ATHENA_PATIENT;
      break;

    case "openepic_system":
      formFieldArray = EPIC_SYSTEM;
      break;
    case "openepic_provider":
      formFieldArray = EPIC_PROVIDER;
      break;
    case "openepic_patient":
      formFieldArray = EPIC_PATIENT;
      break;

    case "cerner_system":
      formFieldArray = CERNER_SYSTEM;
      break;
    case "cerner_provider":
      formFieldArray = CERNER_PROVIDER;
      break;
    case "cerner_patient":
      formFieldArray = CERNER_PATIENT;
      break;

    case "nextgen_system":
      formFieldArray = NEXTGEN_SYSTEM;
      break;
    case "nextgen_patient":
      formFieldArray = NEXTGEN_PATIENT;
      break;

    case "openemr_system":
      formFieldArray = OPEN_EMR_SYSTEM;
      break;

    case "elationemr_system":
      formFieldArray = ELATION_EMR_SYSTEM;
      break;

    default:
      formFieldArray = [];
  }
  formFieldArray = formFieldArray.map((a) => ({ ...a }));
  return formFieldArray;
}
