export const CERNER_SYSTEM = [
  {
    type: "text",
    fieldName: "connection_name",
    label: "Name*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_id",
    label: "Client Id*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_secret",
    label: "Client Secret*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "scope",
    label: "Scope",
    error: false,
    isReq: false,
  },
  {
    type: "text",
    fieldName: "org_redirect_uri",
    label: "Organization Redirect URL",
    error: false,
    isReq: false,
  },
  {
    type: "text",
    fieldName: "tenant",
    label: "Tenant*",
    error: false,
    isReq: true,
  },
];

export const CERNER_PROVIDER = [
  {
    type: "text",
    fieldName: "connection_name",
    label: "Name*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_id",
    label: "Client ID*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_secret",
    label: "Client Secret*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "scope",
    label: "Scope*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "audiance",
    label: "Audience*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "org_redirect_uri",
    label: "Organization Redirect URL*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "tenant",
    label: "Tenant*",
    error: false,
    isReq: true,
  },
];

export const CERNER_PATIENT = [
  {
    type: "text",
    fieldName: "connection_name",
    label: "Name*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_id",
    label: "Client ID*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_secret",
    label: "Client Secret*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "scope",
    label: "Scope*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "audiance",
    label: "Audience*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "org_redirect_uri",
    label: "Organization Redirect URL*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "tenant",
    label: "Tenant*",
    error: false,
    isReq: true,
  },
];
