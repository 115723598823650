import {
  noWhiteSpaceRegex,
  noWhiteSpaceRegexBetween,
  SignupUserNameRegex,
  passwordCustomRegX,
  minMaxRegex,
} from "../../utils";

//Error Messages for Username
const EMPTY_USERNAME_ERROR_MESSAGE = "User Name Should Not be Empty";
const LIMIT_ERROR_MESSAGE = "User Name Should be Less than 40 Characters";
const NO_WHITE_SPACE_ERROR_MESSAGE = "Space is Not Allowed";
const WHITE_SPACE_BETWEEN_ERROR_MESSAGE = "No White Space Between the Words";
const SIGN_UP_NAME_ERROR_MESSAGE =
  "UserName Should Contain Only Alphabets, Digits, _ , @ , - , + , .";

//Error Messages for Confirm Password
const CONFIRM_PASSWORD_ERROR_MESSAGE =
  "Password didn't Match, Please Confirm Your Password";

export const validateUsername = (value, validationState) => {
  if (value === "") {
    validationState.userName.isError = true;
    validationState.userName.errorMessage = EMPTY_USERNAME_ERROR_MESSAGE;
  } else {
    if (noWhiteSpaceRegex.test(value)) {
      validationState.userName.isError = true;
      validationState.userName.errorMessage = NO_WHITE_SPACE_ERROR_MESSAGE;
    } else if (noWhiteSpaceRegexBetween.test(value)) {
      validationState.userName.isError = true;
      validationState.userName.errorMessage = WHITE_SPACE_BETWEEN_ERROR_MESSAGE;
    } else if (!SignupUserNameRegex.test(value)) {
      validationState.userName.isError = true;
      validationState.userName.errorMessage = SIGN_UP_NAME_ERROR_MESSAGE;
    } else if (value.length > 40) {
      validationState.userName.isError = true;
      validationState.userName.errorMessage = LIMIT_ERROR_MESSAGE;
    } else {
      validationState.userName.isError = false;
    }
  }
};

export const validatePassword = (value, validationState) => {
  if (value === "") {
    validationState.password.emptyError = true;
    validationState.password.charactersError = false;
    validationState.password.minMaxError = false;
  } else {
    validationState.confirmPassword.isError = false;
    validationState.password.isError = false;
    validationState.password.emptyError = false;

    //check all the characters are included in the password or not
    if (passwordCustomRegX.test(value)) {
      validationState.password.charactersError = true;
    } else {
      validationState.password.charactersError = false;
    }

    //check the length of the password
    if (minMaxRegex.test(value)) {
      validationState.password.minMaxError = true;
    } else {
      validationState.password.minMaxError = false;
    }

    //if confirm password has value then check if it matches the password
    if (
      validationState.confirmPassword.fieldValue !== "" &&
      value !== validationState.confirmPassword.fieldValue
    ) {
      validationState.confirmPassword.isError = true;
      validationState.confirmPassword.errorMessage =
        CONFIRM_PASSWORD_ERROR_MESSAGE;
    }
  }
};

export const validateConfirmPassword = (value, validationState) => {
  validationState.confirmPassword.isError = false;

  if (value === validationState.password.fieldValue) {
    validationState.confirmPassword.isError = false;
  } else {
    validationState.confirmPassword.isError = true;
    validationState.confirmPassword.errorMessage =
      CONFIRM_PASSWORD_ERROR_MESSAGE;
  }
};
