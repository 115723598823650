import "../../assets/styles/common.scss";

const Footer = ({ className }) => {
  return (
    <div className={"flex justify-center fixed-footer " + `${className ?? ""}`}>
      <p className="font-lato text-[18px]">
        &copy; 2023{" "}
        <span className="font-lato text-[#076993]">IntraConnects.</span>
        &nbsp; All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
