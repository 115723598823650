import React from "react";
import { TextField } from "@mui/material";

function InputText({ ...props }) {
  return (
    <TextField
      className="textinput-field w-[51.2%]"
      id={
        props.error
          ? `${props.name} outlined-error-helper-text`
          : `${props.name}`
      }
      variant="outlined"
      {...props}
    />
  );
}

export default InputText;
