export const NEXTGEN_SYSTEM = [
  {
    type: "text",
    fieldName: "connection_name",
    label: "Name*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_id",
    label: "Client Id*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_secret",
    label: "Client Secret*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "site_id",
    label: "Site ID*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "org_redirect_uri",
    label: "Organization Redirect URL",
    error: false,
    isReq: false,
  },
];

export const NEXTGEN_PATIENT = [
  {
    type: "text",
    fieldName: "connection_name",
    label: "Name*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_id",
    label: "Client Id*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "client_secret",
    label: "Client Secret*",
    error: false,
    isReq: true,
  },
  {
    type: "text",
    fieldName: "org_redirect_uri",
    label: "Organization Redirect URL*",
    error: false,
    isReq: true,
  },
];
