import selectReqFields from "./constants";

export const updateForm = (
  connections,
  copySelectedConnectionData,
  showToastError,
  isConnectionValid,
  formFields,
  appTypeMap,
  ehrName
) => {
  let formFieldsArray = [];

  formFields.map((ele, index) => {
    copySelectedConnectionData[ele.fieldName].isError = false;
    copySelectedConnectionData[ele.fieldName].fieldValue = "";
  });

  Object.keys(copySelectedConnectionData.appType).map((ele) => {
    if (copySelectedConnectionData.appType[ele]) {
      const filteredConnections = connections.current.filter((connection) =>
        isConnectionValid(connection, appTypeMap.get(ele), ehrName)
      );

      if (filteredConnections.length) {
        formFieldsArray = selectReqFields(ehrName);
        copySelectedConnectionData.connectionId[ele] =
          filteredConnections[0].uuid;
      } else {
        copySelectedConnectionData.appType[ele] = false;
        showToastError(appTypeMap.get(ele));
      }
    }
  });

  return { copySelectedConnectionData, formFieldsArray };
};
