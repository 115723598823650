import React from "react";
import { useHistory } from "react-router-dom";
import UserAuthHome from "../../pages/user-auth";
import { HOME } from "../../utils/routeConstants";

const NotLoggedIn = () => {
  const history = useHistory();

  return (
    <UserAuthHome>
      <div className="not-logged-container" onClick={() => history.push(HOME)}>
        <h3 className="not-login-text cursor-pointer">You must login first</h3>
      </div>
    </UserAuthHome>
  );
};

export default NotLoggedIn;
