import React, { useState } from "react";
import { validateName, validateURL } from "./WebhookValidations";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CustomButton from "../../components/core/CustomButton";
import { webHookService } from "../../services/webhookMX";
import { WEBHOOK_VALUES } from "../../services/initialValues";
import SideBar from "../../components/Sidebar";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  getOrganizationDetails,
  deepCopy,
  getStoredData,
  setStoredData,
} from "../../utils";
import { WEBHOOK } from "../../utils/routeConstants";
import "../../assets/styles/apikey.scss";
// Add and update webhook settings
const CreateWebHook = ({}) => {
  const history = useHistory();

  const [btnLoading, setBtnLoading] = useState(false);

  const [validationData, setValidationData] = useState(
    deepCopy(WEBHOOK_VALUES)
  );

  const webHookValuesChangeHandler = ({ target: { name, value } }) => {
    let copiedValidationsData = { ...validationData };

    if (name === "webHookName") {
      copiedValidationsData.webHookName.fieldValue = value;

      validateName(value, copiedValidationsData);
    }
    if (name === "webHookURL") {
      copiedValidationsData.webHookURL.fieldValue = value;

      validateURL(value, copiedValidationsData);
    }
    if (name === "method") {
      copiedValidationsData.method.fieldValue = value;
      if (value !== "") {
        copiedValidationsData.method.isError = false;
      } else {
        copiedValidationsData.method.isError = true;
      }
    }

    setValidationData(copiedValidationsData);
  };

  const createWebHook = () => {
    const orgId = getOrganizationDetails();

    let copiedValidationData = { ...validationData };

    if (copiedValidationData.webHookName.fieldValue === "") {
      copiedValidationData.webHookName.isError = true;
      copiedValidationData.webHookName.errorMessage =
        "Name Should Not be Empty";
    }

    if (copiedValidationData.webHookURL.fieldValue === "") {
      copiedValidationData.webHookURL.isError = true;
      copiedValidationData.webHookURL.errorMessage = "URL Should Not be Empty";
    }

    //Check if the method is set
    if (copiedValidationData.method.fieldValue === "") {
      copiedValidationData.method.isError = true;
    } else {
      copiedValidationData.method.isError = false;
    }

    setValidationData(copiedValidationData);

    const reqBody = {
      name: copiedValidationData.webHookName.fieldValue,
      webhook_url: copiedValidationData.webHookURL.fieldValue,
      method: copiedValidationData.method.fieldValue,
    };
    // if (
    //   copiedValidationData.webHookName.fieldValue &&
    //   copiedValidationData.webHookURL.fieldValue &&
    //   copiedValidationData.method.fieldValue
    // ) {
    if (
      !copiedValidationData.method.isError &&
      !copiedValidationData.webHookName.isError &&
      !copiedValidationData.webHookURL.isError
    ) {
      webHookService
        .createWebHook(orgId, reqBody)
        .then((res) => {
          if (res) {
            let webhookCount = parseInt(getStoredData.getWebhookCount());
            setStoredData.setWebhookCount(webhookCount + 1);
            history.push(WEBHOOK.DASHBOARD);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // }
  };

  return (
    <div className="flex w-full">
      <SideBar />

      <div className="create-edit-apikey-container bg-[#EDF6F9] px-[50px] w-[82%]">
        <h1 className="mt-[20px] xl:mt-[40px]">Create Webhook</h1>

        <div className="create-container flex flex-col mt-[30px]">
          <div className="flex w-full">
            <span className="w-[25%] pt-[18px] font-lato text-[20px] text-[#3a3a3a] xl:text-[16px]">
              Name
            </span>
            <TextField
              name="webHookName"
              className="w-[698px] textinput-field xl:h-[48px]"
              placeholder="Webhook Name"
              onChange={webHookValuesChangeHandler}
              value={validationData?.webHookName.fieldValue}
              error={validationData.webHookName.isError}
              helperText={
                validationData.webHookName.isError &&
                validationData.webHookName.errorMessage
              }
            />
          </div>

          <div className="mt-[40px] flex w-full">
            <div className="flex w-[25%]">
              <span className="font-lato text-[20px] text-[#3a3a3a] pt-[12px]  xl:text-[16px] xl:mt-[10px]">
                Webhook URL
              </span>
            </div>
            <TextField
              name="webHookURL"
              label=""
              className="w-[698px] textinput-field"
              placeholder="Webhook URL"
              onChange={webHookValuesChangeHandler}
              value={validationData?.webHookURL.fieldValue}
              error={validationData.webHookURL.isError}
              helperText={
                validationData.webHookURL.isError &&
                validationData.webHookURL.errorMessage
              }
            />
          </div>

          <div className="mt-[40px] flex w-full">
            <div className="w-[25%] flex">
              <span className="font-lato text-[20px] text-[#3a3a3a] xl:text-[16px] xl:mt-[10px]">
                Method Type
              </span>
            </div>
            <FormControl>
              <RadioGroup
                row
                name="method"
                className="xl:text-[16px]"
                aria-labelledby="demo-row-radio-buttons-group-label"
                onChange={webHookValuesChangeHandler}
                value={validationData?.method.fieldValue}
                required
              >
                <FormControlLabel value="get" control={<Radio />} label="GET" />
                <FormControlLabel
                  value="post"
                  control={<Radio />}
                  label="POST"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {validationData.method.isError && (
            <div className="ml-[26%] text-[#D32F2F] text-[12px]">
              {validationData.method.errorMessage}
            </div>
          )}

          <div className="flex justify-end mt-[42px] xl:mt-[12px]">
            <button
              className="w-[210px] h-[56px] bg-[#FFFFFF] border-[1px] border-black rounded-[10px] text-[20px] mr-[10px] xl:w-[120px] xl:h-[48px]"
              onClick={() => {
                history.push(WEBHOOK.DASHBOARD);
              }}
            >
              CANCEL
            </button>
            {!btnLoading ? (
              <CustomButton
                text={"SAVE"}
                className="filled-custom-btn w-[215px] h-[56px] text-lato text-[20px] xl:w-[160px] xl:h-[48px]"
                onClick={createWebHook}
              />
            ) : (
              <LoadingButton
                loading={btnLoading}
                loadingPosition="center"
                variant="outlined"
                className={
                  "rounded-[5px] text-[20px] font-lato w-[200px] h-[56px] text-[#fff]"
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWebHook;
