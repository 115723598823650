import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "@mui/material";
import DropdownList from "../../components/core/DropdownList";
import { organizationService } from "../../services/organizationMX";
import { CREATE_NEW_CONNECTION_VALUES } from "../../services/initialValues";
import Loader from "../../components/core/Loader";
import { useHistory } from "react-router-dom";
import selectConstants from "./constants";
import AppLayout from "../../components/layout/AppLayout";
import {
  getOrganizationDetails,
  getStoredData,
  nameRegex,
  setStoredData,
  webSiteRegex,
} from "../../utils";
import { CONNECTIONS } from "../../utils/routeConstants";

const list = [
  "athena",
  "openepic",
  "cerner",
  "nextgen",
  "elationemr",
  "openemr",
];
const APP_TYPE_LIST = ["system", "provider", "patient"];

function CreateConnection() {
  const [newConnectionData, setNewConnectionData] = useState(
    CREATE_NEW_CONNECTION_VALUES
  );

  const NAME_CHARACTER_LIMIT_ERROR =
    "This field should have less then 60 character";
  const FIELD_CHARACTER_LIMIT_ERROR =
    "This field should have less then 250 character";
  const URL_CHARACTER_LIMIT_ERROR =
    "This field should have less then 250 character";
  const URL_FORMAT_ERROR = "URL field should be in the right format";
  const NAME_FORMAT_ERROR = "Connection Name Should be Alphanumeric Only";
  const REQUIRED_MESSAGE = "Required";

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [appTypeList, setAppTypeList] = useState(APP_TYPE_LIST);
  const [formFieldsArray, setFormFieldsArray] = useState([]);

  const [isFieldsValid, setIsFieldsValid] = useState(false);

  const [isDataValid, setIsDataValid] = useState(true);

  const url = `${import.meta.env.VITE_MICROSERVICE_API_BASE_URL}/${
    newConnectionData.ehr_name
  }_redirect_url`;

  useEffect(() => {
    if (newConnectionData.ehr_name === "nextgen")
      setAppTypeList(["system", "patient"]);
    else if (
      newConnectionData.ehr_name === "openemr" ||
      newConnectionData.ehr_name === "elationemr"
    )
      setAppTypeList(["system"]);
    else setAppTypeList(APP_TYPE_LIST);
  }, [newConnectionData.ehr_name]);

  const changeFormFields = ({ target: { name, value } }) => {
    if (name === "ehr_name") {
      setNewConnectionData(CREATE_NEW_CONNECTION_VALUES);
      setFormFieldsArray([]);
      setAppTypeList([]);
    }

    setNewConnectionData({ ...newConnectionData, [name]: value });

    if (newConnectionData.ehr_name && name === "app_type" && value) {
      setFormFieldsArray(selectConstants(newConnectionData.ehr_name, value));
    } else if (newConnectionData.app_type && name === "ehr_name" && value) {
      setFormFieldsArray(selectConstants(value, newConnectionData.app_type));
    }
  };

  const onChangeHandler = ({ target: { name, value } }) => {
    setNewConnectionData({
      ...newConnectionData,
      [name]: value,
    });

    let copyFormFieldsArray = formFieldsArray;

    const fieldIndex = copyFormFieldsArray.findIndex(
      (ele) => ele.fieldName === name
    );

    const item = copyFormFieldsArray[fieldIndex];

    if (value) {
      item.error = false;
      item.message = "";
      setIsFieldsValid(false);

      if (value.length > 60 && item.fieldName === "connection_name") {
        item.error = true;
        item.message = NAME_CHARACTER_LIMIT_ERROR;
        setIsFieldsValid(true);
      } else if (value.length > 250) {
        item.error = true;
        item.message = FIELD_CHARACTER_LIMIT_ERROR;
        setIsFieldsValid(true);

        if (item.fieldName === "scope") {
          item.error = false;
          item.message = "";
          setIsFieldsValid(false);
        } else if (item.fieldName === "private_key") {
          item.error = false;
          item.message = "";
          setIsFieldsValid(false);
        } else if (item.fieldName === "access_token") {
          item.error = false;
          item.message = "";
          setIsFieldsValid(false);
        } else if (item.fieldName === "refresh_token") {
          item.error = false;
          item.message = "";
          setIsFieldsValid(false);
        } else if (item.fieldName === "org_redirect_uri") {
          if (value.length > 1000) {
            item.error = true;
            item.message = URL_CHARACTER_LIMIT_ERROR;
            setIsFieldsValid(true);
          } else {
            item.error = false;
            item.message = "";
            setIsFieldsValid(false);
          }
        }
      } else if (item.fieldName === "org_redirect_uri") {
        if (webSiteRegex.test(value)) {
          item.error = false;
          item.message = "";
          setIsFieldsValid(false);
        } else {
          item.error = true;
          item.message = URL_FORMAT_ERROR;
          setIsFieldsValid(true);
        }
      } else if (item.fieldName === "connection_name") {
        if (nameRegex.test(value)) {
          item.error = false;
          item.message = "";
          setIsFieldsValid(false);
        } else {
          item.error = true;
          item.message = NAME_FORMAT_ERROR;
          setIsFieldsValid(true);
        }
      }
    } else {
      if (value === "" && item.isReq === true) {
        item.error = true;
        item.message = REQUIRED_MESSAGE;
        setIsFieldsValid(true);
      } else if (value === "" && item.isReq === false) {
        item.error = false;
        item.message = "";
        setIsFieldsValid(false);
      }
    }
    setFormFieldsArray(copyFormFieldsArray);
  };

  const onSaveHandler = () => {
    let copyFormFieldsArray = formFieldsArray;
    let isFormValid = true;

    copyFormFieldsArray.map((item) => {
      if (item.isReq) {
        if (!newConnectionData[item.fieldName]) {
          item.error = true;
          item.message = REQUIRED_MESSAGE;
          isFormValid = false;
          setIsDataValid(!isDataValid);
        } else if (item.error) {
          isFormValid = false;
        } else {
          item.error = false;
          item.message = "";
          setIsDataValid(true);
        }
      } else {
        if (item.error) {
          isFormValid = false;
        }
      }
    });

    setFormFieldsArray(copyFormFieldsArray);

    const reqBody = {
      ...newConnectionData,
      redirect_uri: url,
    };
    const organisationId = getOrganizationDetails();

    if (isFormValid) {
      setLoading(true);
      organizationService
        .addConnection(reqBody, organisationId)
        .then((resp) => {
          if (resp) {
            let connectionCount = parseInt(getStoredData.getConnectionCount());
            setStoredData.setConnectionCount(connectionCount + 1);
            history.push(CONNECTIONS.DASHBOARD);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  return (
    <AppLayout>
      <section className="connection-container bg-[#EDF6F9] p-[40px]">
        <div className="text-[20px]">
          <span
            className="text-[#939393] cursor-pointer"
            onClick={() => history.push(CONNECTIONS.DASHBOARD)}
          >
            Connections
          </span>
          <span>
            <ArrowForwardIosIcon fontSize="small" /> Create New Connection
          </span>
        </div>

        <div className="bg-[#ffffff] pl-[5%] tabcontainers overflow-auto mt-[2%]">
          {loading && <Loader />}

          <div className="bg-[#ffffff] pl-[50px] xl:pt-[25px] xl:pl-[25px] tabcontainers overflow-auto">
            <div className="flex items-center mt-[40px] w-[full]">
              <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
                EHR Name
              </span>
              <DropdownList
                dropdownName="ehr_name"
                className="w-[698px] h-[56px] rounded-[10px] mr-[50px]"
                list={list}
                onChange={changeFormFields}
                selectedvalue={newConnectionData.ehr_name}
                label="EHR Name"
                labelId="EHR Name"
                // helperText={!newConnectionData.ehr_name && "Required*"}
                // error={!newConnectionData.ehr_name}
              />
            </div>

            <div className="flex items-center mt-[40px] w-full">
              <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
                App Type
              </span>
              <DropdownList
                dropdownName="app_type"
                className="w-[698px] h-[56px] rounded-[10px] mr-[50px] "
                list={appTypeList}
                onChange={changeFormFields}
                selectedvalue={newConnectionData.app_type}
                label="App Type"
                labelId="App Type"
                // helperText={!newConnectionData.app_type && "Required*"}
                // error={!newConnectionData.app_type}
              />
            </div>
            {(newConnectionData.app_type === APP_TYPE_LIST[1] ||
              newConnectionData.app_type === APP_TYPE_LIST[2]) &&
              newConnectionData.ehr_name && (
                <div className="flex items-center mt-[40px] w-full text-[20px]">
                  <span className=" font-lato text-[#3A3A3A] w-[20%]">
                    Redirect URL
                  </span>
                  <span>{url} </span>
                </div>
              )}
            {formFieldsArray &&
              formFieldsArray.map((item, index) => (
                <div className="flex items-center mt-[40px] w-full" key={index}>
                  <span className="text-[20px] font-lato text-[#3A3A3A] w-[20%]">
                    {item.label}
                  </span>
                  <TextField
                    className="textinput-field w-[53.5%]"
                    name={item.fieldName}
                    // label={item.label}
                    helperText={item?.message}
                    // helperText={
                    //   item.error
                    //     ? item?.errorMessage
                    //       ? item?.errorMessage
                    //       : "Required*"
                    //     : ""
                    // }
                    error={item.error}
                    onChange={onChangeHandler}
                    value={newConnectionData.name}
                    variant="outlined"
                    id={item.name}
                  />
                </div>
              ))}

            {newConnectionData.ehr_name && newConnectionData.app_type && (
              <div className="mt-[20px]">
                <button
                  className="w-[160px] h-[56px] text-[20px] font-lato rounded-[5px] bg-[#ffffff] border-[1px]"
                  onClick={() => history.push(CONNECTIONS.DASHBOARD)}
                >
                  CANCEL
                </button>
                <button
                  className="w-[160px] h-[56px] text-[20px] text-[#ffffff] font-lato rounded-[5px] bg-[#076993] ml-[20px]"
                  onClick={onSaveHandler}
                >
                  SAVE
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    </AppLayout>
  );
}

export default CreateConnection;
