import React, { useState } from "react";
import { TextField } from "@mui/material";
import UserAuthHome from ".";
import CustomButton from "../../components/core/CustomButton";
import { useHistory } from "react-router-dom";
import { authService } from "../../services/authMX";
import Loader from "../../components/core/Loader";

import { toast } from "react-toastify";
import { emailRegX } from "../../utils";
import Footer from "../../components/core/Footer";
import { HOME } from "../../utils/routeConstants";

const ForgotPassword = (props) => {
  let history = useHistory();

  const [forgotEmail, setEmail] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [emailLength, setEmailLength] = useState(false);

  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const emailChangeHandler = (event) => {
    let value = event.target.value;
    setEmail(value);
    const _emailIsValid = emailRegX.test(value);

    if (value === "") {
      setIsEmailValid(false);

      setEmailEmpty(true);
    } else if (value.length > 0) {
      setEmailEmpty(false);

      if (!_emailIsValid) {
        setIsEmailValid(true);
      } else {
        setIsEmailValid(false);
      }
    }

    if (value.length > 70) {
      setEmailLength(true);
    } else {
      setEmailLength(false);
    }
  };

  const fetchForgotPasswordAPI = () => {
    if (forgotEmail !== "") {
      if (!isEmailValid && !emailEmpty && !emailLength) {
        setLoading(true);
        authService
          .fetchForgotPasswordData({
            email: forgotEmail,
          })
          .then((resp) => {
            setSuccess(true);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setEmailEmpty(true);
    }
  };

  const handleEnterkeyPress = (event) => {
    if (event.key === "Enter") {
      fetchForgotPasswordAPI();
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <UserAuthHome>
        {!success ? (
          <div className="auth-block  my-[40px]">
            <h1 className="text-[50px] text-[#3A3A3A] font-lato font-bold  xl:text-[40px] xl:mb-[10px] text-[#3a3a3a] lg:text-[30px]">
              Forgot Password
            </h1>
            <p className="text-[30px] text-[#3A3A3A] font-lato mt-[11px]  xl:text-[22px] lg:text-[18px]">
              Enter Email and Get Verification Link for New Password!
            </p>

            <div className="flex flex-col mt-[64px] mr-[60.56px] xl:mt-[40px] lg: mt-[30px]">
              <TextField
                error={isEmailValid || emailEmpty || emailLength}
                className="textinput-field text-[20px] font-lato"
                id="outlined-basic"
                label="Email Id"
                variant="outlined"
                onChange={emailChangeHandler}
                value={forgotEmail}
                helperText={
                  (isEmailValid && "Enter valid email Id") ||
                  (emailEmpty && "Email Should not be Empty") ||
                  (emailLength && "Email Length Should Not be More than 70")
                }
                onKeyDown={handleEnterkeyPress}
              />
            </div>

            <div className="mt-[40px] mr-[60.56px] xl:mt-[16px] lg:mt-[16px]">
              <CustomButton
                iconName="reply-right"
                text="Submit"
                className="filled-custom-btn text-[28px] font-lato w-full "
                onClick={fetchForgotPasswordAPI}
              />

              <CustomButton
                iconName="reply-right"
                text="Go to Sign In"
                className="empty-custom-btn text-[28px] text-[#3A3A3A] font-lato w-full mt-[40px] xl:mt-[20px] lg:mt-[20px] "
                onClick={() => history.push(HOME)}
              />
            </div>
          </div>
        ) : (
          <div className="auth-block my-[40px] w-[535px] xl:my-[30px] lg:my-[20px]">
            <div className="">
              <h1 className="text-[40px] font-bold text-[#3A3A3A] xl:text-[30px] lg:text-[22px]">
                Reset Password Email Sent
              </h1>
              <p className="text-[30px] text-[#3A3A3A] font-lato mt-[11px] xl:text-[24px] lg:text-[20px]">
                An Email is Sent to {forgotEmail}
              </p>
            </div>

            <div className="flex flex-col mt-[121px]">
              <p className="text-[#3A3A3A] text-[30px] font-lato font-lthin mt-[11px] xl:text-[20px] lg:text-[18px]">
                Please Check Mail & Click on Verification Link to Reset your
                Password.
              </p>
            </div>

            {/* <div className="mt-6">
              <CustomButton
                iconName="reply-right"
                text="Go to Sign In"
                className="empty-custom-btn text-2xl w-full h-14 mt-6"
                onClick={() => history.push("/")}
              />
            </div> */}
          </div>
        )}
        <Footer className={"bottom-[5px]"} />
      </UserAuthHome>
    </div>
  );
};

export default ForgotPassword;
