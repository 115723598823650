import { ATHENA, NEXTGEN,ELATION_EMR } from "./reqFields";

export default function selectReqFields(ehr_name) {
  switch (ehr_name) {
    case "athena":
      return ATHENA;

    case "nextgen":
      return NEXTGEN;

    case "elationemr":
      return ELATION_EMR;

    default:
      return [];
  }
}
