import React, { useState } from "react";
import "../../assets/styles/common.scss";

import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";

const FilterDropdown = ({
  name,
  className,
  list,
  onChange,
  selectedvalue,
  placeholder,
  label,
  loading,
}) => {
  const [changedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    onChange(event);
  };

  const displayValue = (item) => {
    return item?.connection_name || item?.name || item.value || item;
  }
  
    return (
    <Box>
      <FormControl>
        <Select
          name={name}
          id="demo-simple-select-label"
          sx={{ minWidth: 150, maxWidth: 698, height: 36 }}
          value={selectedvalue}
          displayEmpty
          defaultValue=""
          // renderValue={(value) =>
          //   value ? value || value.display : placeholder
          // }
          onChange={handleChange}
          className={className ?? "dropdown "}
          label={label ?? ""}
        >
          {list?.length && (
            <MenuItem key={""} value="">
              {"Any"}
            </MenuItem>
          )}
          {list?.length ? (
            list?.map((item, index) => (
              <MenuItem key={index} value={item?.uuid || item.key || item}>
                {displayValue(item)}
              </MenuItem>
            ))
          ) : (
            <p className="p-2">Oops! No Data</p>
          )}
        </Select>

        {loading && (
          <CircularProgress color="primary" className="dropdown-loader" />
        )}
      </FormControl>
    </Box>
  );
};
export default FilterDropdown;
