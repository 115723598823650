import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <ToastContainer
      limit={3}
      autoClose={3000}
      closeOnClick
      pauseOnFocusLoss={false}
    />
    <App />
  </>
);
