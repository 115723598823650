import React from "react";
import SideBar from "../Sidebar";
import Footer from "../core/Footer";

function AppLayout({children}) {
  return (
    <div>
      {" "}
      <div className="flex w-full bg-[#EDF6F9]">
        <SideBar />

        <div className="settings-container  flex-1 w-[82%] xl:pt-[20px] xl:px-[30px]">
          <div className="clear-right">{children}</div>
        </div>
        <Footer className={"bottom-[5px]"} />
      </div>
    </div>
  );
}

export default AppLayout;
