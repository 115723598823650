import React, { useEffect, useState } from "react";
import UpdatePassword from "./UpdatePassword";
import UserProfile from "./UserProfile";

import { TextField } from "@mui/material";
import CustomButton from "../../components/core/CustomButton";
import "../../assets/styles/settings.scss";
import TwoFactorAuth from "./TwoFactorAuth";
import CustomCenterModal from "../../components/core/CustomCenterModal";
import { userService } from "../../services/userMX";
import { authService } from "../../services/authMX";
import { toast } from "react-toastify";
import Loader from "../../components/core/Loader";
import { getTokenDetails, imageType, deepCopy } from "../../utils";
import { useHistory } from "react-router-dom";

import { VALIDATION_UPDATE_USER } from "../../services/initialValues";

const UserSettings = () => {
  let history = useHistory();

  const token = getTokenDetails();
  const [showTwoFactorAuthSection, setShowTwoFactorAuthSection] =
    useState(false);
  const [showPhotoUploadImgModal, setShowUploadImgModal] = useState(false);

  const [usersInfo, setUsersInfo] = useState(deepCopy(VALIDATION_UPDATE_USER));
  const [selectedOrgImage, setSelectedOrgImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isImageValid, setIsImageValid] = useState(false);
  const [isImageSizeIsValid, setIsImageSizeIsValid] = useState(false);

  useEffect(() => {
    fetchCurrentUsersData();
  }, []);

  // get api for current user
  const fetchCurrentUsersData = () => {
    setLoading(true);
    authService
      .fetchCurrentUsersData()
      .then((resp) => {
        if (resp) {
          setUsersInfo(resp);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  //update users profilepic
  const fetchUpdateUsersProfilePic = () => {
    const formData = new FormData();
    formData.append("image", selectedOrgImage);

    userService
      .fetchUpdateUsersProfilePic(formData, token)
      .then((resp) => {
        if (resp) {
          toast.success(resp.value);
          fetchCurrentUsersData();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {loading && <Loader />}

      <div className="usersettings">
        <div className="usersettings-container bg-[#EDF6F9]  flex flex-col justify-between">
          <div className="shadow-[0px_3px_6px_#00000029] bg-[#ffffff] p-[20px] w-full xl:p-[10px]">
            <UserProfile />
          </div>
          {!showTwoFactorAuthSection && (
            <div className="mt-[20px] shadow-[0px_3px_6px_#00000029] bg-[#ffffff] p-[20px]">
              <h1 className="text-[20px] font-lato text-[#3a3a3a] xl:text-[16px]">
                Two Factor Authentication
              </h1>
              <CustomButton
                iconName="reply-right"
                text="ADD MFA METHOD"
                className="filled-custom-btn rounded-[5px] text-[20px] font-lato w-[280px] h-[56px] mt-[20px] xl:w-[180px] xl:text-[16px] xl:h-[38px] xl:mt-[10px]"
                // onClick={() => setShowTwoFactorAuthSection(true)}
              />
            </div>
          )}

          {showTwoFactorAuthSection && <TwoFactorAuth />}

          <div className="mt-[20px] shadow-[0px_3px_6px_#00000029] bg-[#ffffff] p-[20px]">
            <UpdatePassword />
          </div>
        </div>
      </div>

      <CustomCenterModal
        openCenterModal={showPhotoUploadImgModal}
        onCloseCenterModal={() => {
          setShowUploadImgModal(false);
          setIsImageSizeIsValid(false);
        }}
        closeButtonHandler={() => {
          setShowUploadImgModal(false),
            setSelectedOrgImage(null),
            setIsImageValid(false),
            setIsImageSizeIsValid(false);
        }}
        className=""
        saveButtonHandler={() => {
          fetchUpdateUsersProfilePic(), setShowUploadImgModal(false);
        }}
        saveBtnDisable={
          isImageValid || setIsImageSizeIsValid === true ? true : false
        }
      >
        <div className="">
          <p className="text-[#003344] text-[20px] font-bold font-lato">
            Upload Logo
          </p>
          <p className="mt-[19px] text-lato text-[20px] text-[3a3a3a]">
            Select an image to upload as your organization's icon (5 MB max)
          </p>
          <p className="mt-[20px] text-lato text-[20px] text-[3a3a3a]">
            After uploading, your icon will:
          </p>

          <ul className="list-disc ml-[30px] mt-[11px]">
            <li className="text-lato text-[20px] text-[3a3a3a]">
              Be automatically resized
            </li>
            <li className="text-lato text-[20px] text-[3a3a3a]">
              Have a white background applied it necessary
            </li>
          </ul>

          <TextField
            type="file"
            className="mt-[33px]"
            onChange={(event) => {
              setSelectedOrgImage(event.target.files[0]);
              var imgtype = event.target.files[0].name;
              var imageTypeCheck = imageType.includes(
                imgtype.substring(imgtype.lastIndexOf(".") + 1)
              );
              if (imageTypeCheck) {
                setSelectedOrgImage(event.target.files[0]);
                setIsImageValid(false);
              } else {
                setIsImageValid(true);
              }
              if (event.target.files[0].size > 5000000) {
                setIsImageSizeIsValid(true);
              } else {
                setIsImageSizeIsValid(false);
              }
            }}
            error={isImageValid || isImageSizeIsValid}
            helperText={
              (isImageValid && "please select valid image") ||
              (isImageSizeIsValid && "file should be less than 5MB")
            }
          />
        </div>
      </CustomCenterModal>
    </>
  );
};
export default UserSettings;
