import { minMaxRegex, passwordCustomRegX } from "../../utils";

const EMPTY_PASSWORD_ERROR_MESSAGE = " Password Should Not be Empty";

const CONFIRM_PASSWORD_ERROR_MESSAGE =
  "Password didn't Match, Please Confirm your Password";

export const validateNewPassword = (value, validationState) => {
  if (value === "") {
    validationState.newPassword.isError = true;
    validationState.newPassword.charactersError = false;
    validationState.newPassword.minMaxError = false;
    validationState.newPassword.errorMessage = EMPTY_PASSWORD_ERROR_MESSAGE;
  } else {
    validationState.newPassword.isError = false;
    validationState.confirmPassword.isError = false;

    if (passwordCustomRegX.test(value)) {
      validationState.newPassword.charactersError = true;
    } else {
      validationState.newPassword.charactersError = false;
    }
    if (minMaxRegex.test(value)) {
      validationState.newPassword.minMaxError = true;
    } else {
      validationState.newPassword.minMaxError = false;
    }

    if (
      validationState.confirmPassword.fieldValue !== "" &&
      value !== validationState.confirmPassword.fieldValue
    ) {
      validationState.confirmPassword.isError = true;
      validationState.confirmPassword.errorMessage =
        CONFIRM_PASSWORD_ERROR_MESSAGE;
    }
  }
};

export const validateConfirmPassword = (value, validationState) => {
  if (value === validationState.newPassword.fieldValue) {
    validationState.confirmPassword.isError = false;
  } else {
    validationState.confirmPassword.isError = true;
    validationState.confirmPassword.errorMessage =
      CONFIRM_PASSWORD_ERROR_MESSAGE;
  }
};
