const NAME_ERROR_MESSAGE = "Name Should Not be Empty";
const LIMIT_ERROR_MESSAGE = "Name Should be Less than 60 Character";

export const validateName = (value, validationState) => {
  if (value === "") {
    validationState.name.isError = true;
    validationState.name.errorMessage = NAME_ERROR_MESSAGE;
  } else if (value.length > 60) {
    validationState.name.isError = true;
    validationState.name.errorMessage = LIMIT_ERROR_MESSAGE;
  } else {
    validationState.name.isError = false;
  }
};
